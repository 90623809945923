// import { projectFirestore } from "@/firebase/config";
// import { collection, onSnapshot, query } from "firebase/firestore";
// import { ref, watchEffect } from "vue";

// const getCollection = (collectionName, queryTerm) => {
//   const isLoading = ref(true);
//   const documents = ref(null);
//   let collectionRef;
//   if (queryTerm) {
//     collectionRef = query(
//       collection(projectFirestore, collectionName),
//       queryTerm
//     );
//   } else {
//     collectionRef = query(collection(projectFirestore, collectionName));
//   }

//   const unsubscripe = onSnapshot(collectionRef, (qry) => {
//     const result = [];
//     qry.forEach((doc) => {
//       result.push({ id: doc.id, ...doc.data() });
//     });
//     documents.value = result;
//     isLoading.value = false;
//   });

//   watchEffect((onInvalidate) => {
//     onInvalidate(() => unsubscripe());
//   });

//   return { documents, isLoading };
// };

// export default getCollection;



import { projectFirestore } from "@/firebase/config";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { ref, watchEffect } from "vue";

const getCollection = (collectionName, queryTerm) => {
  const isLoading = ref(true);
  const documents = ref(null);
  let collectionRef;

  if (queryTerm) {
    collectionRef = query(
      collection(projectFirestore, collectionName),
      queryTerm
    );
  } else {
    collectionRef = query(
      collection(projectFirestore, collectionName),
      orderBy("createdAt", "asc")
    );
  }

  const unsubscribe = onSnapshot(collectionRef, (qry) => {
    const result = [];
    qry.forEach((doc) => {
      result.push({ id: doc.id, ...doc.data() });
    });
    documents.value = result;
    isLoading.value = false;
  });

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsubscribe());
  });

  return { documents, isLoading };
};

export default getCollection;
