<template>
  <div
    class="w-full my-3"
  >
    <h2
      class="text-body uppercase font-bold text-primery1 font-NotoSansKhmer"
    >
    រក្សាសិទ្ធិគ្រប់យ៉ាងដោយម៉ាស័្ទរអាយធីស៊ីស្ទឹម - ជំនាន់ទី ១.០
    </h2>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>
