<template>
  <div>

  </div>
  <div v-motion :initial="{
    opacity: 0,
  }" :enter="{

    opacity: 1,
    transition: {
      duration: 500,
      ease: 'easeIn',
    },
  }" class="flex flex-col bg-background xl:pt-4 mb-10 md:mb-10 xl:mb-0">
    <div class="xl:w-full md:w-full w-full h-auto xl:py-3 lg:mb-[0px] xl:mb-0">
      <div class="lg:w-full xl:w-[1200px] w-full md:w-full mx-auto">
        <div class="lg:mt-0 xl:-mt-8 md:my-[10px] text-center">
          <div class="lg:mt-5 xl:mt-0  text-center pb-5">
            <h1
              class="text-center text-primery1 xl:text-3xl xl:my-0 md:text-3xl md:mt-[25px] mt-5 lg:text-2xl  text-2xl xl:mt-10 font-NotoSansKhmer">
              អំពីពួកយើង
            </h1>
            <div class="border-b-[4px] border-primery1 w-16 lg:w-16 xl:w-20 mx-auto mt-1.5"></div>

          </div>

          <div class="w-full">
            <div class="w-[100%] mx-auto md:w-[90%] md:px-3  lg:w-[990px] xl:w-[80%]  lg:px-5 ">
              <p
                class="px-10 text-justify text-textbody/70 md:p-0 md:text-center font-[400] md:text-[20px] lg:text-body  xl:text-[20px]   font-NotoSansKhmer lg:w-[95%] mx-auto xl:w-[100%] w-full">
                ម៉ាស្ទ័រអាយធី​ ស៊ីស្ទឹម (Master-IT System) គឺជាក្រុមហ៊ុនផ្តល់ដំណោះស្រាយផ្នែកបច្ចេកវិទ្យា
                ដែលមានឯកទេសក្នុងការអភិវឌ្ឍន៍ប្រព័ន្ធគ្រប់គ្រងទិន្នន័យសម្រាប់អាជីវកម្ម (Software Development)
                ការរចនាគេហទំព័រ
                (Web Design) និងការប្រឹក្សាយោបល់រៀបចំប្រព័ន្ធគ្រប់គ្រងទិន្នន័យសម្រាប់អាជីវកម្ម (Business Software
                Consultant)។
              </p>
            </div>
          </div>
        </div>
      </div>


      <div>

        <div class="lg:w-[85%] xl:w-[1200px] md:w-[90%] md:px-4 mx-auto xl:px-5 mt-5 xl:my-10 px-10">

          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 md:gap-5 lg:gap-5 xl:gap-10 gap-10">
            <div>
              <div class=" border  duration-300  mx-auto relative">
                <div class=" ">
                  <div class="flex justify-center">
                    <img
                      class="w-[400px] h-[300px] border  md:w-[420px] md:h-[300px] lg:w-[350px] lg:h-[250px] xl:w-[400px] xl:h-[300px]"
                      src="../assets/អភិវឌ្ឍប្រព័​ន្ធ-1.png" alt="Product Image" />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class=" border  duration-300  mx-auto relative">
                <div class=" ">
                  <div class="flex justify-center">
                    <img
                      class="w-[400px] h-[300px] border  md:w-[420px] md:h-[300px] lg:w-[350px] lg:h-[250px] xl:w-[400px] xl:h-[300px]"
                      src="../assets/រចនាគេហទំព័រ.jpg" alt="Product Image" />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class=" border  duration-300  mx-auto relative">
                <div class=" ">
                  <div class="flex justify-center">
                    <img
                      class="w-[400px] h-[300px] border  md:w-[420px] md:h-[300px] lg:w-[350px] lg:h-[250px] xl:w-[400px] xl:h-[300px]"
                      src="../assets/ប្រព័ន្ធគ្រប់គ្រងអាជីវកម្ម.jpg" alt="Product Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div  id="history" class="mb-8 text-transparent select-none">_</div> -->

          <div id="history" class="">
            <div>
              <div class="text-center py-7">

                <h1
                  class="text-2xl mt-4 md:text-2xl md:mt-10 xl:mt-3 lg:text-xl font-NotoSansKhmer font-bold text-primery1 xl:text-2xl ">
                  បុព្វហេតុ និងប្រវត្តនៃការបង្កើតម៉ាស្ទ័រអាយធី ស៊ីស្ទឹម</h1>
              </div>

              <!-- Section Content -->

              <div class="space-y-3 text-[16px]  md:text-lg">


                <h5 class="text-[16px] md:text-lg  text-justify leading-8 text-black mt-2">
                  ដោយមើលឃើញពីភាពរីកចម្រើននៃវិស័យបច្ចេកវិទ្យា តម្រូវការអនាគតឆ្ពោះទៅរកបច្ចេកវិទ្យាឌីជីថលទំនើប
                  និងមើលឃើញពីការលំបាករបស់ម្ចាស់អាជីវកម្មជាច្រើននៅកម្ពុជាដែលមានបញ្ហាដូចជា៖
                </h5>
                <div>
                  <ul class="list-disc list-inside space-y-2 pl-2 md:pl-5">
                    <li>មានបញ្ហាលំបាកក្នុងការគ្រប់គ្រងប្រតិបត្តការប្រចាំថ្ងៃ</li>
                    <li>
                      មានបញ្ហាបាត់បង់ទិន្នន័យ បាត់ព័ត៌មាន និងមិនមានរបាយការណ៍ច្បាស់
                      ដែលនាំឱ្យមិនមានមូលដ្ឋានសម្រេចចិត្តត្រូវ
                    </li>
                    <li>
                      មានបញ្ហាខ្ចះខ្ចាយពេលវេលា ថវិការ និងធនធានមនុស្ស ដើម្បីរៀបចំការងារនិងរបាយការណ៍
                    </li>
                    <li>
                      មានបញ្ហាពិបាកគ្រប់គ្រង ស្តុក ការទិញចូល ការលក់ចេញ ចំណូល ចំណាយ ចំណេញ និងស្ថានភាពហិរញ្ញវត្ថុ
                    </li>
                    <li>មានបញ្ហាពិបាកតាមដានប្រតិបត្តការការងារ និងលទ្ធផលការងារ</li>
                    <li>មានបញ្ហាបាត់បង់ឱកាសចំណេញ ឱកាសរីកចម្រើនលឿន</li>
                    <li>
                      មានបញ្ហាបាត់បង់ភាពស្តង់ដារ ទំនើប ទាន់សម័យ ខ្សោយជាងគូរប្រគួតប្រជែង
                    </li>
                    <li>
                      មានបញ្ហាធ្វើប្រតិបត្តការអាជីវកម្មយឺត នាំបាត់បង់ទំនុកចិត្តពីអតិថិជន និងដៃគូរសហការ។ល។
                    </li>
                  </ul>
                </div>


                <div class="text-justify">
                  ម៉ាស្ទ័រអាយធី​ ស៊ីស្ទឹម ត្រូវបានបង្កើតឡើង ដើម្បីនាំមកនូវប្រព័ន្ធបច្ចកវិទ្យាឌីជីថល ទំនើប ទាន់សម័យ
                  ឈានមុខគេ
                  ដែលមានទាំងគុណភាព ប្រសិទ្ធភាព ភាពច្បាស់លាស់ ភាពងាយស្រួលប្រើប្រាស់ ជួយឱ្យម្ចាស់អាជីវកម្មកម្ពុជា
                  គ្រប់គ្រងអាជីវកម្មបានកាន់តែស្រួល និងរីកចម្រើនកាន់តែលឿន។
                </div>
                <div class="text-justify">
                  ផ្អែកលើមូលដ្ឋានគ្រឹះដ៏រឹងមាំក្នុងវិស័យបច្ចេកវិទ្យានិងឌីជីថល ភាពជាអ្នកជំនាញរបស់ក្រុមការងារ
                  ចក្ខុវិស័យវែងឆ្ងាយរបស់ក្រុមហ៊ុន និងបទពិសោធន៍យ៉ាងសម្បូរបែបក្នុងការបម្រើសេវាកម្មជូនអតិថិជន
                  ចាប់តាំងពីឆ្នាំ២០១២មក ម៉ាស្ទ័រអាយធី​ ស៊ីស្ទឹម ជឿជាក់ថា
                  ពួកយើងនឹងផ្តល់ជូននូវដំណោះស្រាយប្រព័ន្ធបច្ចេកវិទ្យាថ្មីបំផុត ជឿជាក់បំផុត បម្រើសេវាកម្មល្អបំផុត
                  និងរក្សាឱ្យបាននូវភាពជាដៃគូរដ៏ល្អ និងយូរអង្វែងឱ្យបានខ្ពស់បំផុត ជូនដល់អតិថិជនគ្រប់ទិសទី។

                </div>
              </div>
            </div>

            <div class='flex justify-center my-10'>
              <div class="">
                <p class="font-bold my-3 text-lg">រូបភាព</p>
                <img class="w-full h-[300px] md:w-[700px] md:h-[400px] lg:w-[600px] lg:h-[350px] xl:w-[800px] xl:h-[500px]" src="https://fakeimg.pl/700x400/" alt="">
              </div>
            </div>
          </div>

        </div>
      </div>




    </div>
  </div>


</template>

<script>
import { useRouter } from "vue-router";
import getCollection from "@/composible/getCollections.js";


import Navbar from "./Navbar.vue";
export default {
  components: {
    Navbar
  },
  setup() {


    const { documents: category } = getCollection('categories')


    const router = useRouter();

    return { category };
  },
};
</script>

<style scoped></style>
