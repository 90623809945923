<template>
  <div
    class="w-full flex flex-col items-center justify-center h-screen font-NotoSansKhmer"
  >
    <form
      @submit.prevent="handleSigUp"
      class="w-[90%] sm:w-[70%] md:w-[50%] lg:w-[40%] xl:w-[30%] h-[780px] bg-background rounded-md shadow-md"
    >
      <div
        class="w-full h-[30%] flex flex-col items-center justify-center border-b-2"
      >
        <img class="w-36" src="../assets/image/image 5.png" alt="" />
        <h2 class="text-Heading1 text-primery1 font-bold">Master-IT System</h2>
      </div>

      <div class="w-[90%] mx-auto p-4 space-y-3">
        <label class="text-xl font-semibold py-2">ឈ្មោះអ្នកប្រើប្រាស់ </label>
        <div class="input">
          <input
            type="text"
            class="outline-none bg-transparent w-full h-full"
            placeholder="ឈ្មោះ អ្នកប្រើប្រាស់
            "
            v-model="username"
            required
            autofocus
          />
        </div>
        <div class="input hidden">
          <input
            type="text"
            class="outline-none bg-transparent w-full h-full"
            placeholder="តួនាទី"
            v-model="role"
            required
            autofocus
          />
        </div>
        <div class="input hidden">
          <input
            type="text"
            class="outline-none bg-transparent w-full h-full"
            placeholder="តួនាទី"
            v-model="status"
            required
            autofocus
          />
        </div>
        <label class="text-xl font-semibold py-2">អ៊ីមែល</label>
        <div class="input">
          <input
            required
            type="text"
            class="outline-none bg-transparent w-full h-full"
            placeholder="អ៊ីមែល"
            v-model="email"
            autofocus
          />
        </div>
        <label class="text-xl font-semibold py-2">ពាក្យសម្ងាត់</label>
        <div class="input relative">
          <input
            required
            :type="showPassword ? 'text' : 'password'"
            placeholder="ពាក្យសម្ងាត់"
            class="w-full h-full bg-transparent outline-none"
            v-model="password"
          />
          <svg
            v-if="showPassword == true"
            @click="togglePasswordVisibility"
            xmlns="http://www.w3.org/2000/svg"
            class="absolute right-2 h-8 w-8"
            viewBox="0 0 24 24"
          >
            <path
              fill="#500192"
              d="M11.83 9L15 12.16V12a3 3 0 0 0-3-3zm-4.3.8l1.55 1.55c-.05.21-.08.42-.08.65a3 3 0 0 0 3 3c.22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53a5 5 0 0 1-5-5c0-.79.2-1.53.53-2.2M2 4.27l2.28 2.28l.45.45C3.08 8.3 1.78 10 1 12c1.73 4.39 6 7.5 11 7.5c1.55 0 3.03-.3 4.38-.84l.43.42L19.73 22L21 20.73L3.27 3M12 7a5 5 0 0 1 5 5c0 .64-.13 1.26-.36 1.82l2.93 2.93c1.5-1.25 2.7-2.89 3.43-4.75c-1.73-4.39-6-7.5-11-7.5c-1.4 0-2.74.25-4 .7l2.17 2.15C10.74 7.13 11.35 7 12 7"
            />
          </svg>
          <svg
            v-else
            @click="togglePasswordVisibility"
            xmlns="http://www.w3.org/2000/svg"
            class="w-8 h-8 absolute right-2"
            viewBox="0 0 24 24"
          >
            <path
              fill="#500192"
              d="M2 12c0 1.64.425 2.191 1.275 3.296C4.972 17.5 7.818 20 12 20c4.182 0 7.028-2.5 8.725-4.704C21.575 14.192 22 13.639 22 12c0-1.64-.425-2.191-1.275-3.296C19.028 6.5 16.182 4 12 4C7.818 4 4.972 6.5 3.275 8.704C2.425 9.81 2 10.361 2 12"
              opacity="0.5"
            />
            <path
              fill="#500192"
              fill-rule="evenodd"
              d="M8.25 12a3.75 3.75 0 1 1 7.5 0a3.75 3.75 0 0 1-7.5 0m1.5 0a2.25 2.25 0 1 1 4.5 0a2.25 2.25 0 0 1-4.5 0"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <span v-if="formSubmitted && password.length < 6" class="text-red-500"
          >ពាក្យសម្ងាត់ត្រូវតែមានយ៉ាងហោចណាស់ 6 តួអក្សរ </span
        ><br />
        <label class="text-xl font-semibold py-2">បញ្ជាក់ពាក្យសម្ងាត់</label>
        <div class="input relative">
          <input
            :type="showPassword ? 'text' : 'password'"
            placeholder="បញ្ជាក់ពាក្យសម្ងាត់"
            class="w-full h-full bg-transparent outline-none"
            v-model="confirmpassword"
            required
          />
          <svg
            v-if="showPassword == true"
            @click="togglePasswordVisibility"
            xmlns="http://www.w3.org/2000/svg"
            class="absolute right-2 h-8 w-8"
            viewBox="0 0 24 24"
          >
            <path
              fill="#500192"
              d="M11.83 9L15 12.16V12a3 3 0 0 0-3-3zm-4.3.8l1.55 1.55c-.05.21-.08.42-.08.65a3 3 0 0 0 3 3c.22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53a5 5 0 0 1-5-5c0-.79.2-1.53.53-2.2M2 4.27l2.28 2.28l.45.45C3.08 8.3 1.78 10 1 12c1.73 4.39 6 7.5 11 7.5c1.55 0 3.03-.3 4.38-.84l.43.42L19.73 22L21 20.73L3.27 3M12 7a5 5 0 0 1 5 5c0 .64-.13 1.26-.36 1.82l2.93 2.93c1.5-1.25 2.7-2.89 3.43-4.75c-1.73-4.39-6-7.5-11-7.5c-1.4 0-2.74.25-4 .7l2.17 2.15C10.74 7.13 11.35 7 12 7"
            />
          </svg>
          <svg
            v-else
            @click="togglePasswordVisibility"
            xmlns="http://www.w3.org/2000/svg"
            class="w-8 h-8 absolute right-2"
            viewBox="0 0 24 24"
          >
            <path
              fill="#500192"
              d="M2 12c0 1.64.425 2.191 1.275 3.296C4.972 17.5 7.818 20 12 20c4.182 0 7.028-2.5 8.725-4.704C21.575 14.192 22 13.639 22 12c0-1.64-.425-2.191-1.275-3.296C19.028 6.5 16.182 4 12 4C7.818 4 4.972 6.5 3.275 8.704C2.425 9.81 2 10.361 2 12"
              opacity="0.5"
            />
            <path
              fill="#500192"
              fill-rule="evenodd"
              d="M8.25 12a3.75 3.75 0 1 1 7.5 0a3.75 3.75 0 0 1-7.5 0m1.5 0a2.25 2.25 0 1 1 4.5 0a2.25 2.25 0 0 1-4.5 0"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div v-if="loading == false">
          <button
            type="submit"
            class="bg-primery1 w-full p-3 text-white text-body flex items-start justify-center font-semibold hover:bg-blue-500 transition-all ease-in-out duration-300"
          >
            ចុះឈ្មោះ
          </button>
        </div>
        <div v-else class="w-auto flex justify-end mx-auto">
          <button
            class="btndynamic w-full bg-primery1 flex items-center justify-center text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-8 h-8 mr-2"
              viewBox="0 0 24 24"
            >
              <defs>
                <linearGradient
                  id="mingcuteLoadingFill0"
                  x1="50%"
                  x2="50%"
                  y1="5.271%"
                  y2="91.793%"
                >
                  <stop offset="0%" stop-color="#500192" />
                  <stop
                    offset="100%"
                    stop-color="#500192"
                    stop-opacity="0.55"
                  />
                </linearGradient>
                <linearGradient
                  id="mingcuteLoadingFill1"
                  x1="50%"
                  x2="50%"
                  y1="15.24%"
                  y2="87.15%"
                >
                  <stop offset="0%" stop-color="#500192" stop-opacity="0" />
                  <stop
                    offset="100%"
                    stop-color="#500192"
                    stop-opacity="0.55"
                  />
                </linearGradient>
              </defs>
              <g fill="none">
                <path
                  d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"
                />
                <path
                  fill="url(#mingcuteLoadingFill0)"
                  d="M8.749.021a1.5 1.5 0 0 1 .497 2.958A7.502 7.502 0 0 0 3 10.375a7.5 7.5 0 0 0 7.5 7.5v3c-5.799 0-10.5-4.7-10.5-10.5C0 5.23 3.726.865 8.749.021"
                  transform="translate(1.5 1.625)"
                />
                <path
                  fill="url(#mingcuteLoadingFill1)"
                  d="M15.392 2.673a1.5 1.5 0 0 1 2.119-.115A10.475 10.475 0 0 1 21 10.375c0 5.8-4.701 10.5-10.5 10.5v-3a7.5 7.5 0 0 0 5.007-13.084a1.5 1.5 0 0 1-.115-2.118"
                  transform="translate(1.5 1.625)"
                />
              </g>
            </svg>
            កំពុងចុះឈ្មោះ
          </button>
        </div>
        <router-link class="w-full" to="/">
          <h2 class="text-lg text-primery1 underline text-center py-2">
            មានគណនីរួចហើយ
          </h2>
        </router-link>
      </div>
    </form>
  </div>
  <Notivue v-slot="item">
    <Notification :item="item" />
  </Notivue>
</template>

<script>
import useCollection from "@/composible/useCollection";
import useSignUp from "../composible/SignUp";
import { useRouter } from "vue-router";
import { ref, computed } from "vue";
import { push, Notivue, Notification } from "notivue";
import { timestamp } from "@/firebase/config";
export default {
  components: {
    Notivue,
    Notification,
  },
  setup() {
    const username = ref("");
    const email = ref("");
    const password = ref("");
    const confirmpassword = ref("");
    const role = ref("admin");
    const status = ref("active");
    const { addDocs, setDocs } = useCollection("users");
    const { signup, error } = useSignUp();
    const router = useRouter();
    const loading = ref(false);
    const showPassword = ref(false);
    const formSubmitted = ref(false);

    const isPasswordValid = computed(() => {
      return password.value.length >= 6;
    });
    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
    };
    const handleSigUp = async () => {
      formSubmitted.value = true;

      if (password.value.length < 6) {
        console.error("Password must be at least 6 characters long");
        return;
      }
      if (password.value.length >= 6) {
        loading.value = true;
      }
      try {
        if (password.value !== confirmpassword.value) {
          throw new Error("Password does not match");
        }
        const res = await signup(
          email.value,
          password.value,
          username.value,
          role.value,
          status.value
        );
        if (res && res.user && res.user.uid) {
          await setDocs(res.user.uid, {
            username: username.value,
            password: password.value,
            email: email.value,
            role: role.value,
            status: status.value,
            createdAt: timestamp(),
          });
          router.push({ name: "admin" });
        }
        push.success("Register Successfully");
      } catch (err) {
        error.value = err.message;
      }
    };
    return {
      togglePasswordVisibility,
      username,
      email,
      confirmpassword,
      password,
      role,
      status,
      handleSigUp,
      showPassword,
      push,
      isPasswordValid,
      formSubmitted,
      loading,
    };
  },
};
</script>

<style lang="scss" scoped></style>
