<template>
  <div class="flex flex-col items-center justify-center w-full h-screen font-NotoSansKhmer">
    <form @submit.prevent="handleSignIn"
      class="w-[90%] sm:w-[70%] md:w-[50%] lg:w-[40%] xl:w-[30%] h-[700px] bg-background rounded-md shadow-md">
      <div class="w-full h-[40%] flex flex-col items-center justify-center border-b-2">
        <img class="w-36" src="../assets/image/image 5.png" alt="" />
        <h2 class="font-bold text-Heading1 text-primery1">Master-IT System</h2>
      </div>
      <div class="w-[90%]  mx-auto p-4 space-y-3">

        <div v-if="msg">
          <div class="p-2 my-3 bg-red-500">
            <p class="text-white ">Incorrect email or password</p>
          </div>
        </div>


        <label class="py-2 text-xl font-semibold">អ៊ីមែល</label>
        <div class="input">
          <input type="text" class="w-full h-full bg-transparent outline-none" placeholder="អ៊ីមែល" v-model="email"
            autofocus required />
        </div>
        <span v-if="!isValidEmail" class="text-red-500">សូមបញ្ចូលអ៊ីមែលត្រឺមត្រូវ</span>
        <br />
        <label class="py-2 text-xl font-semibold">ពាក្យសម្ងាត់</label>
        <div class="relative input">
          <input :type="showPassword ? 'text' : 'password'" placeholder="ពាក្យសម្ងាត់"
            class="w-full h-full bg-transparent outline-none" v-model="password" required />
          <svg v-if="showPassword == true" @click="togglePasswordVisibility" xmlns="http://www.w3.org/2000/svg"
            class="absolute w-8 h-8 right-2" viewBox="0 0 24 24">
            <path fill="#500192"
              d="M11.83 9L15 12.16V12a3 3 0 0 0-3-3zm-4.3.8l1.55 1.55c-.05.21-.08.42-.08.65a3 3 0 0 0 3 3c.22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53a5 5 0 0 1-5-5c0-.79.2-1.53.53-2.2M2 4.27l2.28 2.28l.45.45C3.08 8.3 1.78 10 1 12c1.73 4.39 6 7.5 11 7.5c1.55 0 3.03-.3 4.38-.84l.43.42L19.73 22L21 20.73L3.27 3M12 7a5 5 0 0 1 5 5c0 .64-.13 1.26-.36 1.82l2.93 2.93c1.5-1.25 2.7-2.89 3.43-4.75c-1.73-4.39-6-7.5-11-7.5c-1.4 0-2.74.25-4 .7l2.17 2.15C10.74 7.13 11.35 7 12 7" />
          </svg>
          <svg v-else @click="togglePasswordVisibility" xmlns="http://www.w3.org/2000/svg"
            class="absolute w-8 h-8 right-2" viewBox="0 0 24 24">
            <path fill="#500192"
              d="M2 12c0 1.64.425 2.191 1.275 3.296C4.972 17.5 7.818 20 12 20c4.182 0 7.028-2.5 8.725-4.704C21.575 14.192 22 13.639 22 12c0-1.64-.425-2.191-1.275-3.296C19.028 6.5 16.182 4 12 4C7.818 4 4.972 6.5 3.275 8.704C2.425 9.81 2 10.361 2 12"
              opacity="0.5" />
            <path fill="#500192" fill-rule="evenodd"
              d="M8.25 12a3.75 3.75 0 1 1 7.5 0a3.75 3.75 0 0 1-7.5 0m1.5 0a2.25 2.25 0 1 1 4.5 0a2.25 2.25 0 0 1-4.5 0"
              clip-rule="evenodd" />
          </svg>
        </div>
        <div v-if="loading == false">
          <button type="submit"
            class="flex items-start justify-center w-full p-3 font-semibold text-white transition-all duration-300 ease-in-out bg-primery1 text-body hover:bg-blue-500">
            ចូលគណនី
          </button>
        </div>
        <div v-else class="flex justify-end w-auto mx-auto">
          <button class="flex items-center justify-center w-full p-3 text-white bg-primery1">

            <svg aria-hidden="true" role="status" class="inline w-6 h-6 text-white me-3 animate-spin"
              viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB" />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor" />
            </svg>

            កំពុងដំណើរការ
          </button>
        </div>
      </div>
      <router-link class="w-full text-center" to="/register">
        <h2 class="text-lg underline text-primery1">មិនទាន់មានគណនី</h2>
      </router-link>
      <router-link class="w-full text-center" to="/forgetpassword">
        <h2 class="text-lg underline text-primery1">ភ្លេចពាក្យសម្ងាត់ </h2>
      </router-link>
    </form>
  </div>
</template>

<script>
import useSignIn from "../composible/SigneIn";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { push } from 'notivue';

export default {
  setup() {
    const router = useRouter();
    const { signin, error, isPending } = useSignIn();
    const email = ref("");
    const password = ref("");
    const showPassword = ref(false);
    const loading = ref(false);
    const isValidEmail = ref(true); // Initialize as true
    const msg = ref(false)

    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
    };

    const validateEmail = () => {
      const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/; // Regular expression for email validation
      isValidEmail.value = re.test(email.value);
      loading.value = false
    };

    const handleSignIn = async () => {
      validateEmail(); // Validate email before signing in
      if (isValidEmail.value) {

        loading.value = true;
        await signin(email.value, password.value);
        if (!error.value) {
          loading.value = false
          // router.push({ name: "services" });
          router.push({ name: "admin" });
        }
        else {

          loading.value = false


          msg.value = true
        }
        // Return if email is not valid
      }

    };

    return {
      togglePasswordVisibility,
      password,
      showPassword,
      handleSignIn,
      email,
      loading,
      isValidEmail,
      msg
    };
  },
};
</script>

<style lang="scss" scoped></style>
