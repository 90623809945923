// import { collection, getDocs } from '@firebase/firestore';
// import { projectFirestore } from '@/firebase/config';
// import { ref, watchEffect, onUnmounted } from 'vue';

// const getDocument = (collectionName, id, subcollection) => {
//     const error = ref(null);
//     const documents = ref([]);
//     if (id) {
//         const subcollectionRef = collection(projectFirestore, collectionName, id, subcollection);

//         const fetchData = async () => {
//             try {
//                 const snapshot = await getDocs(subcollectionRef);
//                 const result = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
//                 documents.value = result;
//                 error.value = null;
//             } catch (err) {
//                 error.value = err.message;
//                 documents.value = [];
//             }
//         };

//         const unsubscribe = watchEffect((onInvalidate) => {
//             fetchData();
//         });

//         onUnmounted(() => {
//             unsubscribe();
//         });
//     }



//     return {
//         error,
//         documents,
//     };
// };


import { projectFirestore } from "@/firebase/config"
import { collection, doc, onSnapshot } from "firebase/firestore"
import { ref, watchEffect } from "vue"


const getDocument = (collectionName, id, subCollection) => {

    const isLoading = ref(true)
    const documents = ref(null)
    const documentRef = doc(projectFirestore,`${collectionName}/${id}/`)
    const subCollectionRef = collection(documentRef, subCollection);
    const unsubscripe = onSnapshot(subCollectionRef, (qry) => {
      
        const result = []
        qry.forEach((doc) => {
            result.push({id: doc.id, ...doc.data()})
        })
        documents.value = result
        isLoading.value = false
    },(err) => {
        console.log(err)
    })

    
    watchEffect((onInvaliDate) => {
        onInvaliDate(()=>unsubscripe())
    })

    return {documents, isLoading}
}


export default getDocument;
