<template>
  <div v-motion :initial="{
    opacity: 0,
  }" :enter="{

    opacity: 1,
    transition: {
      duration: 500,

      ease: 'easeIn',
    },
  }" class="w-full bg-gradient-to-l from-[#500192] to-indigo-600 pt-4">



    <div
      class="md:w-[95%] md:mx-auto lg:w-[90%] mx-auto xl:w-[1200px] xl:px-4  md:flex xl:flex hidden items-center justify-between mt-12">

      <div class="flex justify-between w-full ">
        <div class="md:w-[356px] xl:w-[370px] lg:w-[310px] ">
          <img src="../assets/image/image 5.png"
            class="md:w-[90px] md:h-[90px] lg:w-[90px] lg:h-[90px] xl:w-[120px] xl:h-[120px] mx-[45px]" alt="" />
          <div class="w-full ">
            <h3 class="text-white md:text-[22px] xl:text-[26px] mt-[10px] lg:text-[22px]">Master IT System</h3>
            <p class="xl:text-[16px] md:text-[14px] lg:text-[14px] text-white md:mt-[15px] lg:mt-[10px] ">
              យើងជឿជាក់ថា កក្តាដ៏សំខាន់មួយជួយធ្វើឱ្យអាជីវកម្ម
              និងប្រទេសជាតិរីកចម្រើនលឿន
              គឺកក្តាជឿនលឿននៃបច្ចេកវិទ្យា។​​​ <br> ម៉ាស្ទ័រអាយធីស៊ីស្ទឹមនឹងក្លាយជាក្រុមហ៊ុនបច្ចេកវិទ្យា
              ឈានមុខគេបង្កើតមោតនភាពជូនប្រជាជនកម្ពុជា។
            </p>
          </div>

          <div class="mt-3">
            <div class="flex gap-[20px] mb-10">
              <a href="https://t.me/masteritsystems_saleconsultant" class="flex group" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg"
                  class="lg:w-[32px] lg:h-[32px] xl:w-[28px]  xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] group-hover:hidden duration-300"
                  viewBox="0 0 20 20">
                  <path fill="white"
                    d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10S4.477 0 10 0m4.442 6c-.381.007-.966.207-3.779 1.362a485.41 485.41 0 0 0-5.907 2.512c-.48.189-.73.373-.753.553c-.044.346.46.453 1.094.657c.517.166 1.213.36 1.575.368c.328.007.694-.127 1.098-.4c2.76-1.84 4.183-2.769 4.273-2.789c.063-.014.15-.032.21.02c.059.052.053.15.046.177c-.05.211-2.641 2.538-2.79 2.691l-.072.072c-.55.543-1.105.898-.147 1.521c.866.563 1.37.922 2.26 1.5c.57.368 1.017.805 1.605.752c.271-.025.55-.276.693-1.026c.335-1.77.995-5.608 1.147-7.19a1.742 1.742 0 0 0-.017-.393a.42.42 0 0 0-.144-.27c-.121-.098-.309-.118-.392-.117" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg"
                  class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] hidden group-hover:block"
                  viewBox="0 0 256 256">
                  <defs>
                    <linearGradient id="logosTelegram0" x1="50%" x2="50%" y1="0%" y2="100%">
                      <stop offset="0%" stop-color="#2aabee" />
                      <stop offset="100%" stop-color="#229ed9" />
                    </linearGradient>
                  </defs>
                  <path fill="url(#logosTelegram0)"
                    d="M128 0C94.06 0 61.48 13.494 37.5 37.49A128.038 128.038 0 0 0 0 128c0 33.934 13.5 66.514 37.5 90.51C61.48 242.506 94.06 256 128 256s66.52-13.494 90.5-37.49c24-23.996 37.5-56.576 37.5-90.51c0-33.934-13.5-66.514-37.5-90.51C194.52 13.494 161.94 0 128 0" />
                  <path fill="#fff"
                    d="M57.94 126.648c37.32-16.256 62.2-26.974 74.64-32.152c35.56-14.786 42.94-17.354 47.76-17.441c1.06-.017 3.42.245 4.96 1.49c1.28 1.05 1.64 2.47 1.82 3.467c.16.996.38 3.266.2 5.038c-1.92 20.24-10.26 69.356-14.5 92.026c-1.78 9.592-5.32 12.808-8.74 13.122c-7.44.684-13.08-4.912-20.28-9.63c-11.26-7.386-17.62-11.982-28.56-19.188c-12.64-8.328-4.44-12.906 2.76-20.386c1.88-1.958 34.64-31.748 35.26-34.45c.08-.338.16-1.598-.6-2.262c-.74-.666-1.84-.438-2.64-.258c-1.14.256-19.12 12.152-54 35.686c-5.1 3.508-9.72 5.218-13.88 5.128c-4.56-.098-13.36-2.584-19.9-4.708c-8-2.606-14.38-3.984-13.82-8.41c.28-2.304 3.46-4.662 9.52-7.072" />
                </svg>
              </a>
              <a href="https://www.facebook.com/system.master.it" class="flex group" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg"
                  class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] group-hover:hidden duration-300"
                  viewBox="0 0 512 512">
                  <path fill="white"
                    d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48c27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg"
                  class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] hidden group-hover:block"
                  viewBox="0 0 256 256">
                  <path fill="#1877f2"
                    d="M256 128C256 57.308 198.692 0 128 0C57.308 0 0 57.308 0 128c0 63.888 46.808 116.843 108 126.445V165H75.5v-37H108V99.8c0-32.08 19.11-49.8 48.348-49.8C170.352 50 185 52.5 185 52.5V84h-16.14C152.959 84 148 93.867 148 103.99V128h35.5l-5.675 37H148v89.445c61.192-9.602 108-62.556 108-126.445" />
                  <path fill="#fff"
                    d="m177.825 165l5.675-37H148v-24.01C148 93.866 152.959 84 168.86 84H185V52.5S170.352 50 156.347 50C127.11 50 108 67.72 108 99.8V128H75.5v37H108v89.445A128.959 128.959 0 0 0 128 256a128.9 128.9 0 0 0 20-1.555V165z" />
                </svg>


              </a>



              <!-- <div class="flex cursor-pointer group">
                <svg xmlns="http://www.w3.org/2000/svg"
                  class="lg:w-[32px] lg:h-[32px] cursor-pointer xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] group-hover:hidden duration-300"
                  viewBox="0 0 32 32">
                  <path fill="white"
                    d="M0 15.521C0 6.599 6.984 0 16 0s16 6.599 16 15.521c0 8.917-6.984 15.521-16 15.521c-1.615 0-3.172-.214-4.625-.615a1.266 1.266 0 0 0-.854.068l-3.188 1.401a1.282 1.282 0 0 1-1.802-1.135l-.094-2.854a1.281 1.281 0 0 0-.422-.906A15.192 15.192 0 0 1-.001 15.522zm11.094-2.922l-4.693 7.469c-.469.703.427 1.521 1.094 1l5.052-3.828a.944.944 0 0 1 1.161 0l3.729 2.802a2.41 2.41 0 0 0 3.469-.641l4.693-7.469c.469-.703-.427-1.505-1.094-1l-5.052 3.828a.923.923 0 0 1-1.146 0l-3.734-2.802a2.398 2.398 0 0 0-3.479.641" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg"
                  class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] hidden group-hover:block"
                  viewBox="0 0 256 256">
                  <defs>
                    <radialGradient id="logosMessenger0" cx="19.247%" cy="99.465%" r="108.96%" fx="19.247%"
                      fy="99.465%">
                      <stop offset="0%" stop-color="#09f" />
                      <stop offset="60.975%" stop-color="#a033ff" />
                      <stop offset="93.482%" stop-color="#ff5280" />
                      <stop offset="100%" stop-color="#ff7061" />
                    </radialGradient>
                  </defs>
                  <path fill="url(#logosMessenger0)"
                    d="M128 0C55.894 0 0 52.818 0 124.16c0 37.317 15.293 69.562 40.2 91.835c2.09 1.871 3.352 4.493 3.438 7.298l.697 22.77c.223 7.262 7.724 11.988 14.37 9.054L84.111 243.9a10.218 10.218 0 0 1 6.837-.501c11.675 3.21 24.1 4.92 37.052 4.92c72.106 0 128-52.818 128-124.16S200.106 0 128 0" />
                  <path fill="#fff"
                    d="m51.137 160.47l37.6-59.653c5.98-9.49 18.788-11.853 27.762-5.123l29.905 22.43a7.68 7.68 0 0 0 9.252-.027l40.388-30.652c5.39-4.091 12.428 2.36 8.82 8.085l-37.6 59.654c-5.981 9.489-18.79 11.852-27.763 5.122l-29.906-22.43a7.68 7.68 0 0 0-9.25.027l-40.39 30.652c-5.39 4.09-12.427-2.36-8.818-8.085" />
                </svg>
              </div> -->



              <a href="https://www.tiktok.com/@master_it_system" class="flex group"
                target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg"
                  class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] group-hover:hidden duration-300"
                  viewBox="0 0 512 512">
                  <path fill="white"
                    d="M412.19 118.66a109.27 109.27 0 0 1-9.45-5.5a132.87 132.87 0 0 1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14 23.9 350 16 350.13 16h-82.44v318.78c0 4.28 0 8.51-.18 12.69c0 .52-.05 1-.08 1.56c0 .23 0 .47-.05.71v.18a70 70 0 0 1-35.22 55.56a68.8 68.8 0 0 1-34.11 9c-38.41 0-69.54-31.32-69.54-70s31.13-70 69.54-70a68.9 68.9 0 0 1 21.41 3.39l.1-83.94a153.14 153.14 0 0 0-118 34.52a161.79 161.79 0 0 0-35.3 43.53c-3.48 6-16.61 30.11-18.2 69.24c-1 22.21 5.67 45.22 8.85 54.73v.2c2 5.6 9.75 24.71 22.38 40.82A167.53 167.53 0 0 0 115 470.66v-.2l.2.2c39.91 27.12 84.16 25.34 84.16 25.34c7.66-.31 33.32 0 62.46-13.81c32.32-15.31 50.72-38.12 50.72-38.12a158.46 158.46 0 0 0 27.64-45.93c7.46-19.61 9.95-43.13 9.95-52.53V176.49c1 .6 14.32 9.41 14.32 9.41s19.19 12.3 49.13 20.31c21.48 5.7 50.42 6.9 50.42 6.9v-81.84c-10.14 1.1-30.73-2.1-51.81-12.61" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg"
                  class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] hidden group-hover:block"
                  viewBox="0 0 256 290">
                  <path fill="#ff004f"
                    d="M189.72 104.421c18.678 13.345 41.56 21.197 66.273 21.197v-47.53a67.115 67.115 0 0 1-13.918-1.456v37.413c-24.711 0-47.59-7.851-66.272-21.195v96.996c0 48.523-39.356 87.855-87.9 87.855c-18.113 0-34.949-5.473-48.934-14.86c15.962 16.313 38.222 26.432 62.848 26.432c48.548 0 87.905-39.332 87.905-87.857v-96.995zm17.17-47.952c-9.546-10.423-15.814-23.893-17.17-38.785v-6.113h-13.189c3.32 18.927 14.644 35.097 30.358 44.898M69.673 225.607a40.008 40.008 0 0 1-8.203-24.33c0-22.192 18.001-40.186 40.21-40.186a40.313 40.313 0 0 1 12.197 1.883v-48.593c-4.61-.631-9.262-.9-13.912-.801v37.822a40.268 40.268 0 0 0-12.203-1.882c-22.208 0-40.208 17.992-40.208 40.187c0 15.694 8.997 29.281 22.119 35.9" />
                  <path
                    d="M175.803 92.849c18.683 13.344 41.56 21.195 66.272 21.195V76.631c-13.794-2.937-26.005-10.141-35.186-20.162c-15.715-9.802-27.038-25.972-30.358-44.898h-34.643v189.843c-.079 22.132-18.049 40.052-40.21 40.052c-13.058 0-24.66-6.221-32.007-15.86c-13.12-6.618-22.118-20.206-22.118-35.898c0-22.193 18-40.187 40.208-40.187c4.255 0 8.356.662 12.203 1.882v-37.822c-47.692.985-86.047 39.933-86.047 87.834c0 23.912 9.551 45.589 25.053 61.428c13.985 9.385 30.82 14.86 48.934 14.86c48.545 0 87.9-39.335 87.9-87.857z" />
                  <path fill="#00f2ea"
                    d="M242.075 76.63V66.516a66.285 66.285 0 0 1-35.186-10.047a66.47 66.47 0 0 0 35.186 20.163M176.53 11.57a67.788 67.788 0 0 1-.728-5.457V0h-47.834v189.845c-.076 22.13-18.046 40.05-40.208 40.05a40.06 40.06 0 0 1-18.09-4.287c7.347 9.637 18.949 15.857 32.007 15.857c22.16 0 40.132-17.918 40.21-40.05V11.571zM99.966 113.58v-10.769a88.787 88.787 0 0 0-12.061-.818C39.355 101.993 0 141.327 0 189.845c0 30.419 15.467 57.227 38.971 72.996c-15.502-15.838-25.053-37.516-25.053-61.427c0-47.9 38.354-86.848 86.048-87.833" />
                </svg>
              </a>



              <a href="https://www.youtube.com/@master-itsystems" class="flex group" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg"
                  class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] group-hover:hidden duration-300"
                  viewBox="0 0 24 24">
                  <path fill="white"
                    d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814M9.545 15.568V8.432L15.818 12z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg"
                  class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] hidden group-hover:block"
                  viewBox="0 0 256 180">
                  <path fill="#f00"
                    d="M250.346 28.075A32.18 32.18 0 0 0 227.69 5.418C207.824 0 127.87 0 127.87 0S47.912.164 28.046 5.582A32.18 32.18 0 0 0 5.39 28.24c-6.009 35.298-8.34 89.084.165 122.97a32.18 32.18 0 0 0 22.656 22.657c19.866 5.418 99.822 5.418 99.822 5.418s79.955 0 99.82-5.418a32.18 32.18 0 0 0 22.657-22.657c6.338-35.348 8.291-89.1-.164-123.134" />
                  <path fill="#fff" d="m102.421 128.06l66.328-38.418l-66.328-38.418z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div class="md:w-[244px] xl:w-[286px] lg:w-[310px] md:ml-[30px] lg:ml-10">
          <div class="">
            <div>
              <h3 class="xl:text-[24px] md:text-[18px] lg:text-[18px] font-bold text-white">ទំព័រផ្សេងៗ</h3>
              <hr class="lg:w-[50px] md:w-[50px] xl:w-[64px] md:h-[3px] lg:h-[3px] bg-white rounded-full lg:mt-[2px]">
            </div>
          </div>
          <div class="">
            <div class="flex flex-col space-y-3  md:mt-[14px]">
              <router-link to="/"
                class="lg:text-[16px] md:text-[16px] xl:text-[18px] text-white uppercase duration-300 hover:text-indigo-500">
                ទំព័រដើម
              </router-link>

              <router-link to="/productsandservices"
                class="xl:text-[18px]  md:text-[16px] lg:text-[16px]  text-white uppercase duration-300 hover:text-indigo-500">
                សេវាកម្ម
              </router-link>
              <router-link to="/viewAllproduct"
                class="xl:text-[18px]  md:text-[16px] lg:text-[16px]  text-white uppercase duration-300 hover:text-indigo-500">
                ឧបករណ៍
              </router-link>
              <router-link to="/aboutUs"
                class="xl:text-[18px]  md:text-[16px] lg:text-[16px]  text-white uppercase duration-300 hover:text-indigo-500">
                អំពីក្រុមហ៊ុន
              </router-link>
              <router-link to="/contact"
                class="xl:text-[18px]  md:text-[16px] lg:text-[16px]  text-white uppercase duration-300 hover:text-indigo-500">
                ទំនាក់ទំនង
              </router-link>
            </div>
          </div>
        </div>
        <div>
          <div class="xl:w-[370px] lg:w-[340px] md:w-[370px]">
            <div>
              <h3 class="md:text-[18px] lg:text-[18px] xl:text-[24px] font-bold text-white">ទំនាក់ទំនង</h3>
              <hr class="md:w-[50px]  xl:w-[64px]  lg:w-[50px] h-[3px] bg-white rounded-md">
            </div>
            <div>
              <div class="flex flex-col space-y-3 md:mt-[12px] lg:mt-[14px]">
                <div
                  class="lg:text-[14px] md:text-[16px] xl:text-[16px] text-white uppercase duration-300 flex items-center space-x-2">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                  </svg>

                  <p>master.it.systems.team@gmail.com</p>

                </div>

                <div
                  class="lg:text-[14px] md:text-[16px] xl:text-[16px] text-white uppercase duration-300 flex items-center space-x-2">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="size-[24px]">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                  </svg>

                  <p>095 596 656 / 096 50 21 168</p>

                </div>
                <div
                  class="lg:text-[14px] md:text-[16px] xl:text-[16px] text-white uppercase   duration-300 flex items-center space-x-2">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0,0,256,256">
                    <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                      stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
                      font-family="none" font-weight="none" font-size="none" text-anchor="none"
                      style="mix-blend-mode: normal">
                      <g transform="scale(5.12,5.12)">
                        <path
                          d="M46.137,6.552c-0.75,-0.636 -1.928,-0.727 -3.146,-0.238h-0.002c-1.281,0.514 -36.261,15.518 -37.685,16.131c-0.259,0.09 -2.521,0.934 -2.288,2.814c0.208,1.695 2.026,2.397 2.248,2.478l8.893,3.045c0.59,1.964 2.765,9.21 3.246,10.758c0.3,0.965 0.789,2.233 1.646,2.494c0.752,0.29 1.5,0.025 1.984,-0.355l5.437,-5.043l8.777,6.845l0.209,0.125c0.596,0.264 1.167,0.396 1.712,0.396c0.421,0 0.825,-0.079 1.211,-0.237c1.315,-0.54 1.841,-1.793 1.896,-1.935l6.556,-34.077c0.4,-1.82 -0.156,-2.746 -0.694,-3.201zM22,32l-3,8l-3,-10l23,-17z">
                        </path>
                      </g>
                    </g>
                  </svg>
                  <a href="https://t.me/masteritsystems_saleconsultant">https://t.me/masteritsystems_saleconsultant</a>

                </div>


                <div
                  class="lg:text-[14px] md:text-[16px] xl:text-[16px] text-white uppercase  duration-300 flex items-center space-x-2">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="size-[45px]">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                  </svg>

                  <p>Phum Romchek IV, Sangkat Ratanak, Krong Battambang, Battambang Province</p>

                </div>
              </div>
            </div>
          </div>



          <!-- <div>
            <div class="">
              <div class="my-5">
                <h3 class="lg:text-[18px] xl:text-[24px] font-bold text-white">ប្រព័ន្ធផ្សព្វផ្សាយ</h3>
                <hr class="md:w-[50px] lg:w-[50px] xl:w-[64px] h-[3px] bg-white rounded-md">
              </div>
              <div class="flex gap-[20px] mb-10">
                <a href="https://t.me/masteritsystems_saleconsultant" class="flex group" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg"
                    class="lg:w-[32px] lg:h-[32px] xl:w-[28px]  xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] group-hover:hidden duration-300"
                    viewBox="0 0 20 20">
                    <path fill="white"
                      d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10S4.477 0 10 0m4.442 6c-.381.007-.966.207-3.779 1.362a485.41 485.41 0 0 0-5.907 2.512c-.48.189-.73.373-.753.553c-.044.346.46.453 1.094.657c.517.166 1.213.36 1.575.368c.328.007.694-.127 1.098-.4c2.76-1.84 4.183-2.769 4.273-2.789c.063-.014.15-.032.21.02c.059.052.053.15.046.177c-.05.211-2.641 2.538-2.79 2.691l-.072.072c-.55.543-1.105.898-.147 1.521c.866.563 1.37.922 2.26 1.5c.57.368 1.017.805 1.605.752c.271-.025.55-.276.693-1.026c.335-1.77.995-5.608 1.147-7.19a1.742 1.742 0 0 0-.017-.393a.42.42 0 0 0-.144-.27c-.121-.098-.309-.118-.392-.117" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg"
                    class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] hidden group-hover:block"
                    viewBox="0 0 256 256">
                    <defs>
                      <linearGradient id="logosTelegram0" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0%" stop-color="#2aabee" />
                        <stop offset="100%" stop-color="#229ed9" />
                      </linearGradient>
                    </defs>
                    <path fill="url(#logosTelegram0)"
                      d="M128 0C94.06 0 61.48 13.494 37.5 37.49A128.038 128.038 0 0 0 0 128c0 33.934 13.5 66.514 37.5 90.51C61.48 242.506 94.06 256 128 256s66.52-13.494 90.5-37.49c24-23.996 37.5-56.576 37.5-90.51c0-33.934-13.5-66.514-37.5-90.51C194.52 13.494 161.94 0 128 0" />
                    <path fill="#fff"
                      d="M57.94 126.648c37.32-16.256 62.2-26.974 74.64-32.152c35.56-14.786 42.94-17.354 47.76-17.441c1.06-.017 3.42.245 4.96 1.49c1.28 1.05 1.64 2.47 1.82 3.467c.16.996.38 3.266.2 5.038c-1.92 20.24-10.26 69.356-14.5 92.026c-1.78 9.592-5.32 12.808-8.74 13.122c-7.44.684-13.08-4.912-20.28-9.63c-11.26-7.386-17.62-11.982-28.56-19.188c-12.64-8.328-4.44-12.906 2.76-20.386c1.88-1.958 34.64-31.748 35.26-34.45c.08-.338.16-1.598-.6-2.262c-.74-.666-1.84-.438-2.64-.258c-1.14.256-19.12 12.152-54 35.686c-5.1 3.508-9.72 5.218-13.88 5.128c-4.56-.098-13.36-2.584-19.9-4.708c-8-2.606-14.38-3.984-13.82-8.41c.28-2.304 3.46-4.662 9.52-7.072" />
                  </svg>
                </a>
                <a href="https://www.facebook.com/system.master.it" class="flex group"
                  target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg"
                    class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] group-hover:hidden duration-300"
                    viewBox="0 0 512 512">
                    <path fill="white"
                      d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48c27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg"
                    class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] hidden group-hover:block"
                    viewBox="0 0 256 256">
                    <path fill="#1877f2"
                      d="M256 128C256 57.308 198.692 0 128 0C57.308 0 0 57.308 0 128c0 63.888 46.808 116.843 108 126.445V165H75.5v-37H108V99.8c0-32.08 19.11-49.8 48.348-49.8C170.352 50 185 52.5 185 52.5V84h-16.14C152.959 84 148 93.867 148 103.99V128h35.5l-5.675 37H148v89.445c61.192-9.602 108-62.556 108-126.445" />
                    <path fill="#fff"
                      d="m177.825 165l5.675-37H148v-24.01C148 93.866 152.959 84 168.86 84H185V52.5S170.352 50 156.347 50C127.11 50 108 67.72 108 99.8V128H75.5v37H108v89.445A128.959 128.959 0 0 0 128 256a128.9 128.9 0 0 0 20-1.555V165z" />
                  </svg>


                </a>



                <div class="flex group">
                  <svg xmlns="http://www.w3.org/2000/svg"
                    class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] group-hover:hidden duration-300"
                    viewBox="0 0 32 32">
                    <path fill="white"
                      d="M0 15.521C0 6.599 6.984 0 16 0s16 6.599 16 15.521c0 8.917-6.984 15.521-16 15.521c-1.615 0-3.172-.214-4.625-.615a1.266 1.266 0 0 0-.854.068l-3.188 1.401a1.282 1.282 0 0 1-1.802-1.135l-.094-2.854a1.281 1.281 0 0 0-.422-.906A15.192 15.192 0 0 1-.001 15.522zm11.094-2.922l-4.693 7.469c-.469.703.427 1.521 1.094 1l5.052-3.828a.944.944 0 0 1 1.161 0l3.729 2.802a2.41 2.41 0 0 0 3.469-.641l4.693-7.469c.469-.703-.427-1.505-1.094-1l-5.052 3.828a.923.923 0 0 1-1.146 0l-3.734-2.802a2.398 2.398 0 0 0-3.479.641" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg"
                    class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] hidden group-hover:block"
                    viewBox="0 0 256 256">
                    <defs>
                      <radialGradient id="logosMessenger0" cx="19.247%" cy="99.465%" r="108.96%" fx="19.247%"
                        fy="99.465%">
                        <stop offset="0%" stop-color="#09f" />
                        <stop offset="60.975%" stop-color="#a033ff" />
                        <stop offset="93.482%" stop-color="#ff5280" />
                        <stop offset="100%" stop-color="#ff7061" />
                      </radialGradient>
                    </defs>
                    <path fill="url(#logosMessenger0)"
                      d="M128 0C55.894 0 0 52.818 0 124.16c0 37.317 15.293 69.562 40.2 91.835c2.09 1.871 3.352 4.493 3.438 7.298l.697 22.77c.223 7.262 7.724 11.988 14.37 9.054L84.111 243.9a10.218 10.218 0 0 1 6.837-.501c11.675 3.21 24.1 4.92 37.052 4.92c72.106 0 128-52.818 128-124.16S200.106 0 128 0" />
                    <path fill="#fff"
                      d="m51.137 160.47l37.6-59.653c5.98-9.49 18.788-11.853 27.762-5.123l29.905 22.43a7.68 7.68 0 0 0 9.252-.027l40.388-30.652c5.39-4.091 12.428 2.36 8.82 8.085l-37.6 59.654c-5.981 9.489-18.79 11.852-27.763 5.122l-29.906-22.43a7.68 7.68 0 0 0-9.25.027l-40.39 30.652c-5.39 4.09-12.427-2.36-8.818-8.085" />
                  </svg>
                </div>

                <div class="flex group">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                    class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] group-hover:hidden duration-300"
                    viewBox="0,0,256,256">
                    <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                      stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
                      font-family="none" font-weight="none" font-size="none" text-anchor="none"
                      style="mix-blend-mode: normal">
                      <g transform="scale(5.12,5.12)">
                        <path
                          d="M16,3c-7.17,0 -13,5.83 -13,13v18c0,7.17 5.83,13 13,13h18c7.17,0 13,-5.83 13,-13v-18c0,-7.17 -5.83,-13 -13,-13zM37,11c1.1,0 2,0.9 2,2c0,1.1 -0.9,2 -2,2c-1.1,0 -2,-0.9 -2,-2c0,-1.1 0.9,-2 2,-2zM25,14c6.07,0 11,4.93 11,11c0,6.07 -4.93,11 -11,11c-6.07,0 -11,-4.93 -11,-11c0,-6.07 4.93,-11 11,-11zM25,16c-4.96,0 -9,4.04 -9,9c0,4.96 4.04,9 9,9c4.96,0 9,-4.04 9,-9c0,-4.96 -4.04,-9 -9,-9z">
                        </path>
                      </g>
                    </g>
                  </svg>

                  <div
                    class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] hidden group-hover:block">
                    <img width="28px"
                      src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Instagram_colored_svg_1-512.png"
                      alt="">
                  </div>


                </div>


                <a href="https://www.tiktok.com/@master_it_system" class="flex group"
                  target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg"
                    class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] group-hover:hidden duration-300"
                    viewBox="0 0 512 512">
                    <path fill="white"
                      d="M412.19 118.66a109.27 109.27 0 0 1-9.45-5.5a132.87 132.87 0 0 1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14 23.9 350 16 350.13 16h-82.44v318.78c0 4.28 0 8.51-.18 12.69c0 .52-.05 1-.08 1.56c0 .23 0 .47-.05.71v.18a70 70 0 0 1-35.22 55.56a68.8 68.8 0 0 1-34.11 9c-38.41 0-69.54-31.32-69.54-70s31.13-70 69.54-70a68.9 68.9 0 0 1 21.41 3.39l.1-83.94a153.14 153.14 0 0 0-118 34.52a161.79 161.79 0 0 0-35.3 43.53c-3.48 6-16.61 30.11-18.2 69.24c-1 22.21 5.67 45.22 8.85 54.73v.2c2 5.6 9.75 24.71 22.38 40.82A167.53 167.53 0 0 0 115 470.66v-.2l.2.2c39.91 27.12 84.16 25.34 84.16 25.34c7.66-.31 33.32 0 62.46-13.81c32.32-15.31 50.72-38.12 50.72-38.12a158.46 158.46 0 0 0 27.64-45.93c7.46-19.61 9.95-43.13 9.95-52.53V176.49c1 .6 14.32 9.41 14.32 9.41s19.19 12.3 49.13 20.31c21.48 5.7 50.42 6.9 50.42 6.9v-81.84c-10.14 1.1-30.73-2.1-51.81-12.61" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg"
                    class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] hidden group-hover:block"
                    viewBox="0 0 256 290">
                    <path fill="#ff004f"
                      d="M189.72 104.421c18.678 13.345 41.56 21.197 66.273 21.197v-47.53a67.115 67.115 0 0 1-13.918-1.456v37.413c-24.711 0-47.59-7.851-66.272-21.195v96.996c0 48.523-39.356 87.855-87.9 87.855c-18.113 0-34.949-5.473-48.934-14.86c15.962 16.313 38.222 26.432 62.848 26.432c48.548 0 87.905-39.332 87.905-87.857v-96.995zm17.17-47.952c-9.546-10.423-15.814-23.893-17.17-38.785v-6.113h-13.189c3.32 18.927 14.644 35.097 30.358 44.898M69.673 225.607a40.008 40.008 0 0 1-8.203-24.33c0-22.192 18.001-40.186 40.21-40.186a40.313 40.313 0 0 1 12.197 1.883v-48.593c-4.61-.631-9.262-.9-13.912-.801v37.822a40.268 40.268 0 0 0-12.203-1.882c-22.208 0-40.208 17.992-40.208 40.187c0 15.694 8.997 29.281 22.119 35.9" />
                    <path
                      d="M175.803 92.849c18.683 13.344 41.56 21.195 66.272 21.195V76.631c-13.794-2.937-26.005-10.141-35.186-20.162c-15.715-9.802-27.038-25.972-30.358-44.898h-34.643v189.843c-.079 22.132-18.049 40.052-40.21 40.052c-13.058 0-24.66-6.221-32.007-15.86c-13.12-6.618-22.118-20.206-22.118-35.898c0-22.193 18-40.187 40.208-40.187c4.255 0 8.356.662 12.203 1.882v-37.822c-47.692.985-86.047 39.933-86.047 87.834c0 23.912 9.551 45.589 25.053 61.428c13.985 9.385 30.82 14.86 48.934 14.86c48.545 0 87.9-39.335 87.9-87.857z" />
                    <path fill="#00f2ea"
                      d="M242.075 76.63V66.516a66.285 66.285 0 0 1-35.186-10.047a66.47 66.47 0 0 0 35.186 20.163M176.53 11.57a67.788 67.788 0 0 1-.728-5.457V0h-47.834v189.845c-.076 22.13-18.046 40.05-40.208 40.05a40.06 40.06 0 0 1-18.09-4.287c7.347 9.637 18.949 15.857 32.007 15.857c22.16 0 40.132-17.918 40.21-40.05V11.571zM99.966 113.58v-10.769a88.787 88.787 0 0 0-12.061-.818C39.355 101.993 0 141.327 0 189.845c0 30.419 15.467 57.227 38.971 72.996c-15.502-15.838-25.053-37.516-25.053-61.427c0-47.9 38.354-86.848 86.048-87.833" />
                  </svg>
                </a>



                <a href="https://www.youtube.com/@master-itsystems" class="flex group" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg"
                    class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] group-hover:hidden duration-300"
                    viewBox="0 0 24 24">
                    <path fill="white"
                      d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814M9.545 15.568V8.432L15.818 12z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg"
                    class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] hidden group-hover:block"
                    viewBox="0 0 256 180">
                    <path fill="#f00"
                      d="M250.346 28.075A32.18 32.18 0 0 0 227.69 5.418C207.824 0 127.87 0 127.87 0S47.912.164 28.046 5.582A32.18 32.18 0 0 0 5.39 28.24c-6.009 35.298-8.34 89.084.165 122.97a32.18 32.18 0 0 0 22.656 22.657c19.866 5.418 99.822 5.418 99.822 5.418s79.955 0 99.82-5.418a32.18 32.18 0 0 0 22.657-22.657c6.338-35.348 8.291-89.1-.164-123.134" />
                    <path fill="#fff" d="m102.421 128.06l66.328-38.418l-66.328-38.418z" />
                  </svg>
                </a>
              </div>

            </div>



          </div> -->


        </div>
      </div>
    </div>


    <!-- mobile footer -->

    <div class="p-[12px] space-y-[12px] md:hidden">
      <div class="flex justify-center">
        <div class="flex items-center space-x-[6px]">
          <img src="../assets/image/image 5.png" class="w-[60px] h-[60px]" alt="" />
          <h3 class="text-white text-[20px]">Master IT System</h3>
        </div>
      </div>
      <div>
        <p class="text-[14px] text-white">យើងជឿជាក់ថា កក្តាដ៏សំខាន់មួយជួយធ្វើឱ្យអាជីវកម្ម និងប្រទេសជាតិរីក ចម្រើនលឿន
          គឺកក្តាជឿនលឿននៃបច្ចេកវិទ្យា ម៉ាស្ទ័រអាយធីស៊ីស្ទឹម នឹងក្លាយជា
          ក្រុមហ៊ុនបច្ចេកវិទ្យាឈានមុខគេបង្កើតមោតនភាពជូនប្រជាជនកម្ពុជា។ </p>

          <div class="flex gap-[10px] mb-[10px] my-4">
              <a href="https://t.me/masteritsystems_saleconsultant" class="flex group" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" class=" w-[32px] h-[32px] group-hover:hidden duration-300"
                  viewBox="0 0 20 20">
                  <path fill="white"
                    d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10S4.477 0 10 0m4.442 6c-.381.007-.966.207-3.779 1.362a485.41 485.41 0 0 0-5.907 2.512c-.48.189-.73.373-.753.553c-.044.346.46.453 1.094.657c.517.166 1.213.36 1.575.368c.328.007.694-.127 1.098-.4c2.76-1.84 4.183-2.769 4.273-2.789c.063-.014.15-.032.21.02c.059.052.053.15.046.177c-.05.211-2.641 2.538-2.79 2.691l-.072.072c-.55.543-1.105.898-.147 1.521c.866.563 1.37.922 2.26 1.5c.57.368 1.017.805 1.605.752c.271-.025.55-.276.693-1.026c.335-1.77.995-5.608 1.147-7.19a1.742 1.742 0 0 0-.017-.393a.42.42 0 0 0-.144-.27c-.121-.098-.309-.118-.392-.117" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg"
                  class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] hidden group-hover:block"
                  viewBox="0 0 256 256">
                  <defs>
                    <linearGradient id="logosTelegram0" x1="50%" x2="50%" y1="0%" y2="100%">
                      <stop offset="0%" stop-color="#2aabee" />
                      <stop offset="100%" stop-color="#229ed9" />
                    </linearGradient>
                  </defs>
                  <path fill="url(#logosTelegram0)"
                    d="M128 0C94.06 0 61.48 13.494 37.5 37.49A128.038 128.038 0 0 0 0 128c0 33.934 13.5 66.514 37.5 90.51C61.48 242.506 94.06 256 128 256s66.52-13.494 90.5-37.49c24-23.996 37.5-56.576 37.5-90.51c0-33.934-13.5-66.514-37.5-90.51C194.52 13.494 161.94 0 128 0" />
                  <path fill="#fff"
                    d="M57.94 126.648c37.32-16.256 62.2-26.974 74.64-32.152c35.56-14.786 42.94-17.354 47.76-17.441c1.06-.017 3.42.245 4.96 1.49c1.28 1.05 1.64 2.47 1.82 3.467c.16.996.38 3.266.2 5.038c-1.92 20.24-10.26 69.356-14.5 92.026c-1.78 9.592-5.32 12.808-8.74 13.122c-7.44.684-13.08-4.912-20.28-9.63c-11.26-7.386-17.62-11.982-28.56-19.188c-12.64-8.328-4.44-12.906 2.76-20.386c1.88-1.958 34.64-31.748 35.26-34.45c.08-.338.16-1.598-.6-2.262c-.74-.666-1.84-.438-2.64-.258c-1.14.256-19.12 12.152-54 35.686c-5.1 3.508-9.72 5.218-13.88 5.128c-4.56-.098-13.36-2.584-19.9-4.708c-8-2.606-14.38-3.984-13.82-8.41c.28-2.304 3.46-4.662 9.52-7.072" />
                </svg>
              </a>
              <a href="https://www.facebook.com/system.master.it" class="flex group" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" class=" h-[32px] group-hover:hidden duration-300"
                  viewBox="0 0 512 512">
                  <path fill="white"
                    d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48c27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg"
                  class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[32px] h-[32px] hidden group-hover:block"
                  viewBox="0 0 256 256">
                  <path fill="#1877f2"
                    d="M256 128C256 57.308 198.692 0 128 0C57.308 0 0 57.308 0 128c0 63.888 46.808 116.843 108 126.445V165H75.5v-37H108V99.8c0-32.08 19.11-49.8 48.348-49.8C170.352 50 185 52.5 185 52.5V84h-16.14C152.959 84 148 93.867 148 103.99V128h35.5l-5.675 37H148v89.445c61.192-9.602 108-62.556 108-126.445" />
                  <path fill="#fff"
                    d="m177.825 165l5.675-37H148v-24.01C148 93.866 152.959 84 168.86 84H185V52.5S170.352 50 156.347 50C127.11 50 108 67.72 108 99.8V128H75.5v37H108v89.445A128.959 128.959 0 0 0 128 256a128.9 128.9 0 0 0 20-1.555V165z" />
                </svg>
              </a>


<!-- 
              <div class="flex cursor-pointer group">
                <svg xmlns="http://www.w3.org/2000/svg"
                  class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[32px] h-[32px] group-hover:hidden duration-300"
                  viewBox="0 0 32 32">
                  <path fill="white"
                    d="M0 15.521C0 6.599 6.984 0 16 0s16 6.599 16 15.521c0 8.917-6.984 15.521-16 15.521c-1.615 0-3.172-.214-4.625-.615a1.266 1.266 0 0 0-.854.068l-3.188 1.401a1.282 1.282 0 0 1-1.802-1.135l-.094-2.854a1.281 1.281 0 0 0-.422-.906A15.192 15.192 0 0 1-.001 15.522zm11.094-2.922l-4.693 7.469c-.469.703.427 1.521 1.094 1l5.052-3.828a.944.944 0 0 1 1.161 0l3.729 2.802a2.41 2.41 0 0 0 3.469-.641l4.693-7.469c.469-.703-.427-1.505-1.094-1l-5.052 3.828a.923.923 0 0 1-1.146 0l-3.734-2.802a2.398 2.398 0 0 0-3.479.641" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg"
                  class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] hidden group-hover:block"
                  viewBox="0 0 256 256">
                  <defs>
                    <radialGradient id="logosMessenger0" cx="19.247%" cy="99.465%" r="108.96%" fx="19.247%"
                      fy="99.465%">
                      <stop offset="0%" stop-color="#09f" />
                      <stop offset="60.975%" stop-color="#a033ff" />
                      <stop offset="93.482%" stop-color="#ff5280" />
                      <stop offset="100%" stop-color="#ff7061" />
                    </radialGradient>
                  </defs>
                  <path fill="url(#logosMessenger0)"
                    d="M128 0C55.894 0 0 52.818 0 124.16c0 37.317 15.293 69.562 40.2 91.835c2.09 1.871 3.352 4.493 3.438 7.298l.697 22.77c.223 7.262 7.724 11.988 14.37 9.054L84.111 243.9a10.218 10.218 0 0 1 6.837-.501c11.675 3.21 24.1 4.92 37.052 4.92c72.106 0 128-52.818 128-124.16S200.106 0 128 0" />
                  <path fill="#fff"
                    d="m51.137 160.47l37.6-59.653c5.98-9.49 18.788-11.853 27.762-5.123l29.905 22.43a7.68 7.68 0 0 0 9.252-.027l40.388-30.652c5.39-4.091 12.428 2.36 8.82 8.085l-37.6 59.654c-5.981 9.489-18.79 11.852-27.763 5.122l-29.906-22.43a7.68 7.68 0 0 0-9.25.027l-40.39 30.652c-5.39 4.09-12.427-2.36-8.818-8.085" />
                </svg>
              </div> -->

             

              <a href="https://www.tiktok.com/@master_it_system" class="flex group"
                target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" class=" w-[32px] h-[32px] group-hover:hidden duration-300"
                  viewBox="0 0 512 512">
                  <path fill="white"
                    d="M412.19 118.66a109.27 109.27 0 0 1-9.45-5.5a132.87 132.87 0 0 1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14 23.9 350 16 350.13 16h-82.44v318.78c0 4.28 0 8.51-.18 12.69c0 .52-.05 1-.08 1.56c0 .23 0 .47-.05.71v.18a70 70 0 0 1-35.22 55.56a68.8 68.8 0 0 1-34.11 9c-38.41 0-69.54-31.32-69.54-70s31.13-70 69.54-70a68.9 68.9 0 0 1 21.41 3.39l.1-83.94a153.14 153.14 0 0 0-118 34.52a161.79 161.79 0 0 0-35.3 43.53c-3.48 6-16.61 30.11-18.2 69.24c-1 22.21 5.67 45.22 8.85 54.73v.2c2 5.6 9.75 24.71 22.38 40.82A167.53 167.53 0 0 0 115 470.66v-.2l.2.2c39.91 27.12 84.16 25.34 84.16 25.34c7.66-.31 33.32 0 62.46-13.81c32.32-15.31 50.72-38.12 50.72-38.12a158.46 158.46 0 0 0 27.64-45.93c7.46-19.61 9.95-43.13 9.95-52.53V176.49c1 .6 14.32 9.41 14.32 9.41s19.19 12.3 49.13 20.31c21.48 5.7 50.42 6.9 50.42 6.9v-81.84c-10.14 1.1-30.73-2.1-51.81-12.61" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-[32px] h-[32px] hidden group-hover:block"
                  viewBox="0 0 256 290">
                  <path fill="#ff004f"
                    d="M189.72 104.421c18.678 13.345 41.56 21.197 66.273 21.197v-47.53a67.115 67.115 0 0 1-13.918-1.456v37.413c-24.711 0-47.59-7.851-66.272-21.195v96.996c0 48.523-39.356 87.855-87.9 87.855c-18.113 0-34.949-5.473-48.934-14.86c15.962 16.313 38.222 26.432 62.848 26.432c48.548 0 87.905-39.332 87.905-87.857v-96.995zm17.17-47.952c-9.546-10.423-15.814-23.893-17.17-38.785v-6.113h-13.189c3.32 18.927 14.644 35.097 30.358 44.898M69.673 225.607a40.008 40.008 0 0 1-8.203-24.33c0-22.192 18.001-40.186 40.21-40.186a40.313 40.313 0 0 1 12.197 1.883v-48.593c-4.61-.631-9.262-.9-13.912-.801v37.822a40.268 40.268 0 0 0-12.203-1.882c-22.208 0-40.208 17.992-40.208 40.187c0 15.694 8.997 29.281 22.119 35.9" />
                  <path
                    d="M175.803 92.849c18.683 13.344 41.56 21.195 66.272 21.195V76.631c-13.794-2.937-26.005-10.141-35.186-20.162c-15.715-9.802-27.038-25.972-30.358-44.898h-34.643v189.843c-.079 22.132-18.049 40.052-40.21 40.052c-13.058 0-24.66-6.221-32.007-15.86c-13.12-6.618-22.118-20.206-22.118-35.898c0-22.193 18-40.187 40.208-40.187c4.255 0 8.356.662 12.203 1.882v-37.822c-47.692.985-86.047 39.933-86.047 87.834c0 23.912 9.551 45.589 25.053 61.428c13.985 9.385 30.82 14.86 48.934 14.86c48.545 0 87.9-39.335 87.9-87.857z" />
                  <path fill="#00f2ea"
                    d="M242.075 76.63V66.516a66.285 66.285 0 0 1-35.186-10.047a66.47 66.47 0 0 0 35.186 20.163M176.53 11.57a67.788 67.788 0 0 1-.728-5.457V0h-47.834v189.845c-.076 22.13-18.046 40.05-40.208 40.05a40.06 40.06 0 0 1-18.09-4.287c7.347 9.637 18.949 15.857 32.007 15.857c22.16 0 40.132-17.918 40.21-40.05V11.571zM99.966 113.58v-10.769a88.787 88.787 0 0 0-12.061-.818C39.355 101.993 0 141.327 0 189.845c0 30.419 15.467 57.227 38.971 72.996c-15.502-15.838-25.053-37.516-25.053-61.427c0-47.9 38.354-86.848 86.048-87.833" />
                </svg>
              </a>



              <a href="https://www.youtube.com/@master-itsystems" class="flex cursor-pointer group" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-[32px] h-[32px] group-hover:hidden duration-300 cursor-pointer"
                  viewBox="0 0 24 24">
                  <path fill="white"
                    d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814M9.545 15.568V8.432L15.818 12z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" class=" w-[32] h-[32] hidden group-hover:block "
                  viewBox="0 0 256 180">
                  <path fill="#f00"
                    d="M250.346 28.075A32.18 32.18 0 0 0 227.69 5.418C207.824 0 127.87 0 127.87 0S47.912.164 28.046 5.582A32.18 32.18 0 0 0 5.39 28.24c-6.009 35.298-8.34 89.084.165 122.97a32.18 32.18 0 0 0 22.656 22.657c19.866 5.418 99.822 5.418 99.822 5.418s79.955 0 99.82-5.418a32.18 32.18 0 0 0 22.657-22.657c6.338-35.348 8.291-89.1-.164-123.134" />
                  <path fill="#fff" d="m102.421 128.06l66.328-38.418l-66.328-38.418z" />
                </svg>
              </a>
            </div>

      </div>

      <div>
        <div class="">
          <div class="">
            <div>
              <h3 class="font-bold text-white ">ទំព័រផ្សេងៗ</h3>
              <hr class="w-[50px] bg-white rounded-full h-[3px]">
            </div>
          </div>
          <div class="mt-[10px] p-2">
            <div class="flex flex-col space-y-3  md:mt-[14px]">
              <router-link to="/" class="text-[16px] text-white uppercase duration-300 hover:text-indigo-500">
                ទំព័រដើម
              </router-link>

              <router-link to="/productsandservices"
                class="text-[16px] text-white uppercase duration-300 hover:text-indigo-500">
                សេវាកម្ម
              </router-link>
              <router-link to="/viewAllproduct" class="text-[16px] text-white uppercase duration-300 hover:text-indigo-500">
                ឧបករណ៍
              </router-link>
              <router-link to="/aboutUs" class="text-[16px] text-white uppercase duration-300 hover:text-indigo-500">
                អំពីក្រុមហ៊ុន
              </router-link>
              <router-link to="/contact" class="text-[16px] text-white uppercase duration-300 hover:text-indigo-500">
                ទំនាក់ទំនង
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-[10px]">
        <div class="">
          <div>
            <h3 class="md:text-[18px] lg:text-[18px] xl:text-[24px] font-bold text-white">ទំនាក់ទំនង</h3>
            <hr class="w-[50px] h-[3px] bg-white rounded-md">
          </div>
          <div class="mt-[10px] p-2">
            <div class="flex flex-col space-y-3 md:mt-[12px] lg:mt-[14px]">
              <div
                class="lg:text-[14px] md:text-[16px] xl:text-[16px] text-white uppercase duration-300 flex items-center space-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                </svg>

                <p>master.it.systems.team@gmail.com</p>

              </div>

              <div
                class="lg:text-[14px] md:text-[16px] xl:text-[16px] text-white uppercase duration-300 flex items-center space-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="size-[24px]">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                </svg>

                <p>095 596 656 / 096 50 21 168</p>

              </div>
              <div
                class="lg:text-[14px] md:text-[16px] xl:text-[16px] text-white uppercase   duration-300 flex items-center space-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0,0,256,256">
                  <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                    stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
                    font-family="none" font-weight="none" font-size="none" text-anchor="none"
                    style="mix-blend-mode: normal">
                    <g transform="scale(5.12,5.12)">
                      <path
                        d="M46.137,6.552c-0.75,-0.636 -1.928,-0.727 -3.146,-0.238h-0.002c-1.281,0.514 -36.261,15.518 -37.685,16.131c-0.259,0.09 -2.521,0.934 -2.288,2.814c0.208,1.695 2.026,2.397 2.248,2.478l8.893,3.045c0.59,1.964 2.765,9.21 3.246,10.758c0.3,0.965 0.789,2.233 1.646,2.494c0.752,0.29 1.5,0.025 1.984,-0.355l5.437,-5.043l8.777,6.845l0.209,0.125c0.596,0.264 1.167,0.396 1.712,0.396c0.421,0 0.825,-0.079 1.211,-0.237c1.315,-0.54 1.841,-1.793 1.896,-1.935l6.556,-34.077c0.4,-1.82 -0.156,-2.746 -0.694,-3.201zM22,32l-3,8l-3,-10l23,-17z">
                      </path>
                    </g>
                  </g>
                </svg>
                <a href="https://t.me/masteritsystems_saleconsultant">
                  https://t.me/masteritsystems,
                  saleconsultant
                </a>

              </div>


              <div
                class="lg:text-[14px] md:text-[16px] xl:text-[16px] text-white uppercase  duration-300 flex items-center space-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="size-[45px]">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                </svg>

                <p>Phum Romchek IV, Sangkat Ratanak, Krong Battambang, Battambang Province</p>

              </div>
            </div>
          </div>
        </div>

      </div>

    </div>


    <div class="bg-gradient-to-l from-[#500192] to-indigo-500 text-start h-[76px] py-4 font-normal px-4">
      <div class="md:w-[95%] md:mx-auto lg:w-[90%] mx-auto xl:w-[1200px]">
        <p class="text-[#FDFBFF]/90 font-bold text-[14px] font-poppins">In Term of Use | Private Policy</p>
        <p class="text-[#FDFBFF]/90  text-[14px] font-poppins font-bold">@2012-2025 Copy right | Master-IT System</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {};
</script>

<style scoped>
p {
  line-height: 1.8;
}
</style>
