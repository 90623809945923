<template>


    <div class=" mx-auto  overflow-hidden  py-5 lg:py-6">
      
        <div class=" xl:mt-0  text-center pb-5">
                        <h1
                            class="text-center text-primery1 xl:text-3xl xl:my-0 md:text-3xl mt-5 lg:text-2xl  text-2xl  font-NotoSansKhmer">
                            ដៃគូរបស់យើង
                        </h1>
                        <div class="border-b-[4px] border-primery1 w-16 lg:w-16 xl:w-20 mx-auto mt-2"></div>

                    </div>
        <div class="relative flex  w-screen items-center">
            <div class="relative flex overflow-hidden py-5">
                <div class="flex animate-marquee">

                    <div v-for="item in items" :key="item.name" class="h-full ">
                        <div
                        
                            class="relative h-full w-[10rem] md:w-[13rem] lg:w-[15rem] border-r-[1px] border-indigo-600 bg-gradient-to-b from-[#500192]/90 to-indigo-500/90 ">
                            <div class="px-4 py-10 flex items-center  justify-center">
                                <img :src="item.image"
                                    class="h-[100px] w-[100px] md:w-[140px] md:h-[140px] lg:h-[130px] lg:w-[130px] xl:h-[150px] xl:w-[150px] object-contain" />

                                    <!-- <img src="https://fakeimg.pl/150x150/"
                                    class="h-[100px] w-[100px] md:w-[140px] md:h-[140px] lg:h-[130px] lg:w-[130px] xl:h-[150px] xl:w-[150px] object-contain" /> -->

                            </div>
                        </div>
                    </div>

                    <!-- Duplicate set of items to create continuous scrolling effect -->
                    <div v-for="item in items" :key="'duplicate-' + item.name" class="h-full">
                        <div
                            class="relative h-full w-[10rem] md:w-[13rem] lg:w-[15rem]   border-r-[1px] border-indigo-600 bg-gradient-to-b from-[#500192]/90 to-indigo-500/90 ">
                            <div class="px-4 py-10  flex items-center gap-4 justify-center">
                                <img :src="item.image"
                                    class="h-[100px] w-[100px] md:w-[140px] md:h-[140px] lg:h-[130px] lg:w-[130px] xl:h-[150px] xl:w-[150px] object-contain " />
                                    <!-- <img src="https://fakeimg.pl/150x150/"
                                    class="h-[100px] w-[100px] md:w-[140px] md:h-[140px] lg:h-[130px] lg:w-[130px] xl:h-[150px] xl:w-[150px] object-contain" /> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import getCollection from '@/composible/getCollections';

export default {
    setup() {
        const { documents: items } = getCollection('partner');

        return { items };
    }
};
</script>

<style scoped>
@keyframes marquee {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

.animate-marquee {
    display: flex;
    animation: marquee 30s linear infinite;
}

.hover\:animation-paused:hover {
    animation-play-state: paused;
}
</style>
