<template>


    <div v-motion :initial="{
        opacity: 0,
    }" :enter="{

        opacity: 1,
        transition: {
            duration: 500,

            ease: 'easeIn',
        },
    }" class="flex flex-col px-2 bg-background md:px-0">
        <div class="w-full md:w-[90%] xl:w-[1280px] lg:w-[90%] mx-auto space-y-4 relative">


            <div
                class=" w-full xl:w-[1280px] xl:px-4 md:py-0 lg:p-0 xl:p-0  mx-auto border-b-gray-400 lg:h-auto xl:auto md:h-auto h-auto lg:flex lg:flex-row xl:flex xl:flex-row md:flex md:flex-col-reverse flex flex-col-reverse items-center justify-between">
                <div class="flex md:w-[] lg:w-[60%] ">
                    <div v-for="cat in categories" :key="cat"
                        class="text-[14px]  md:mx-0 text-textbody md:text-[18px] lg:text-[14px] xl:text-[18px] font-[500]">

                        <div v-if="cat.id === $route.params.id">

                            <div class="px-4 mt-4 md:px-0 lg:mt-8 xl:mt-8 md:mt-3 text-start lg:p-0 xl:p-0 xl:px-0">
                                <h2
                                    class="mt-5 mb-4 text-2xl font-bold text-center font-NotoSansKhmer md:mt-8 md:text-3xl md:text-start lg:text-2xl xl:text-3xl text-primery1 lg:text-start xl:text-start xl:ml-0">
                                    {{ cat.name }}
                                </h2>

                                <span
                                    class=" text-justify text-black/70 font-NotoSansKhmer prose-li:text-wrap sm:prose-li:text-wrap text-[16px]   md:mx-0  md:text-[18px] lg:text-[16px] xl:text-[18px] font-[500]"
                                    v-html="cat.description"></span>
                            </div>
                        </div>

                    </div>

                </div>

            </div>


            <div class="grid grid-cols-1 gap-5 px-4 md:px-0 sm:grid-cols-2 md:grid-cols-3 lg:gap-10 xl:px-4">
                <div v-for="item in filteredBusiness" :key="item.id">

                    <div
                        class="bg-white  hover:-translate-y-4 transition-all duration-300 ease-in-out border-[1px] h-full   md:h-full   xl:h-full flex flex-col justify-between">

                        <div class="gap-5 mt-2 xl:flex xl:space-y-0 lg:space-y-4 xl:mt-0 ">

                            <div>
                                <div class="flex justify-center -mt-2 xl:mt-0">
                                    <img :src="item.image"
                                        class="w-[400px] h-[300px]   md:w-[420px] md:h-[300px] lg:w-[350px] lg:h-[250px] xl:w-[400px] xl:h-[300px]"
                                        alt="">
                                   
                                </div>
                                <div class="p-4 space-y-2">
                                    <h4
                                        class="text-[18px] mt-3 xl:mt-0 font-poppins text-black/70 font-bold md:text-[16px] xl:text-[18px] lg:text-[18px] line-clamp-2">
                                        {{ item.name }}</h4>
                                    <div v-html="item.descritpts"
                                        class="text-[15px] mt-2 text-justify  md:text-[15px] line-clamp-4  xl:text-[16px] sm:line-clamp-4 text-[#909090] xl:mt-3 md:line-clamp-3 lg:line-clamp-4 xl:line-clamp-3 lg:text-[16px] ">
                                    </div>

                                    <div class="flex justify-end mt-3 xl:justify-end">


                                        <router-link :to="{ name: 'systemdetails', params: { id: item.id } }"
                                            class="text-primery1/90 text-[17px] hover:text-indigo-600  md:mt-[5px] flex items-center gap-1 rounded-md  md:text-[14px] lg:text-[16px] xl:text-[18px] font-NotoSansKhmer font-bold grow_skew_forward relative z-10  text-2xl py-1 lg:py-1.5 px-8 mt-1   hover:border-blue-500">
                                            <p>មើលបន្ថែម</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke-width="1.5" stroke="currentColor" class="size-6">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                                            </svg>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="w-full h-[48px]"></div>
    </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { collection, documentId, where } from "@firebase/firestore";
import getCollection from "@/composible/getCollections.js";
import { useRoute } from "vue-router";
import { projectFirestore } from "@/firebase/config";
import { computed } from "vue";

export default {
    setup() {
        const bussiness = ref(null);
        const route = useRoute();
        const { documents: categories } = getCollection('categories')
        const fetchBusiness = async () => {
            const { documents } = getCollection(
                "products",
                where("cid", "==", route.params.id)
            );

            watch(documents, () => {
                bussiness.value = documents.value;
            });
        };

        // Fetch business data when the component is mounted

        const filteredBusiness = computed(() => {
            return bussiness.value?.filter(
                b => b.name !== 'ប្រព័ន្ធគ្រប់គ្រងបន្ទប់ជួល និងតូបជួល'
            ) || [];
        });

        onMounted(() => {
            fetchBusiness();
        });

        return { bussiness, categories,filteredBusiness };
    },
};
</script>