<template>
    <div class="border-b-[1px] py-5 ">
        <div class="duration-300 bg-transparent lg:w-[98%] xl:w-[1200px]  mx-auto  px-4 ">

            <div class="flex justify-between  lg:grid lg:grid-cols-3 gap-3 flex-wrap ">
 
                <div class="hidden md:flex gap-2 md:gap-4 lg:gap-1  xl:gap-4 items-center  flex-wrap">
                    <div class="flex items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                        </svg>
                        <p class="text-sm font-[600] font-poppins text-gray-600">095 596 656 </p>
                    </div>
                    <div class="flex items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                        </svg>

                        <p class="text-sm font-[600] font-poppins text-gray-600">master.it.systems.team@gmail.com</p>
                    </div>
                </div>

                <div class="lg:flex items-center gap-2 hidden ">
                    <router-link to="/" class="cursor-pointer flex h-full w-full gap-2 items-center justify-center ">
                        <img class="lg:w-[68px] lg:h-[50px] xl:w-[50px] md:w-[55px] md:h-[55px] w-[40px] lg:block object-contain"
                            src="../assets/image/image 5.png" />
                        <h1 class=" font-poppins  md:text-[20px] text-primery1 lg:text-nowrap lg:text-[18px]">
                            Master-IT System
                        </h1>
                    </router-link>
                </div>

                <div class="flex items-center justify-end gap-[13px]">
                    <a href="https://t.me/masteritsystems_saleconsultant" class="group flex " target="_blank">

                        <svg xmlns="http://www.w3.org/2000/svg"
                            class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px]"
                            viewBox="0 0 256 256">
                            <defs>
                                <linearGradient id="logosTelegram0" x1="50%" x2="50%" y1="0%" y2="100%">
                                    <stop offset="0%" stop-color="#2aabee" />
                                    <stop offset="100%" stop-color="#229ed9" />
                                </linearGradient>
                            </defs>
                            <path fill="url(#logosTelegram0)"
                                d="M128 0C94.06 0 61.48 13.494 37.5 37.49A128.038 128.038 0 0 0 0 128c0 33.934 13.5 66.514 37.5 90.51C61.48 242.506 94.06 256 128 256s66.52-13.494 90.5-37.49c24-23.996 37.5-56.576 37.5-90.51c0-33.934-13.5-66.514-37.5-90.51C194.52 13.494 161.94 0 128 0" />
                            <path fill="#fff"
                                d="M57.94 126.648c37.32-16.256 62.2-26.974 74.64-32.152c35.56-14.786 42.94-17.354 47.76-17.441c1.06-.017 3.42.245 4.96 1.49c1.28 1.05 1.64 2.47 1.82 3.467c.16.996.38 3.266.2 5.038c-1.92 20.24-10.26 69.356-14.5 92.026c-1.78 9.592-5.32 12.808-8.74 13.122c-7.44.684-13.08-4.912-20.28-9.63c-11.26-7.386-17.62-11.982-28.56-19.188c-12.64-8.328-4.44-12.906 2.76-20.386c1.88-1.958 34.64-31.748 35.26-34.45c.08-.338.16-1.598-.6-2.262c-.74-.666-1.84-.438-2.64-.258c-1.14.256-19.12 12.152-54 35.686c-5.1 3.508-9.72 5.218-13.88 5.128c-4.56-.098-13.36-2.584-19.9-4.708c-8-2.606-14.38-3.984-13.82-8.41c.28-2.304 3.46-4.662 9.52-7.072" />
                        </svg>
                    </a>
                    <a href="https://www.facebook.com/system.master.it" class="group flex"
                        target="_blank">

                        <svg xmlns="http://www.w3.org/2000/svg"
                            class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] "
                            viewBox="0 0 256 256">
                            <path fill="#1877f2"
                                d="M256 128C256 57.308 198.692 0 128 0C57.308 0 0 57.308 0 128c0 63.888 46.808 116.843 108 126.445V165H75.5v-37H108V99.8c0-32.08 19.11-49.8 48.348-49.8C170.352 50 185 52.5 185 52.5V84h-16.14C152.959 84 148 93.867 148 103.99V128h35.5l-5.675 37H148v89.445c61.192-9.602 108-62.556 108-126.445" />
                            <path fill="#fff"
                                d="m177.825 165l5.675-37H148v-24.01C148 93.866 152.959 84 168.86 84H185V52.5S170.352 50 156.347 50C127.11 50 108 67.72 108 99.8V128H75.5v37H108v89.445A128.959 128.959 0 0 0 128 256a128.9 128.9 0 0 0 20-1.555V165z" />
                        </svg>
                    </a>

                   
                    <a href="https://www.tiktok.com/@master_it_system" class="group flex"
                        target="_blank">

                        <svg xmlns="http://www.w3.org/2000/svg"
                            class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] "
                            viewBox="0 0 256 290">
                            <path fill="#ff004f"
                                d="M189.72 104.421c18.678 13.345 41.56 21.197 66.273 21.197v-47.53a67.115 67.115 0 0 1-13.918-1.456v37.413c-24.711 0-47.59-7.851-66.272-21.195v96.996c0 48.523-39.356 87.855-87.9 87.855c-18.113 0-34.949-5.473-48.934-14.86c15.962 16.313 38.222 26.432 62.848 26.432c48.548 0 87.905-39.332 87.905-87.857v-96.995zm17.17-47.952c-9.546-10.423-15.814-23.893-17.17-38.785v-6.113h-13.189c3.32 18.927 14.644 35.097 30.358 44.898M69.673 225.607a40.008 40.008 0 0 1-8.203-24.33c0-22.192 18.001-40.186 40.21-40.186a40.313 40.313 0 0 1 12.197 1.883v-48.593c-4.61-.631-9.262-.9-13.912-.801v37.822a40.268 40.268 0 0 0-12.203-1.882c-22.208 0-40.208 17.992-40.208 40.187c0 15.694 8.997 29.281 22.119 35.9" />
                            <path
                                d="M175.803 92.849c18.683 13.344 41.56 21.195 66.272 21.195V76.631c-13.794-2.937-26.005-10.141-35.186-20.162c-15.715-9.802-27.038-25.972-30.358-44.898h-34.643v189.843c-.079 22.132-18.049 40.052-40.21 40.052c-13.058 0-24.66-6.221-32.007-15.86c-13.12-6.618-22.118-20.206-22.118-35.898c0-22.193 18-40.187 40.208-40.187c4.255 0 8.356.662 12.203 1.882v-37.822c-47.692.985-86.047 39.933-86.047 87.834c0 23.912 9.551 45.589 25.053 61.428c13.985 9.385 30.82 14.86 48.934 14.86c48.545 0 87.9-39.335 87.9-87.857z" />
                            <path fill="#00f2ea"
                                d="M242.075 76.63V66.516a66.285 66.285 0 0 1-35.186-10.047a66.47 66.47 0 0 0 35.186 20.163M176.53 11.57a67.788 67.788 0 0 1-.728-5.457V0h-47.834v189.845c-.076 22.13-18.046 40.05-40.208 40.05a40.06 40.06 0 0 1-18.09-4.287c7.347 9.637 18.949 15.857 32.007 15.857c22.16 0 40.132-17.918 40.21-40.05V11.571zM99.966 113.58v-10.769a88.787 88.787 0 0 0-12.061-.818C39.355 101.993 0 141.327 0 189.845c0 30.419 15.467 57.227 38.971 72.996c-15.502-15.838-25.053-37.516-25.053-61.427c0-47.9 38.354-86.848 86.048-87.833" />
                        </svg>
                    </a>


                    <a href="https://www.youtube.com/@master-itsystems" class="group flex" target="_blank">

                        <svg xmlns="http://www.w3.org/2000/svg"
                            class="lg:w-[32px] lg:h-[32px] xl:w-[28px] xl:h-[28px] md:w-[28px] md:h-[28px] w-[28px] h-[28px] "
                            viewBox="0 0 256 180">
                            <path fill="#f00"
                                d="M250.346 28.075A32.18 32.18 0 0 0 227.69 5.418C207.824 0 127.87 0 127.87 0S47.912.164 28.046 5.582A32.18 32.18 0 0 0 5.39 28.24c-6.009 35.298-8.34 89.084.165 122.97a32.18 32.18 0 0 0 22.656 22.657c19.866 5.418 99.822 5.418 99.822 5.418s79.955 0 99.82-5.418a32.18 32.18 0 0 0 22.657-22.657c6.338-35.348 8.291-89.1-.164-123.134" />
                            <path fill="#fff" d="m102.421 128.06l66.328-38.418l-66.328-38.418z" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>