<template>
    <div class="bg-[#fcfcfc]" 
    v-motion :initial="{
        opacity: 0,
    }" :enter="{

        opacity: 1,
        transition: {
            duration: 500,

            ease: 'easeIn',
        },
    }"
    
    >
        <div class="md:w-[95%] lg:w-[90%] xl:w-[1280px] mx-auto pb-[16px] lg:pb-[60px] xl:px-4">
            <div class="px-5 w-full lg:w-[60%] xl:w-[50%] xl:px-0 lg:mx-auto ">
                <h1
                    class="text-[24px] md:text-[35px] xl:text-[35px] font-KhmerMoul font-[400] lg:text-[30px] py-[16px] xl:pt-[25px] text-center text-primery1 pt-5">
                    ឧបករណ៍</h1>
                <p class="">
                    ឈុតឧបករណ៍ ប្រើក្នុងឈុតប្រព័ន្ធគ្រប់គ្រងអាជីវកម្ម POS Hardware បែបទំនើបស្តងដារ​ ទាន់សម័យ
                    ងាយស្រួលប្រើប្រាស់ ទៅតាមតម្រូវការដែលអតិថិជនចង់បាន
                </p>
            </div>


            <div
                class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-[16px] md:gap-[30px]  lg:gap-[30px] xl:gap-[40px] p-5 xl:p-0 md:mt-3 lg:mt-10">
                <div v-for="pro in product" :key="pro">
                    <div class="relative bg-white h-full rounded-md cursor-pointer overflow-hidden hover:scale-105 duration-300 transition-all"
                        style="box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;">
                        <div
                            class="absolute inset-0 border-2 border-transparent rounded-md hover:border-indigo-500 transition-all duration-500 animate-border-animation">
                        </div>
                        <div class="p-[16px] gap-6">
                            <div class="flex justify-center">
                                <img :src="pro.url"
                                    class="h-[100px] w-[100px] md:w-[140px] md:h-[140px] lg:w-[120px] lg:h-[120px] xl:w-[180px] xl:h-[180px] object-contain"
                                    alt="">
                            </div>

                            <div>
                                <h4
                                    class="font-bold font-poppins md:text-[18px] xl:text-[18px] lg:text-[15px] text-[16px] line-clamp-2">
                                    {{ pro.productName }}
                                </h4>
                                <!-- <div v-html="pro.productDesscript"
                                        class="xl:text-[16px] font-poppins prose mt-[12px] md:text-[16px] xl:mt-[16px] lg:text-[#909090] lg:text-[12px] line-clamp-2 text-[14px]">
                                    </div> -->

                                <div v-html="pro.productDesscript"
                                    class="xl:text-[16px] font-poppins prose mt-[12px] md:text-[16px] xl:mt-[16px] lg:text-[#909090]  lg:text-[12px]  text-[14px]">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- {{ product }} -->
</template>

<script>
import getDocument from '@/composible/getDocument';

import { useRoute } from 'vue-router';
export default {

    setup() {

        const route = useRoute()
        // const { documents: product } = getCollection("newProduct")
        const { documents: product } = getDocument('hardwareCategories', route.params.id, 'hardware')
        console.log(product)




        return { product }
    }
}
</script>

<style></style>