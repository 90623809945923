<template>
  <div class="flex flex-col items-center justify-center w-full h-screen fixed top-0 right-0 bg-black/10  z-[20]">
    <div v-motion-slide-top
      class="xl:w-[50%] lg:w-[50%] w-[90%]  bg-white  p-5  -mt-[28rem] md:-mt-[28rem] lg:-mt-[28rem] xl:-mt-[28rem] ">
      <div class="flex items-center justify-between w-full mx-auto mb-4">
        <h1 class="text-primery1 text-heading3">
          {{ datatoedit ? "កែប្រែប្រភេទ" : "បន្ថែមថ្មី" }}
        </h1>
        <button @click="handleClose"
          class="flex items-center justify-center w-10 h-10 rounded-full focus:border-primery1 focus:border">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-8 h-8 text-primery1">
            <path
              d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
          </svg>
        </button>
      </div>
      <form @submit.prevent="handleSubmit" class="items-center justify-center w-full space-y-2">
        <div class="grid w-full grid-cols-2 gap-2">
          <div class="w-full space-y-2">
            <label class="text-body text-textbody"> ឈ្មោះ:* </label>

            <input required v-model="username" type="text" class="p-3 w-full border-[1px] hover:border-blue-500"
              placeholder="ឈ្មោះ" />
          </div>
          <div class="w-full space-y-2">
            <label class="text-body text-textbody"> អ៊ីម៉ែល:* </label>

            <input required v-model="email" type="text" class="p-3 w-full border-[1px] hover:border-blue-500"
              placeholder="អ៊ីម៉ែល" />
          </div>
          <div class="w-full space-y-2">
            <label class="text-body text-textbody"> ពាក្យសម្ងាត់:* </label>

            <input required v-model="password" type="text" class="p-3 w-full border-[1px] hover:border-blue-500"
              placeholder="ពាក្យសម្ងាត់" />
            <span v-if="formSubmitted && password.length < 6" class="text-red-500">ពាក្យសម្ងាត់ត្រូវតែមានយ៉ាងហោចណាស់ 6
              តួអក្សរ
            </span>
          </div>
          <div class="w-full space-y-2">
            <label class="text-body text-textbody"> តួនាទី:* </label>

            <div class="">
              <select required class="p-3 w-full border-[1px] hover:border-blue-500" v-model="role">
                <option value=""  disabled>ជ្រើសរើសតួនាទី</option>
                <option required v-for="roles in roleOptions" :value="roles" :key="roles">
                  {{ roles }}
                </option>
              </select>
            </div>
          </div>
          <div class="w-full space-y-2">
            <label class="text-body text-textbody"> ស្ថានភាព </label>

            <div class="">
              <select class="p-3 w-full border-[1px] hover:border-blue-500" v-model="status">
                <option disabled>ជ្រើសរើសរើសស្ថានភាព</option>
                <option required v-for="active in statusOptions" :value="active" :key="active">
                  {{ active }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div v-if="loading == false" class="flex justify-end w-auto mx-auto">
          <button class="p-3 px-6 text-white transition-all duration-300 ease-in-out bg-primery1 hover:bg-blue-600">
            {{ datatoedit ? "កែប្រែ" : "បន្ថែមថ្មី" }}
          </button>
        </div>
        <div v-else class="flex justify-end w-auto mx-auto">
          <button class="flex items-center justify-center p-3 text-white bg-blue-500">
            <svg aria-hidden="true" role="status" class="inline w-4 h-4 text-white me-3 animate-spin"
              viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB" />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor" />
            </svg>
            កំពុងបញ្ចូល
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import useCollection from "@/composible/useCollection";
import { ref, onMounted, computed, isShallow } from "vue";
import { v4 as uuidv4 } from "uuid";
import { httpsCallable } from "firebase/functions";
import { timestamp, functions } from "@/firebase/config";
export default {
  emit: ["AddSusccesfully", "UpddateSuccess"],
  props: ["datatoedit"],
  setup(props, { emit }) {

    const { updateDocs, setDocs } = useCollection("users");
    const username = ref("");
    const email = ref("");
    const password = ref("");
    const id = ref("");
    const roleOptions = ["admin", "SuperAdmin", "Sale"];
    const statusOptions = ["active", "inactive"];
    const role = ref("");
    const status = ref("");
    const loading = ref(false);
    const formSubmitted = ref(false);

    onMounted(() => {
      if (props.datatoedit) {
        id.value = props.datatoedit.id;
        username.value = props.datatoedit.username;
        email.value = props.datatoedit.email;
        password.value = props.datatoedit.password;
        status.value = props.datatoedit.status;
        role.value = props.datatoedit.role;
      }
    });

    const isPasswordValid = computed(() => {
      return password.value.length >= 6;
    });

    const handleSubmit = async () => {
      formSubmitted.value = true;

      if (password.value.length < 6) {
        console.error("Password must be at least 6 characters long");
        return;
      }
      if (password.value.length >= 6) {
        loading.value = true;
      }
      try {
        if (props.datatoedit) {
          const userData = {
            username: username.value,
            email: email.value,
            password: password.value,
            role: role.value,
            status: status.value,
            createdAt: timestamp(),
          };

          console.log("User created successfully");
          const updateUserFunction = httpsCallable(functions, "updateUserV2");
          const updatePromise = updateUserFunction({
            email: email.value,
            password: password.value,
            id,
          });

          const updateDocsPromise = updateDocs(props.datatoedit.id, userData);
          const [updateResponse, updateDocResponse] = await Promise.all([
            updatePromise,
            updateDocsPromise,
          ]);
          if (updateResponse.data.success) {
            console.log("User updated successfully");
            emit("UpddateSuccess");
          } else {
            console.error("Error updating user");
          }
        } else {
          const uid = uuidv4();
          const userData = {
            username: username.value,
            email: email.value,
            password: password.value,
            role: role.value,
            status: status.value,
            createdAt: timestamp(),
          };
          const createUserFunction = httpsCallable(functions, "createUserV2");
          const signupPromise = createUserFunction({
            email: email.value,
            password: password.value,
            uid,
          });

          const setDocsPromise = setDocs(uid, userData);
          const [signupResponse, setDocsResponse] = await Promise.all([
            signupPromise,
            setDocsPromise,
          ]);
          if (signupResponse.data.success) {
            console.log("User created successfully");
          }
          emit("AddSusccesfully");
        }
      } catch (error) {
        console.error("Error performing user operation:", error);
      }
      handleClear();
      handleClose();
    };

    const handleClear = () => {
      username.value = "";
      email.value = "";
      role.value = "";
      status.value = "";
      password.value = "";
    };

    const handleClose = () => {
      emit("close");
    };

    return {
      handleClose,
      handleSubmit,
      loading,
      username,
      email,
      password,
      role,
      status,
      roleOptions,
      statusOptions,
      formSubmitted,
      isPasswordValid,
    };
  },
};
</script>
