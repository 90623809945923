import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import AboutUsVue from '@/views/AboutUs.vue'
import ContactUsVue from '@/views/ContactUs.vue'

import ProductAndServiceVue from '@/views/ProductAndService.vue'
import SystemDevelopmentVue from '@/views/SystemDevelopment.vue'

import WebsiteDevelopmentVue from '@/views/WebsiteDevelopment.vue'
import BussinessManangerDevelopmentVue from '@/views/BussinessManangerDevelopment.vue'

import SystemDetails from '@/views/SystemDetails.vue'


import MainPage from '@/views/MainPage.vue'
import TestSlider from '@/views/TestSlider.vue'
import TestSliderAuto from '@/views/TestAutoSlide.vue'
import TestKenSlide from '@/views/TestKenSlide.vue'
import Product from '@/views/Product.vue'
import WebDesign from '@/views/WebDesign.vue'
import BussinessDetail from '@/views/BussinessDetail.vue'
import ViewAllHardware from '@/views/ViewAllHardware.vue'

import ProductServiceDetails from '@/views/ProductServiceDetails.vue'
import VisionMissionAndValues from '@/views/VisionMissionAndValues.vue'
import ActionTeamwork from '@/views/ActionTeamwork.vue'

import RequestQuote from '@/views/RequestQuoteView.vue'

const routes = [

  {
    path: '/home',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: '/',
        name: 'mainpage',
        component: MainPage
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: AboutUsVue
      },
      {
        path: '/contact',
        name: 'contact',
        component: ContactUsVue
      },
      {
        path: '/productsandservices',
        name: "productsandservices",
        component: ProductAndServiceVue,

      },
      {
        path: '/SystemDevelopment',
        name: 'systemdev',
        component: SystemDevelopmentVue
      },
      {
        path: '/WebsiteDesign',
        name: 'WebsiteDesign',
        component: WebsiteDevelopmentVue,
      },
      {
        path: '/BusinessManagementSystem',
        name: 'bms',
        component: BussinessManangerDevelopmentVue
      },
      {
        path: '/systemdev/:id',
        name: 'systemdetails',
        component: SystemDetails,
        props: true
      },
      {
        path: "/product/:id",
        name: "product",
        component: Product
      },

      {
        path: '/webdesign',
        name: "webdesign",
        component: WebDesign
      },
      {
        path: '/bussinessDetail/:id',
        name: "bussinessDetail",
        component: BussinessDetail
      },

      {
        path: '/viewAllProduct',
        name: "viewAllProduct",
        component: ViewAllHardware
      },

      {
        path: '/productServices/:id',
        name: 'productServices',
        component: ProductServiceDetails
      },

      {
        path: "/visionMissionValue",
        name: "visionMissionValue",
        component: VisionMissionAndValues
      },

      {
        path: '/actionTeamwork',
        name: 'actionTeamwork',
        component: ActionTeamwork
      },

      {
        path: '/request-quote',
        name: 'request-quote',
        component: RequestQuote
      },

      //test
      {
        path: "/TestSlider",
        name: "TestSlider",
        component: TestSlider
      },
      {
        path: "/TestSliderAuto",
        name: "TestSliderAuto",
        component: TestSliderAuto
      },
      {
        path: "/TestKenSlide",
        name: "TestKenSlide",
        component: TestKenSlide
      }


    ]
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // linkExactActiveClass: 'border-b-[5px] border-primary  py-1',

  // scrollBehavior(to, from, savedPosition) {
  //   // Check if the route has a saved position
  //   if (savedPosition) {
  //     return savedPosition; // Return the saved position
  //   } else {
  //     // If no saved position, scroll to the top with smooth animation
  //     return { top: 0, behavior: 'smooth' };
  //   }
  // }

  scrollBehavior(to) {
    if (to.hash) {
      return {
      
        el: to.hash,
        behavior: 'smooth', // Optional for smooth scrolling
      }
    }
    else{
      return {
        top: 0
      }
    }
  },
});


export default router


