<template>
  <div class="mt-3 ">
    <div v-for="category in productDetails" :key="category.id" class="relative select-none">
      <swiper ref="mySwiper" :navigation="{
        nextEl: '.button-next-slide',
        prevEl: '.button-pre-slide'
      }" :loop="false" :modules="modules" :allowTouchMove="false" :slides-per-group="3" :speed="1000" :breakpoints="{
        '540': { slidesPerView: 2, spaceBetween: 15 },
        '768': { slidesPerView: 3, spaceBetween: 15 },
        '1024': { slidesPerView: 4, spaceBetween: 15 }
      }" class="mySwiper relative xl:w-[1200px]" @reachEnd="showNextButton = true"
        @reachBeginning="showPrevButton = false" @fromEdge="onSlideChange">
        <swiper-slide v-for="detail in category.product" :key="detail.id">
          <div class="border-[1px]">
            <img :src="detail.images" alt="" class="object-contain w-full h-40" />
          </div>
          <div class="mt-2 space-y-1">

            <p class="text-gray-500 text-[12px] line-clamp-1">{{ detail.id }}</p>

          </div>
          <div class="mt-1">
            <h1 class="font-bold text-md">${{ detail.description }}</h1>
          </div>
        </swiper-slide>
      </swiper>

      
      <!-- Navigation buttons -->

    </div>
    <div>
      <div :class="showPrevButton ? 'block' : 'hidden'"
        class="button-pre-slide cursor-pointer rounded-full text-black absolute top-[20%] -left-5 z-[2]">
        <div
          class="flex justify-center w-10 h-10 border-gray-500 border-[1px] items-center bg-background rounded-full hover:bg-background/90"
          @click="prevSlide">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="size-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
          </svg>
        </div>
      </div>

      <div :class="showNextButton ? 'block' : 'hidden'"
        class="button-next-slide cursor-pointer rounded-full text-black absolute top-[20%] -right-5 z-[2]">
        <div
          class="flex justify-center w-10 h-10 border-gray-500 border-[1px] items-center bg-background rounded-full hover:bg-background/90"
          @click="nextSlide">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="font-bold size-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { useFirestoreCollection, useSubcollection } from '@/composible/getSubcollection';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const showNextButton = ref(true);
    const showPrevButton = ref(false);
    const swiperRef = ref(null);
    const { documents: productsdetails, fetchCollection } = useFirestoreCollection("products");

    const productDetails = ref([]);


    onMounted(async () => {
      await fetchCollection();
      await fetchProductDetailSlide();

    });

    const fetchProductDetailSlide = async () => {
      const orderByField = 'createdAt';

      try {
        const detailsPromises = productsdetails.value.map(async (pro) => {
          const { subcollectionData: product, fetchSubcollection } = useSubcollection('products', pro.id, 'productSlider', orderByField);

          await fetchSubcollection();

          return {
            id: pro.id,
            name: pro.name,
            descritpts: pro.descritpts,
            image: pro.image,
            videoLink: pro.videoLink,
            product: product.value,
          };
        });

        const result = await Promise.all(detailsPromises);
        productDetails.value = result;
      } catch (err) {
        console.error('Error fetching categories and products:', err);
      }
    };


    //below is button slide changes

    const onSlideChange = (swiper) => {
      showPrevButton.value = !swiper.isBeginning;
      showNextButton.value = !swiper.isEnd;
    };

    const prevSlide = () => {
      swiperRef.value?.slidePrev(); // Use swiperRef here
    };

    const nextSlide = () => {
      swiperRef.value?.slideNext(); // Use swiperRef here
    };

    return {
      swiperRef,
      productDetails,
      showNextButton,
      showPrevButton,
      prevSlide,
      nextSlide,
      onSlideChange,
      modules: [Navigation],
    };
  },
};
</script>


<style scoped>
/* Add necessary styles if needed */
</style>
