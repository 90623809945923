<template>
  <div>



    <div>
      <BannerComponent />
    </div>

    <div>
      <ProductAndService />
    </div>


    <div>
      <BussinesServiceComponentsVue />
    </div>

    <div>
      <WhyChooseMasterComponent />
    </div>






    <!-- FAQS -->
    <div class="w-full md:hidden mt-8 hidden lg:hidden xl:hidden  ">
      <h2
        class="lg:text-heading2 md:text-start font-semibold xl:text-heading2 md:text-heading2 text-heading4 font-NotoSansKhmer lg:mt-0 xl:mt-0 md:mt-2 mt-0 lg:ml-0 xl:ml-0 md:ml-4 ml-4">
        សំណួរ ចម្លើយ
      </h2>


    </div>




    <div>
      <PartnerComponentsVue />
    </div>


    <div>

      <ContactUsComponent />
    </div>




    <div class="mt-2">
      <QuestionAndAnswerComponentsVue />
    </div>




    <div>
      <!-- <SocialMediaVue /> -->
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { getCollectionQuery } from "@/composible/getCollection";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { useRouter } from "vue-router";
import SocialMediaVue from './SocialMedia.vue';
import BussinesServiceComponentsVue from '@/components/BussinesServiceComponents.vue'
import HardwareComponentsVue from '@/components/HardwareComponents.vue';
import PartnerComponentsVue from '@/components/PartnerComponents.vue'
import QuestionAndAnswerComponentsVue from '@/components/QuestionAndAnswerComponents.vue'
import BannerComponent from "@/components/BannerComponent.vue";
import WhyChooseMasterComponent from "@/components/WhyChooseMasterComponent.vue";
import ProductAndService from "./ProductAndService.vue";
import ContactUsComponent from "@/components/ContactUsComponent.vue";
import Navbar from "./Navbar.vue";

export default {
  components: {
    Accordion,
    AccordionTab,
    SocialMediaVue,
    BussinesServiceComponentsVue,
    WhyChooseMasterComponent,
    HardwareComponentsVue,
    PartnerComponentsVue,
    QuestionAndAnswerComponentsVue,
    BannerComponent,
    ProductAndService,
    ContactUsComponent,
    Navbar
  },
  setup() {
    const router = useRouter();
    const dataitem = ref([]);
    const products = ref([]);

    const isDropdown = ref(false)


    const getData = async () => {
      try {
        await getCollectionQuery(
          "FAQ",
          [],
          (data) => {
            dataitem.value = data;
          },
          true
        );
      } catch (error) {
        console.error(error.message);
      }
    };
    onMounted(() => {
      getData();
      getDataProduct();
      getDataPartner();
    });

    const getDataProduct = async () => {
      try {
        await getCollectionQuery(
          "products",
          [],
          (data) => {
            // Filter products where productdisplay is outstanding and truncate the descriptions
            products.value = data
              .filter((item) => item.productdisplay === "outstanding")
              .map((item) => ({
                ...item,
                description: item.description
                  ? truncateDescription(item.description)
                  : "",
              }));
          },
          true
        );
      } catch (error) {
        console.error(error.message);
      }
    };
    let sortedbyASC = ref(true);

    const sortList = (sortBy) => {
      if (sortedbyASC.value) {
        products.value.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
        sortedbyASC.value = false;
      } else {
        products.value.sort((x, y) => (x[sortBy] < y[sortBy] ? -1 : 1));
        sortedbyASC.value = true;
      }
    };

    const itemsPerPage = ref(3);
    const currentPage = ref(1);

    const totalPages = computed(() => {
      const totalItems = filteredItems.value.length;
      const itemsPerPageValue = itemsPerPage.value; // Use a different name for the constant variable
      return Math.ceil(totalItems / itemsPerPageValue);
    });

    const currentPageItems = computed(() => {
      const startIndex = (currentPage.value - 1) * itemsPerPage.value;
      const endIndex = startIndex + itemsPerPage.value;
      return filteredItems.value.slice(startIndex, endIndex);
    });

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value -= 1;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value += 1;
      }
    };

    const searchQuery = ref("");
    const filteredItems = computed(() => {
      const query = searchQuery.value.toLowerCase();
      return products.value.filter((item) => {
        if ((item && item.name) || item.descritpts) {
          return (
            item.name.toLowerCase().includes(query) ||
            item.descritpts.toLowerCase().includes(query)
          );
        }
        return false;
      });
    });
    const itemsPerPageOptions = ref([20, 30, 40, 50]);

    // Update pagination when items per page changes
    const updatePagination = () => {
      currentPage.value = 1; // Reset to first page when items per page changes
    };
    const goToPage = (pageNumber) => {
      currentPage.value = pageNumber;
    };

    const truncateDescription = (description) => {
      const words = description.split(" ");
      if (words.length > 20) {
        return words.slice(0, 20).join(" ") + " ...";
      } else {
        return description;
      }
    };

    const partner = ref([]);
    const getDataPartner = async () => {
      try {
        await getCollectionQuery(
          "partner",
          [],
          (data) => {
            partner.value = data;
          },
          true
        );
      } catch (error) {
        console.error(error.message);
      }
    };
    const handleGogoContact = () => {
      router.push({ name: "contact" });
    };
    return {
      dataitem,
      products,
      handleGogoContact,
      partner,
      truncateDescription,
      sortList,
      currentPageItems,
      currentPage,
      totalPages,
      prevPage,
      nextPage,
      filteredItems,
      searchQuery,
      itemsPerPage,
      itemsPerPageOptions,
      updatePagination,
      goToPage,
      isDropdown,
      type: " border",
    };
  },
};
</script>

<style >
::-webkit-scrollbar {
  display: none;
}

* {
  scrollbar-width: none;
}

/* 
::-webkit-scrollbar-thumb:vertical {
  background: #510192b9;
  border-radius: 10px;
  margin-left: 20px;
  transition: 3s;

} */

/*
  Enter and leave animations can use different
  durations and timing functions.
*/

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}
</style>
