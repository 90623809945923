<template>
  <div class="w-full px-4 mx-auto my-5 lg:my-16 max-w-7xl">
    <div class="grid gap-12 xl:grid-cols-2">
      <!-- Image Section -->
      <div class="h-auto">
        <h1 class="mb-5 text-xl font-bold leading-snug text-center md:text-[28px] md:mt-6 lg:mt-0 lg:hidden">
          ដំណោះស្រាយសម្រាប់អាជីវកម្មជាមួយប្រព័ន្ធបច្ចេកវិទ្យាឌីជីថល
        </h1>
        <!-- <img src="https://fakeimg.pl/600x400/" class="w-full rounded-lg" alt="banner" /> -->


        <!-- <iframe v-if="banner.videoLink && getYouTubeEmbedLink(banner.videoLink)"
          :src="getYouTubeEmbedLink(banner.videoLink)" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen
          class="w-full h-full aspect-video">
        </iframe> -->

        <!-- <video v-else-if="banner.videoLink && /\.(mp4|webm|ogg|m4v)$/i.test(banner.videoLink)" :src="banner.videoLink"
          muted autoplay controls class="w-full h-full ">
        </video>
        <img v-else class="w-full h-full " :src="banner.image" alt=""> -->

        <div v-for="requestDoc in request_quoteDoc" :key="requestDoc">
          <img :src="requestDoc.image" class="border" alt="">
        </div>
      </div>

      <!-- Text Section -->
      <div class="lg:mt-5">
        <h1 class="hidden text-xl font-bold leading-snug md:text-3xl lg:block">
          ដំណោះស្រាយសម្រាប់អាជីវកម្មជាមួយប្រព័ន្ធបច្ចេកវិទ្យាឌីជីថល
        </h1>
        <p class="text-center text-gray-600 lg:text-start md:mt-4 xl:text-xl lg:text-lg">
          ផ្តល់សេវាកម្មបច្ចេកវិទ្យាតាំងពី២០១៣
        </p>
      </div>
    </div>

    <!-- Title -->
    <h2 class="lg:my-10 mb-10 text-[20px] md:text-[26px] font-bold text-center">
      បំពេញព័ត៍មានដើម្បីទទួលបានការពិភាក្សាឥតគិតថ្លៃ
    </h2>

    <!-- Form -->
    <form @submit.prevent="handleSubmit" class="w-full max-w-4xl mx-auto">
      <!-- Row 1 -->
      <div class="flex flex-col gap-4 md:flex-row">
        <div class="w-full space-y-2">
          <label for="name" class="text-[18px] font-bold">ឈ្មោះរបស់អ្នក <span class="text-red-500">*</span></label>
          <input type="text" id="name" class="w-full input1" placeholder="ឈ្មោះរបស់អ្នក *" required v-model="name" />
        </div>

        <div class="w-full space-y-2">
          <label for="telegram" class="text-[18px] font-bold">តេឡេក្រាម <span class="text-red-500">*</span></label>
          <input type="text" id="telegram" class="w-full input1" placeholder="តេឡេក្រាម *" required
            v-model="telegram" />
        </div>
      </div>

      <!-- Row 2 -->
      <div class="flex flex-col gap-4 my-6 md:flex-row">
        <div class="w-full space-y-2">
          <label for="phone" class="text-[18px] font-bold">លេខទូរស័ព្ទ <span class="text-red-500">*</span></label>
          <input type="text" id="phone" class="w-full input1" placeholder="លេខទូរស័ព្ទ *" required v-model="phone" />
        </div>

        <div class="w-full space-y-2">
          <label for="address" class="text-[18px] font-bold">អាស័យដ្ឋាន</label>
          <textarea id="address" rows="4" class="w-full resize-none input1" placeholder="អាស័យដ្ឋាន"
            v-model="address"></textarea>
        </div>
      </div>

      <!-- Time/Product Selection -->
      <div class="flex flex-col gap-2 md:w-[50%] mx-auto">
        <label for="select" class="text-[18px] font-bold text-center md:text-left">ជ្រើសរើសផលិតផល</label>
        <select id="select" v-model="productName" required class="w-full input1">
          <option disabled value="">-- ជ្រើសរើស --</option>
          <option v-for="pro in products" :key="pro.id" :value="pro.name">{{ pro.name }}</option>
        </select>
      </div>

      <!-- Submit Button -->
      <div class="mb-10 text-center">
        <button v-if="!isLoading"
          class="btn-hover text-[17px] md:mt-[20px]  md:text-[14px] lg:text-[16px] xl:text-[18px] font-NotoSansKhmer font-bold grow_skew_forward relative z-10 text-white text-2xl py-2 px-12 mt-4  hover:border-blue-500 overflow-hidden bg-gradient-to-r from-purple-600 to-indigo-700 shadow-md rounded-lg">
          បញ្ជូន
        </button>
        <button v-else disabled
          class="btn-hover text-[17px] md:mt-[20px]  md:text-[14px] lg:text-[16px] xl:text-[18px] font-NotoSansKhmer font-bold grow_skew_forward relative z-10 text-white text-2xl py-2 px-12 mt-4  hover:border-blue-500 overflow-hidden bg-gradient-to-r from-purple-600 to-indigo-700 shadow-md rounded-lg">
          កំពុងបញ្ជូន...
        </button>
      </div>
    </form>
  </div>

  <Notivue v-slot="item">
    <Notification :item="item" />
  </Notivue>


</template>

<script>
import { ref } from 'vue'
import getCollection from '@/composible/getCollections'
import useCollection from '@/composible/useCollection'
import { push, Notivue, Notification } from 'notivue'
import { create } from 'quill'
import { timestamp } from '@/firebase/config'

export default {
  components: {
    Notivue,
    Notification,

  },
  setup() {
    const name = ref('')
    const telegram = ref('')
    const phone = ref('')
    const address = ref('')
    const productName = ref('')
    const isLoading = ref(false)

    const { documents: products } = getCollection('products')
    const { documents: request_quoteDoc } = getCollection('Media-Video')
    const { addDocs } = useCollection('Request-Quote')

    const handleSubmit = async () => {
      isLoading.value = true
      try {
        const requestData = {
          name: name.value,
          telegram: telegram.value,
          phone: phone.value,
          address: address.value,
          products: productName.value,
          status: 'pending',
          createdAt: timestamp(),
        }

        await addDocs(requestData)
        console.log('Request data:', requestData)
        push.success('បានបញ្ជូនបានជោគជ័យ', {
          duration: 3000,
          position: 'top-right',
        })

        // Reset form
        name.value = ''
        telegram.value = ''
        phone.value = ''
        address.value = ''
        productName.value = ''
      } catch (err) {
        console.error(err)
        push.error('មានបញ្ហាក្នុងការបញ្ជូនទិន្នន័យ')
      } finally {
        isLoading.value = false
      }
    }



    const getYouTubeEmbedLink = (url) => {
      const youtubeRegex = /(?:youtu\.be\/|youtube\.com\/(?:[^\/]+\/\S+\/|(?:watch\?v=)))([a-zA-Z0-9_-]+)/;
      const match = url.match(youtubeRegex);
      if (match) {
        return `https://www.youtube.com/embed/${match[1]}?autoplay=1&mute=1`;
      }
      return '';
    };


    return {
      name,
      telegram,
      phone,
      address,
      productName,
      products,
      handleSubmit,
      isLoading,

      getYouTubeEmbedLink,
      request_quoteDoc


    }
  },
}
</script>