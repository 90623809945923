<template>


    <div class="bg-gradient-to-r from-[#500192] to-indigo-400/70 pt-10">
        <div
            class="xl:w-[1280px] xl:h-[650px] lg:h-[520px] md:w-[90%] md:h-[400px] h-[800px]  lg:w-[88%] overflow-hidden mx-auto xl:px-5">
            <div class="gap-4 lg:gap-10 xl:gap-8 md:grid-cols-2 md:grid">
                <div class="md:mt-12 md:block hidden lg:mt-14 xl:mt-24 mt-14">
                    <div
                        class="font-NotoSansKhmer text-center md:text-start xl:p-5 space-y-2.5 px-5 md:p-0 md:space-y-1.5">
                        <!-- Fade-up for Title -->
                        <transition name="fade-up" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                            <p v-if="showTitle"
                                class="text-white  text-[20px] md:text-[18px]  xl:text-[28px] lg:text-[20px] font-poppins font-[600] animate-title">
                                <!-- Master-IT System -->
                            </p>
                        </transition>

                        <!-- Fade-up for Khmer Title -->
                        <transition name="fade-up" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                            <h2 v-if="showTitle"
                                class="lg:text-[30px] text-[30px]  xl:text-[45px] md:text-[24px] font-bold text-background animate-title">
                                ម៉ាស្ទ័រអាយធី ស៊ីស្ទឹម
                            </h2>
                        </transition>

                        <div class="space-y-5 md:space-y-1 lg:space-y-3 xl:space-y-3">
                            <transition name="fade-up" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                                <h2 v-if="showSubheading"
                                    class="font-[500] text-[20px] text-background lg:text-[15px] md:text-[14px] xl:text-[20px] animate-title">
                                    គ្រប់គ្រងអាជីវកម្មកាន់តែស្រួល រីកចម្រើនកាន់តែលឿន។
                                </h2>
                            </transition>

                            <transition name="fade-up" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                                <h2 v-if="showSubheading"
                                    class="font-[500] text-justify text-[20px] text-background lg:text-[17px] md:text-[15px] xl:text-[22px] animate-title">
                                    ម៉ាស្ទ័រអាយធី ស៊ីស្ទឹម គឺជាក្រុមហ៊ុនផ្តល់ដំណោះស្រាយផ្នែកបច្ចេកវិទ្យា
                                    ដែលមានឯកទេសដូចជា៖
                                </h2>
                            </transition>

                            <transition name="fade-up" @before-enter="beforeEnter" @enter="enter" @leave="leave">

                                <ul v-if="showSubheading"
                                    class="font-[500] text-[16px] text-background lg:text-[13px] md:text-[13px] xl:text-[17px] pl-5 list-disc space-y-1">
                                    <li>អភិវឌ្ឍន៍ប្រព័ន្ធគ្រប់គ្រងទិន្នន័យថ្មី (Software Development)</li>
                                    <li>រចនាគេហទំព័រ (Web Design)</li>
                                    <li>រៀបចំប្រព័ន្ធគ្រប់គ្រងទិន្នន័យសម្រាប់អាជីវកម្មគ្រប់ប្រភេទ (Software Consultant)
                                    </li>

                                </ul>
                            </transition>
                            <transition name="fade-up" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                                <div v-show="showSubheading">


                                    <router-link to="/contact">
                                        <button
                                            class="btn-hover rounded-lg text-[17px] md:mt-[10px] md:text-[14px] lg:text-[15px] xl:text-[18px] font-NotoSansKhmer font-bold grow_skew_forward relative z-10 text-white text-2xl py-2 px-4 md:p-0.5 md:px-3 lg:py-1 lg:px-3 xl:py-2 xl:px-4 mt-4 overflow-hidden bg-gradient-to-r from-purple-600 to-indigo-700 shadow-xl">
                                            ទាក់ទងមកពួកយើង
                                        </button>

                                    </router-link>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>


                <div class="md:mt-5 md:hidden lg:mt-20 xl:mt-28 mt-5">
                    <div class="font-NotoSansKhmer md:text-start xl:p-5 space-y-2.5 px-5 md:p-0 md:space-y-1.5">

                        <h2
                            class="lg:text-[30px] text-[30px]  xl:text-[45px] md:text-[30px] font-bold text-background animate-title">
                            ម៉ាស្ទ័រអាយធី ស៊ីស្ទីម
                        </h2>

                        <div class="space-y-2 md:space-y-0">
                            <h2
                                class="font-[500] text-[17px] text-background lg:text-[15px] md:text-[15px] xl:text-[22px] animate-title">
                                គ្រប់គ្រងអាជីវកម្មកាន់តែស្រួល រីកចម្រើនកាន់តែលឿន។
                            </h2>

                            <div class="space-y-2">
                                <div
                                    class="font-[500] text-[18px] text-background lg:text-[17px] md:text-[15px] xl:text-[22px] animate-title">
                                    ម៉ាស្ទ័រអាយធី ស៊ីស្ទឹម គឺជាក្រុមហ៊ុនផ្តល់ដំណោះស្រាយផ្នែកបច្ចេកវិទ្យា
                                    ដែលមានឯកទេសដូចជា៖
                                </div>
                                <ul
                                    class="font-[500] text-[16px] text-background lg:text-[13px] md:text-[13px] xl:text-[18px] pl-5 list-disc space-y-1">
                                    <li>អភិវឌ្ឍន៍ប្រព័ន្ធគ្រប់គ្រងទិន្នន័យថ្មី (Software Development)</li>
                                    <li>រចនាគេហទំព័រ (Web Design)</li>
                                    <li>រៀបចំប្រព័ន្ធគ្រប់គ្រងទិន្នន័យសម្រាប់អាជីវកម្មគ្រប់ប្រភេទ (Software Consultant)
                                    </li>

                                </ul>
                            </div>

                            <div>
                                <router-link to="/contact">
                                    <button
                                        class="btn-hover rounded-lg text-[17px] md:mt-[25px] md:text-[14px] lg:text-[16px] xl:text-[18px] font-NotoSansKhmer font-bold grow_skew_forward relative z-10 text-white text-2xl py-1 px-4 mt-4 overflow-hidden bg-gradient-to-r from-purple-600 to-indigo-700 shadow-xl">
                                        ទាក់ទងមកពួកយើង
                                    </button>

                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <swiper :navigation="{
                        nextEl: '.button-next',
                        prevEl: '.button-pre'
                    }" :modules="modules" :loop="true" class="mySwiper select-non relative">
                        <swiper-slide v-for="banner in banners" :key="banner.id">

                            <transition name="fade-up" @before-enter="beforeEnter" @enter="enter" @leave="leave">

                                <!-- <div v-if="showTitle"
                    class="flex justify-center mt-10 md:mt-0 px-4 md:px-0 h-[380px] md:w-[400px] md:h-[360px] lg:w-[500px] lg:h-[450px] xl:w-[700px] xl:h-[600px] "> -->
                                <div v-if="showTitle"
                                    class="flex justify-center mt-10 md:mt-0 px-4 md:px-0 w-full h-[380px] md:w-[400px] md:h-[360px] lg:w-[500px] lg:h-[450px] xl:w-[700px] xl:h-[600px]">

                                    <iframe v-if="banner.videoLink && getYouTubeEmbedLink(banner.videoLink)"
                                        :src="getYouTubeEmbedLink(banner.videoLink)" frameborder="0"
                                        allow="autoplay; encrypted-media" allowfullscreen
                                        class="w-full h-full aspect-video">
                                    </iframe>

                                    <video
                                        v-else-if="banner.videoLink && /\.(mp4|webm|ogg|m4v)$/i.test(banner.videoLink)"
                                        :src="banner.videoLink" muted autoplay controls class="w-full h-full ">
                                    </video>
                                    <img v-else class="w-full h-full " :src="banner.image" alt="">
                                </div>

                            </transition>

                        </swiper-slide>

                        <button
                            class="button-pre absolute z-50 top-[45%] hover:text-red-400  left-4 md:left-0 bg-gradient-to-r from-[#500192] to-indigo-700 p-2.5  md:p-1 lg:p-1.5 xl:p-3 shadow-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="size-5 md:size-5 lg:size-6 text-white">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
                            </svg>

                        </button>
                        <button
                            class="button-next absolute z-50 top-[45%] hover:text-red-400 right-4 md:right-0 bg-gradient-to-l from-[#500192]/60 to-indigo-700 p-2.5  md:p-1 lg:p-1.5 xl:p-3 shadow-lg">

                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class=" size-5 md:size-5 lg:size-6 text-white">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                            </svg>

                        </button>
                    </swiper>
                </div>
            </div>
        </div>
    </div>



</template>

<script>

import { ref } from "vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

import getCollection from "@/composible/getCollections";
import { orderBy } from "@firebase/firestore";


export default {

    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const showTitle = ref(false);
        const showKhmerTitle = ref(false);
        const showSubheading = ref(false);
        const showButton = ref(false);

        const { documents: banners } = getCollection("banners", orderBy('createdAt', "desc"))


        setTimeout(() => showTitle.value = true, 300);
        setTimeout(() => showKhmerTitle.value = true, 900);
        setTimeout(() => showSubheading.value = true, 1300);
        setTimeout(() => showButton.value = true, 2000);

        const beforeEnter = (el) => {
            el.style.opacity = 0;
            el.style.transform = "translateY(90px)";
        };
        const enter = (el, done) => {
            el.offsetHeight; // trigger reflow
            el.style.transition = "opacity 1s ease-out, transform 1s ease-out";
            el.style.opacity = 1;
            el.style.transform = "translateY(0px)";
            done();
        };
        const leave = (el, done) => {
            el.style.transition = "opacity 0.1s ease-in,  0.2s ease-in";
            el.style.opacity = 0;
            el.style.transform = "translateY(-30px)"; // Move up when leaving
            done();
        };


        const getYouTubeEmbedLink = (url) => {
            const youtubeRegex = /(?:youtu\.be\/|youtube\.com\/(?:[^\/]+\/\S+\/|(?:watch\?v=)))([a-zA-Z0-9_-]+)/;
            const match = url.match(youtubeRegex);
            if (match) {
                return `https://www.youtube.com/embed/${match[1]}?autoplay=1&mute=1`;
            }
            return '';
        };

        

        return {
            showTitle,
            showKhmerTitle,
            showSubheading,
            showButton,
            beforeEnter,
            enter,
            leave,
            modules: [Navigation],
            banners,

            getYouTubeEmbedLink
        };
    }
};



</script>

<style scoped></style>