<template>
    <div>

    </div>
    <div v-motion :initial="{
        opacity: 0,
    }" :enter="{

        opacity: 1,
        transition: {
            duration: 500,
            ease: 'easeIn',
        },
    }" class="flex flex-col mb-10 bg-background xl:pt-4 md:mb-20 lg:mb-10">
        <div class="xl:w-full md:w-full w-full h-auto xl:py-3 lg:mb-[0px] xl:mb-0">
            <div class="lg:w-full xl:w-[80%]  w-full md:w-full mx-auto">
                <div class="text-center lg:mt-0 xl:-mt-8">
                    <div class="pb-5 text-center lg:mt-5 xl:mt-0">
                        <h1
                            class="text-center text-primery1 xl:text-3xl xl:my-0 md:text-3xl md:mt-[25px] mt-5 lg:text-2xl  text-2xl xl:mt-10 font-NotoSansKhmer">
                            សកម្មភាពក្រុមការងារ
                        </h1>
                        <div class="border-b-[4px] border-primery1 w-16 lg:w-16 xl:w-20 mx-auto mt-1.5"></div>
                    </div>
                </div>
            </div>



            <div
                class=" xl:mt-10 lg:w-[85%] xl:w-[85%] md:w-[90%] md:px-4 mx-auto xl:px-5 mt-5 space-y-4 sm:space-y-14  px-10 md:space-y-8">

                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 md:gap-8 ">
                    <div class="">
                        <img class="w-full border shadow-sm h-[250px] rounded-md   sm:w-[300px] sm:h-[250px] md:w-full md:h-[300px] lg:w-full lg:h-[350px] xl:w-full xl:h-[450px] object-cover"
                            src="@/assets/teamwork/700x450-1.png" alt="">
                    </div>

                    <div class="sm:mt-8 md:mt-10 lg:mt-20">
                        <div class="p-4 bg-white border rounded-md shadow-sm md:p-5">
                            <h2 class="text-[18px] md:text-[20px] lg:text-[22px] xl:text-[25px] font-bold">
                                សកម្មភាពក្រុមការងារចុះ រៀបចំប្រព័ន្ធគ្រប់គ្រងអាហារដ្ឋាន និងបង្រៀនអតិថិជន នៅកោះរុង។</h2>
                        </div>
                    </div>

                </div>


                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 md:gap-8 ">

                    <div class="sm:mt-8 md:mt-10 lg:mt-16">

                        <div class="p-4 bg-white border rounded-md shadow-sm md:p-5">
                            <h2 class="text-[18px] md:text-[20px] lg:text-[22px] xl:text-[25px] font-bold">
                            សកម្មភាពក្រុមការងារចុះ រៀបចំប្រព័ន្ធគ្រប់គ្រងការលក់និងស្តុក និងបង្រៀនអតិថិជន នៅក្រុងបាត់ដំបង។</h2>
                        </div>
                    </div>


                    <div class="">
                        <img class="w-full border h-[250px] shadow-sm rounded-md   sm:w-[300px] sm:h-[250px] md:w-full md:h-[300px] lg:w-full lg:h-[350px] xl:w-full xl:h-[450px]  object-cover"
                            src="@/assets/teamwork/700x450-2.png" alt="">
                    </div>

                </div>


                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 md:gap-8 ">

                    <div class="">
                        <img class="w-full border h-[250px] shadow-sm rounded-md   sm:w-[300px] sm:h-[250px] md:w-full md:h-[300px] lg:w-full lg:h-[350px] xl:w-full xl:h-[450px]  object-cover"
                            src="@/assets/teamwork/700x450-3.png" alt="">
                    </div>



                    <div class="">
                        <img class="w-full border h-[250px] shadow-sm rounded-md   sm:w-[300px] sm:h-[250px] md:w-full md:h-[300px] lg:w-full lg:h-[350px] xl:w-full xl:h-[450px]  object-cover"
                            src="@/assets/teamwork/700x450-6.png" alt="">
                    </div>

                </div>

                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 xl:grid-cols-2 md:gap-8 ">

                   <div class="grid grid-cols-2 gap-5 md:gap-8">
                    <div class="">
                        <img class="w-full border h-[250px] shadow-sm rounded-md   sm:w-[300px] sm:h-[250px] md:w-full md:h-[300px] lg:w-full lg:h-[350px] xl:w-full xl:h-[450px]  object-cover"
                            src="@/assets/teamwork/300x450-1.png" alt="">
                    </div>
                    <div class="">
                        <img class="w-full border h-[250px] shadow-sm rounded-md   sm:w-[300px] sm:h-[250px] md:w-full md:h-[300px] lg:w-full lg:h-[350px] xl:w-full xl:h-[450px]  object-cover"
                            src="@/assets/teamwork/300x450-2.png" alt="">
                    </div>


                   </div>


                    <div class="">
                        <img class="w-full border h-[250px] shadow-sm rounded-md   sm:w-[300px] sm:h-[250px] md:w-full md:h-[300px] lg:w-full lg:h-[350px] xl:w-full xl:h-[450px]  object-cover"
                            src="@/assets/teamwork/700x450-4.png" alt="">
                    </div>

                </div>







                <div>
                    <img class="w-full md:h-[300px] lg:h-[350px] h-[250px] xl:h-[600px] rounded-md object-cover"
                        src="@/assets/teamwork/1000x600-1.png" alt="image description">

                </div>


            </div>
        </div>
    </div>


</template>

<script>
import { useRouter } from "vue-router";
import getCollection from "@/composible/getCollections.js";


import Navbar from "./Navbar.vue";
export default {
    components: {
        Navbar
    },
    setup() {


        const { documents: category } = getCollection('categories')


        const router = useRouter();

        return { category };
    },
};
</script>

<style scoped></style>