<template>
    <div class="md:w-[750px] lg:w-[85%] xl:w-[1420px] xl:mx-auto mx-auto hidden sm:block">
        <swiper :slides-per-view="1" :space-between="30" :loop="loop"
      
            :keyboard="true" 
            
            :pagination="pagination" :modules="modules" class="mySwiper" :effect="'fade'"
                :speed="800" @swiper="onSwiper" @slideChange="onSlideChange">
            <swiper-slide class="fade-slide p-1 lg:h-[230px] xl:h-[320px] bg-white" v-for="(image, index) in slides"
                :key="index">
                <div class="flex sm:gap-5 sm:mx-5 md:gap-4 md:pl-4 lg:p-0 gap-10">
                    <div class="sm:w-[240px] sm:h-[170px] md:w-[392px] md:h-[230px] lg:w-[392px] lg:h-[220px] xl:w-[440px]">
                        <img class="bg-white w-full h-auto xl:h-[260px] lg:object-contain xl:object-cover rounded-[6px]"
                            :src="image.image" alt="">
                    </div>
                    <div class="sm:w-[400px] md:ml-5 md:w-[464px] lg:w-[700px] lg:h-[220px] xl:w-[900px] xl:h-[260px]">
                        <div class="sm:space-y-2 md:space-y-[16px] md:p-[20px, 10px, 20px, 10px] lg:py-0 xl:p-[20px, 10px, 20px, 10px] lg:space-y-[10px] xl:space-y-[16px]">
                            <h3 class="md:text-[16px] lg:text-[20px] xl:text-[24px] font-bold">{{ image.name }}</h3>
                            <p class="sm:text-[12px] md:text-[14px] lg:text-[14px] xl:text-[16px] xl:text-wrap">{{ image.descriptions }}</p>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// Import required modules
import { Pagination } from 'swiper/modules';
import getCollection from '@/composible/getCollections';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const { documents: slides } = getCollection("products");

        return {
            pagination: {
                clickable: true,
                renderBullet: function (index, className) {
                    const isActive = className.includes('swiper-pagination-bullet-active');
                    const activeClass = isActive ? 'bg-primray1 w-[100px] h-[10px]' : 'bg-primery1 w-[20px] rounded-0 h-[15px]';
                    return `<span class="${className} ${activeClass} "></span>`;
                }
            },
            modules: [Pagination],
            slides
        };
    },
};
</script>
