<template>

    <div class="h-full bg-gray-100 font-NotoSansKhmer">
        <div class="h-full ">
            <div class="flex w-full h-screen">
           


                <!-- main -->
                <div class="w-full mx-4 my-4">
             
                    <div class="mt-3">

                        <button @click="isAdd"
                            class="flex items-center gap-2 px-6 py-2 font-semibold text-white group hover:bg-indigo-800 bg-primery1 text-body">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                class="w-[20px] h-[30px] text-white group-hover:text-background">
                                <path
                                    d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z" />
                            </svg>

                            បន្ថែមថ្មី
                        </button>
                        <div class="w-full bg-white/50 p-4 mt-3 h-[650px] overflow-auto">
                            <!-- product -->

                            <div>
                                <div class="flex items-center justify-between w-full">


                                    <div>
                                        <vs-select width="100px" @change="updatePagination" v-model="itemsPerPage">
                                            <vs-select-item v-for="(option, index) in itemsPerPageOptions" :key="index"
                                                :modelValue="option" :text="option" />
                                        </vs-select>
                                    </div>
                                    <div class="flex gap-2">
                                        <div class="relative flex items-center px-2">
                                            <input placeholder="ស្វែងរកតាមឈ្មោះ"
                                                class="w-full p-2 border-[1px] hover:border-primery1 bg-white/50 px-5 outline-none"
                                                type="text" name="" id="" v-model="searchQuery" />
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                                                fill="currentColor" class="absolute w-7 h-7 right-4">
                                                <path fill-rule="evenodd"
                                                    d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                        </div>

                                        <Menu as="div" class="relative inline-block text-left">
                                            <div>
                                                <MenuButton
                                                    class="inline-flex justify-center w-full text-sm font-medium text-white rounded-md hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                                                    <button
                                                        class="p-3 group bg-background flex items-center gap-2 border-[1px] hover:bg-gray-50 hover:border-primery1 text-body  font-semibold">
                                                        <font-awesome-icon icon="filter" class=" text-primery1" />
                                                    </button>
                                                </MenuButton>
                                            </div>

                                            <transition enter-active-class="transition duration-100 ease-out"
                                                enter-from-class="transform scale-95 opacity-0"
                                                enter-to-class="transform scale-100 opacity-100"
                                                leave-active-class="transition duration-75 ease-in"
                                                leave-from-class="transform scale-100 opacity-100"
                                                leave-to-class="transform scale-95 opacity-0">
                                                <MenuItems
                                                    class="absolute right-0 z-40 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md ring-1 ring-black/5 focus:outline-none">
                                                    <div class="px-1 py-1">
                                                        <MenuItem v-slot="{ active }">
                                                        <button @click="sortList('name')" :class="[
                                                            active ? 'bg-primery1 text-white' : 'text-gray-900',
                                                            'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                                                        ]">
                                                            <EditIcon :active="active"
                                                                class="w-5 h-5 mr-2 text-violet-400"
                                                                aria-hidden="true" />
                                                            ច្រោះតាមឈ្មោះ A-Z
                                                        </button>
                                                        </MenuItem>
                                                        <MenuItem v-slot="{ active }">
                                                        <button @click="sortList('createdAt')" :class="[
                                                            active ? 'bg-primery1 text-white' : 'text-gray-900',
                                                            'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                                                        ]">
                                                            <DuplicateIcon :active="active"
                                                                class="w-5 h-5 mr-2 text-violet-400"
                                                                aria-hidden="true" />
                                                            ច្រោះតាមថ្ងៃខែ
                                                        </button>
                                                        </MenuItem>
                                                    </div>
                                                </MenuItems>
                                            </transition>
                                        </Menu>
                                    </div>
                                </div>


                                <div :class="isOpenSidebar ? ' transition-all duration-300 ease-in-out' : 'xl:w-[110rem] transition-all duration-300 ease-in-out'"
                                    class=" h-[800px] overflow-auto mt-4">
                                    <div v-if="currentPageItems.length > 0" class="relative overflow-x-auto">
                                        <table
                                            class="w-full text-sm text-left text-gray-500 rtl:text-right dark:text-gray-400">
                                            <thead
                                                class="text-gray-700 uppercase bg-gray-200/30 dark:bg-gray-700 dark:text-gray-400">
                                                <tr class="text-center border-2">

                                                    <th scope="col" class="px-3 text-[15px]  py-5">
                                                        ល.រ
                                                    </th>

                                                    <th scope="col" class="px-6 text-[17px]  py-5 flex-nowrap">
                                                        ឈ្មោះផលិតផល
                                                    </th>
                                                    <th scope="col" class="px-6 text-[17px]  py-5 flex-nowrap">
                                                        ប្រភេទផលិតផល
                                                    </th>
                                                    <th scope="col" class="px-6 text-[17px]  py-5 flex-nowrap">
                                                        ម៉ាកយីហោ
                                                    </th>
                                                    <th scope="col" class="px-6 text-[17px]  py-5">
                                                        ចំនួន
                                                    </th>
                                                    <th scope="col" class="px-6 text-[17px]  py-5">
                                                        តម្លៃ
                                                    </th>

                                                    <th scope="col" class="px-6 text-[17px] py-5">
                                                        ពិពណ៌នាផលិតផល
                                                    </th>
                                                    <th scope="col" class="px-6 text-[17px] py-5">
                                                        រូបភាព
                                                    </th>
                                                    <th scope="col" class="px-6 text-[17px]  py-5">
                                                        កាលបរិច្ឆេត
                                                    </th>
                                                    <th scope="col" class="px-6 text-[17px] py-5">
                                                        សកម្មភាព
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- <tr v-for="(data, index) in currentPageItems" :key="index"
                                                    class="overflow-hidden">
                                                    <td class="px-6 py-4 capitalize">{{ index + 1 }}</td>
                                                    <td class="py-4 capitalize ">{{ data.productName }}</td>
                                                    <td class="py-4 capitalize ">{{ data.category }}</td>
                                                    <td class="py-4 capitalize ">{{ data.brand }}</td>
                                                    <td class="py-4 capitalize ">{{ data.qty }}</td>
                                                    <td class="py-4 capitalize ">{{ data.price }}</td>
                                                    <td class="py-4 capitalize ">
                                                        <span v-html='data.productDesscript'
                                                            class="prose prose-p:line-clamp-1 line-clamp-1"></span>
                                                    </td>
                                                    <td class="md:w-[10%] py-4">
                                                        <img class="object-cover w-16 mx-auto " :src="data.url" />
                                                    </td>

                                                    <td class="px-6 py-4">
                                                        {{
                                                            data && data.createdAt
                                                                ? new Date(
                                                                    data.createdAt.seconds * 1000
                                                        ).toLocaleString()
                                                        : "N/A"
                                                        }}
                                                    </td>
                                                    <td>
                                                        <div class="flex items-center justify-center gap-2">
                                                            <router-link v-if="data && data.id" :to="{
                                                                name: 'productsdetails',
                                                                params: { id: data.id },
                                                            }">

                                                            </router-link>

                                                            <button @click="handleAddEditData(data)"
                                                                class="w-[36px] flex items-center justify-center h-[36px] bg-accent rounded-[6px]">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 20 20" fill="currentColor"
                                                                    class="w-6 h-6 text-white">
                                                                    <path
                                                                        d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
                                                                    <path
                                                                        d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
                                                                </svg>
                                                            </button>
                                                            <button @click="handleDelete(data.id)"
                                                                class="w-[36px] flex items-center justify-center h-[36px] bg-red-500 rounded-[6px]">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 20 20" fill="currentColor"
                                                                    class="w-6 h-6 text-white">
                                                                    <path fill-rule="evenodd"
                                                                        d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                                                                        clip-rule="evenodd" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <div v-if="isDeleteOpen == true"
                                                        class="fixed w-full h-full bg-black/10 flex items-center top-0 right-0 justify-center z-[50]">
                                                        <div v-motion-slide-top
                                                            class="w-[400px] bg-background p-3  xl:mb-0 -mt-[40rem]">
                                                            <h2 class="text-xl text-body text-primery1">
                                                                តើអ្នកពិតជាចង់លុបផលិតផលនេះមែនទេ?
                                                            </h2>
                                                            <div class="flex items-center justify-end gap-2 py-4">
                                                                <button
                                                                    class="p-2 px-5 text-white transition-all duration-300 ease-in-out bg-accent hover:bg-yellow-500"
                                                                    @click="closeDelete">
                                                                    បោះបង់
                                                                </button>
                                                                <button
                                                                    class="p-2 px-5 text-white transition-all duration-300 ease-in-out bg-primery1 hover:bg-blue-500 hover:text-white"
                                                                    @click="deleteProduct">
                                                                    លុប
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </tr> -->


                                                <template v-for="(cate, index) in hardwareCategory" :key="cate.id">

                                                    <tr v-for="hardCat in cate.hardware" :key="hardCat">
                                                        <td>{{ index + 1 }}</td>
                                                        <td>{{ hardCat.productName }}</td>
                                                        <td>{{ cate.categoryName }}</td>
                                                        <td>{{ hardCat.brand }}</td>
                                                        <td>{{ hardCat.qty }}</td>
                                                        <td>{{ hardCat.price }}</td>
                                                        <td>
                                                            <p v-html="hardCat.productDesscript"></p>
                                                        </td>
                                                        <td>
                                                            <img class="w-[90px]" :src="hardCat.url" alt="">
                                                        </td>
                                                        <td>
                                                            {{
                                                                hardCat && hardCat.createdAt
                                                                    ? new Date(
                                                                        hardCat.createdAt.seconds * 1000
                                                                    ).toLocaleString()
                                                                    : "N/A"
                                                            }}
                                                        </td>

                                                        <td>
                                                            <div class="flex gap-2 ">
                                                                <button @click="handleAddEditData(cate, hardCat)"
                                                                    class="w-[36px] flex items-center justify-center h-[36px] bg-accent rounded-[6px]">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 20 20" fill="currentColor"
                                                                        class="w-6 h-6 text-white">
                                                                        <path
                                                                            d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
                                                                        <path
                                                                            d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
                                                                    </svg>
                                                                </button>

                                                                <button @click="handleDelete(cate.id, hardCat.id)"
                                                                    class="w-[36px] flex items-center justify-center h-[36px] bg-red-500 rounded-[6px]">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 20 20" fill="currentColor"
                                                                        class="w-6 h-6 text-white">
                                                                        <path fill-rule="evenodd"
                                                                            d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                                                                            clip-rule="evenodd" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </td>


                                                        <div v-if="isDeleteOpen == true"
                                                            class="fixed w-full h-full bg-black/10 flex items-center top-0 right-0 justify-center z-[50]">
                                                            <div v-motion-slide-top
                                                                class="w-[400px] bg-background p-3  xl:mb-0 -mt-[40rem]">
                                                                <h2 class="text-xl text-body text-primery1">
                                                                    តើអ្នកពិតជាចង់លុបផលិតផលនេះមែនទេ?
                                                                </h2>
                                                                <div class="flex items-center justify-end gap-2 py-4">
                                                                    <button
                                                                        class="p-2 px-5 text-white transition-all duration-300 ease-in-out bg-accent hover:bg-yellow-500"
                                                                        @click="closeDelete">
                                                                        បោះបង់
                                                                    </button>
                                                                    <button
                                                                        class="p-2 px-5 text-white transition-all duration-300 ease-in-out bg-primery1 hover:bg-blue-500 hover:text-white"
                                                                        @click="deleteProduct">
                                                                        លុប
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </tr>
                                                </template>


                                            </tbody>
                                        </table>
                                    </div>

                                    <div v-else
                                        class="relative sm:rounded-lg flex items-center justify-center h-[400px]">
                                        <h2 class="text-heading3 text-primery1">មិនមានទិន្ន័យ</h2>
                                    </div>
                                </div>



                                <div class="flex items-center justify-end w-full gap-4 mt-16">

                                    <button :class="{ 'bg-primery1/50': currentPage === 1 }" v-if="currentPage > 1"
                                        class="flex items-center justify-center w-10 h-10 text-white duration-200 rounded-md bg-primery1 hover:bg-background hover:border-2 hover:text-primery1 hover:border-primery1"
                                        @click="prevPage" :disabled="currentPage === 1">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                                            class="w-6 h-6">
                                            <path fill-rule="evenodd"
                                                d="M14 8a.75.75 0 0 1-.75.75H4.56l3.22 3.22a.75.75 0 1 1-1.06 1.06l-4.5-4.5a.75.75 0 0 1 0-1.06l4.5-4.5a.75.75 0 0 1 1.06 1.06L4.56 7.25h8.69A.75.75 0 0 1 14 8Z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </button>
                                    {{ currentPage }} នៃ {{ totalPages }}
                                    <button :class="{ 'bg-primery1/50': currentPage === totalPages }"
                                        class="flex items-center justify-center w-10 h-10 text-white duration-200 rounded-md bg-primery1 hover:bg-background hover:border-2 hover:text-primery1 hover:border-primery1"
                                        @click="nextPage" :disabled="currentPage === totalPages">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                                            class="w-6 h-6">
                                            <path fill-rule="evenodd"
                                                d="M2 8a.75.75 0 0 1 .75-.75h8.69L8.22 4.03a.75.75 0 0 1 1.06-1.06l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 0 1-1.06-1.06l3.22-3.22H2.75A.75.75 0 0 1 2 8Z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <pre>{{ hardwareCategory }}</pre> -->
                    <FooterVue />
                </div>
            </div>

        </div>
        <Notivue v-slot="item">
            <Notification :item="item" />
        </Notivue>
        <component :is="currentComponent" @close="handleClose" @AddSusccesfully="handleSubmit"
            @UpddateSuccess="handleUpdateSuccess" :datatoedit="datatoedit" :catDocument="catDocument"
            :loadHardware="loadHardware" />
    </div>

</template>

<script>


import Navbar from "./Navbar.vue";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

import { onMounted, ref, computed } from "vue";
import AddProductVue from "./AddProduct.vue";
import { push, Notivue, Notification } from "notivue";
import useCollection from "@/composible/useCollection";
import { getCollectionQuery } from "@/composible/getCollection";
import FooterVue from "./Footer.vue";
import { getAuth } from "firebase/auth";
import { deleteDoc, doc, getDoc } from "firebase/firestore";
import { projectFirestore } from "@/firebase/config";
import Navbar_ProductVue from '@/titleAdmin/NavbarProduct.vue';
import NavbarListNewProductVue from '@/titleAdmin/NavbarListNewProduct.vue'
import NavbarHardware from "@/titleAdmin/NavbarHardware.vue";

import AddNewProductsVue from './AddNewProducts.vue';
import getCollection from "@/composible/getCollections";
import useDocument from "@/composible/useDocument";
import { useFirestoreCollection, useSubcollection } from '@/composible/getSubcollection';

export default {
    components: {
        Navbar,
        AddProductVue,
        Notivue,
        Notification,
        Menu,
        MenuButton,
        MenuItems,
        MenuItem,
        FooterVue,
        Navbar_ProductVue,
        NavbarListNewProductVue,
        AddNewProductsVue,
        NavbarHardware
    },
    setup() {

        const isOpenSidebar = ref(true)
        const dataitem = ref([]);
        const sortedbyASC = ref(true);
        const userDocument = ref(null);
        const datatoedit = ref(null);
        const isDeleteOpen = ref(false);
        const searchQuery = ref("");
        const currentComponent = ref("");
        const productId = ref(null);
        const itemsPerPage = ref(20);
        const currentPage = ref(1);
        const catDocument = ref(null)

        const hardwareCategory = ref(null)
        const itemsPerPageOptions = ref([20, 30, 40, 50]);
        const categoryId = ref("")
        const { removeDoc } = useCollection("newProduct");




        const { documents: hardwareCat } = getCollection('hardwareCategories')


        const { documents: categoryDocument, fetchCollection } = useFirestoreCollection("hardwareCategories");

        onMounted(async () => {
            getData();
            fetchUserData();
            await fetchCollection();
            await fetchCategoryHardware();

        });


        const fetchCategoryHardware = async () => {
            // isLoading.value = true
            const orderByField = 'productName';

            try {
                // Fetch all categories and products concurrently
                const categoryPromises = categoryDocument.value.map(async (cate) => {
                    const { subcollectionData: product, fetchSubcollection } = useSubcollection('hardwareCategories', cate.id, 'hardware', orderByField);

                    await fetchSubcollection();

                    return {
                        id: cate.id,
                        categoryName: cate.name,
                        description: cate.description,
                        image: cate.image,
                        route: cate.route,
                        hardware: product.value,
                    };
                });

                const result = await Promise.all(categoryPromises);
                // isLoading.value = false
                hardwareCategory.value = result;
            } catch (err) {
                console.error('Error fetching categories and products:', err);
            }
        };




        const sortList = (sortBy) => {
            if (sortedbyASC.value) {
                dataitem.value.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
                sortedbyASC.value = false;
            } else {
                dataitem.value.sort((x, y) => (x[sortBy] < y[sortBy] ? -1 : 1));
                sortedbyASC.value = true;
            }
        };

        //get data
        const getData = async () => {
            try {
                await getCollectionQuery(
                    "newProduct",
                    [],
                    (data) => {
                        dataitem.value = data;
                    },
                    true
                );
            } catch (error) {
                console.error(error.message);
            }
        };

        const fetchUserData = async () => {
            try {
                const userlogin = getAuth().currentUser;
                if (userlogin) {
                    if (!userDocument.value) {
                        const docRef = doc(projectFirestore, "users", userlogin.uid);
                        const docSnap = await getDoc(docRef);
                        if (docSnap.exists()) {
                            userDocument.value = docSnap.data();
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching user data:", error.message);
            }

        };

        //filter Item search
        const filteredItems = computed(() => {
            const query = searchQuery.value.toLowerCase();
            return dataitem.value.filter((item) => {
                if (item && item.productName) {
                    return item.productName.toLowerCase().includes(query);
                }
                return false;
            });
        });

        //total page paginate
        const totalPages = computed(() => {
            const totalItems = filteredItems.value.length;
            const itemsPerPage = 20;
            return Math.ceil(totalItems / itemsPerPage);
        });

        const currentPageItems = computed(() => {
            const startIndex = (currentPage.value - 1) * itemsPerPage.value;
            const endIndex = startIndex + itemsPerPage.value;
            return filteredItems.value.slice(startIndex, endIndex);
        });

        const updatePagination = () => {
            currentPage.value = 1;
        };

        const prevPage = () => {
            if (currentPage.value > 1) {
                currentPage.value -= 1;
            }
        };

        const nextPage = () => {
            if (currentPage.value < totalPages.value) {
                currentPage.value += 1;
            }
        };

        //close modal
        const handleClose = () => {
            currentComponent.value = "";
        };

        const handleSuccess = (message) => {
            push.success(message);
        };

        //handle submit
        const handleSubmit = () => {
            handleSuccess("អាប់ដេតភាពជោគជ័យ");
        };

        const handleUpdateSuccess = () => {
            handleSuccess("បន្ថែមបានដោយជោគជ័យ");
        };

        const handleAddEditData = (cat, item) => {
            currentComponent.value = "AddNewProductsVue";
            datatoedit.value = item;
            catDocument.value = cat;
            loadHardware();
        };


        const loadHardware = async () => {
            await fetchCategoryHardware();
        }

        //current components
        const isAdd = () => {
            currentComponent.value = "AddNewProductsVue";
            datatoedit.value = null;
        };

        //close delete
        const closeDelete = () => {
            isDeleteOpen.value = !isDeleteOpen.value;
        };

        //delete product
        const handleDelete = (catId, id) => {
            categoryId.value = catId;
            productId.value = id;
            isDeleteOpen.value = !isDeleteOpen.value;
        };

        //delete product
        const deleteProduct = async () => {
            const { deleteDocs } = useDocument('hardwareCategories', categoryId.value, 'hardware')
            isDeleteOpen.value = false;
            try {
                if (!productId.value) {
                    console.error("Product ID is required.");
                    return;
                }
                await deleteDocs(productId.value);
                push.success("បានលុបដោយជោគជ័យ");
                await fetchCategoryHardware();
                console.log(productId.value);
                productId.value = "";
                console.log("Product deleted successfully");
            } catch (error) {
                console.error("Error deleting product:", error);
            }
        };

        return {
            currentComponent,
            isAdd,
            handleClose,
            dataitem,
            handleSubmit,
            handleAddEditData,
            datatoedit,
            handleUpdateSuccess,
            handleDelete,
            isDeleteOpen,
            deleteProduct,
            closeDelete,
            sortList,
            currentPageItems,
            currentPage,
            totalPages,
            prevPage,
            nextPage,
            filteredItems,
            searchQuery,
            itemsPerPage,
            itemsPerPageOptions,
            updatePagination,
            userDocument,
            isOpenSidebar,
            hardwareCat,
            hardwareCategory,
            catDocument,
            loadHardware


        };
    },
};

</script>
<style scoped>
/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #510192b9;
    border-radius: 10px;
    margin-left: 20px;
    transition: 3s;
}

::-webkit-scrollbar-thumb:hover {
    display: block;
    transition: 0.5s;
}

.router-link-active {
    background-color: #500192;
    width: 100%;


    /* border-radius: 6px; */

    color: white;
    /* box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.1); */
}

.custom-option {
    padding: 1.5rem;
}


/* line climp */

.lines {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    height: 120px;
    overflow: hidden;
    font-size: 15px;

}


::-webkit-scrollbar-thumb:horizontal {
    background: #510192b9;
    border-radius: 10px;
    transition: 3s;
    display: none;
}

table,
tr,
th,
td {
    border: 1px solid lightgray;
    border-collapse: collapse;
}
</style>