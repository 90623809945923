<template>
  <div class="flex flex-col h-screen">
    <NavbarVue />
    <div class="mb-auto">
      <router-view></router-view>
    </div>


    <div>
      <Footer />
    </div>
  </div>
</template>

<script>
import NavbarVue from "@/views/Navbar.vue";
import Footer from "./Footer.vue";
import MainPageVue from "./MainPage.vue";
export default {
  components: {
    NavbarVue,
    MainPageVue,
    Footer
  },
  setup() {
    return {};
  },
};
</script>
