<template>
  <div>

  </div>
  <div v-motion :initial="{
    opacity: 0,
  }" :enter="{

    opacity: 1,
    transition: {
      duration: 500,
      ease: 'easeIn',
    },
  }" class="flex flex-col bg-background xl:pt-4 mb-10 md:mb-10 xl:mb-0">
    <div class="xl:w-full md:w-full w-full h-auto xl:py-3 lg:mb-[0px] xl:mb-0">
      <div class="lg:w-full xl:w-[1280px] w-full md:w-full mx-auto">
        <div class="lg:mt-0 xl:-mt-8 md:my-[10px] text-center">
          <div class="lg:mt-5 xl:mt-0  text-center pb-5">
            <h1
              class="text-center text-primery1 xl:text-3xl xl:my-0 md:text-3xl md:mt-[25px] mt-5 lg:text-2xl  text-2xl xl:mt-10 font-NotoSansKhmer">
              ផលិតផល និងសេវាកម្មយើង
            </h1>
            <div class="border-b-[4px] border-primery1 w-16 lg:w-16 xl:w-20 mx-auto mt-1"></div>

          </div>

          <div class="w-full">
            <div class="w-[100%] mx-auto md:w-[90%] md:px-3  lg:w-[990px] xl:w-[50%]  lg:px-5 ">
              <p
                class="px-10 text-justify text-textbody/70 md:p-0 md:text-center font-[400] md:text-[20px] lg:text-body  xl:text-[20px]   font-NotoSansKhmer lg:w-[95%] mx-auto xl:w-[100%] w-full">
                យើងខ្ញុំផ្តល់ជូនការប្រឹក្សាដោយឥតគិតថ្លៃ ដើម្បីជួយលោកអ្នកឱ្យ
                ទទួលបានប្រព័ន្ធ ឬគេហទំព័រ ដែលត្រូវនឹងតម្រូវការបំផុត ហើយចំណាយប្រាក់តិចបំផុត។
              </p>
            </div>
          </div>
        </div>
      </div>


      <div>
        <!-- system -->
        <div class="lg:w-[85%] xl:w-[1280px] md:w-[90%] md:px-4 mx-auto xl:px-5 mt-5 xl:my-10 px-10">

          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 md:gap-5 lg:gap-5 xl:gap-10 gap-10">
            <div v-for="cart in category" :key="cart.id" class="card">
              <div
                class="card__content    duration-300 h-[350px] md:h-[350px] lg:h-[300px] w-[100%] xl:h-[350px]  mx-auto relative">
                <!-- Front side of the card -->
                <div
                  class="card__front absolute top-0 bottom-0 right-0 left-0 flex flex-col items-center justify-center -mt-[35px] md:-mt-[39px] lg:-mt-[40px] xl:-mt-[39px]">
                  <div class="">

                    <img
                      class="w-[400px] h-[250px] border  md:w-[420px] md:h-[300px] lg:w-[350px] lg:h-[250px] xl:w-[400px] xl:h-[300px]"
                      :src="cart.image" alt="Product Image" />

                  </div>

                  <div class="absolute md:-bottom-2 bottom-5   lg:-bottom-0 xl:-bottom-1.5 w-full">
                    <div
                      style="box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;"
                      class="lg:text-[15px]    z-10  bg-primery1 p-4 lg:w-full xl:w-full  flex justify-center  text-center xl:text-start font-NotoSansKhmer xl:text-[18px] mt-3 md:text-[18px] text-[16px] font-bold text-white">
                      {{ cart.name }}
                    </div>
                  </div>

                </div>


                <!-- Back side of the card -->
                <div
                  class="card__back absolute top-0 bottom-0 right-0 left-0 flex items-center justify-center p-8 bg-gray-100 ">

                  <div class="card__front absolute top-0 flex flex-col items-center  p-8">

                    <div
                      class="lg:text-[20px] font-NotoSansKhmer text-start xl:text-start xl:text-[24px] mt-3 md:text-[22px] text-heading2_Mobile font-bold text-black">
                      {{ cart.name }}
                    </div>


                    <div class="w-full  rounded-md  lg:space-y-0 xl:space-y-8 md:space-y-0">
                      <div class="space-y-2  lg:space-y-0 mb-auto xl:space-y-0">
                        <div
                          class="  text-[15px]   mt-[14px] md:mt-[10px] lg:text-[14px]  xl:text-[17px]  md:text-body  duration-300 font-[500]">

                          <span class="md:line-clamp-5 lg:line-clamp-3 line-clamp-6 xl:line-clamp-5  text-justify"
                            v-html="cart.description"></span>
                        </div>
                      </div>
                    </div>



                    <router-link
                      v-if="cart.name === 'អភិវឌ្ឍន៍ប្រព័ន្ធគ្រប់គ្រងទិន្នន័យ' || cart.name === 'រចនាគេហទំព័រ'" to="#"
                      class="flex justify-center">
                      <button
                        class="btn-hover  text-[14px] flex gap-2 items-center md:mt-[25px] md:text-[15px] lg:text-[16px] xl:text-[15px] font-NotoSansKhmer font-bold grow_skew_forward relative z-10 text-white text-2xl py-1.5 px-4 mt-4 overflow-hidden bg-gradient-to-r from-purple-600 to-indigo-700 shadow-xl">
                        <p>មើលលម្អិតបន្ថែម</p>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                          stroke="currentColor" class="size-6">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                        </svg>
                      </button>
                    </router-link>

                    <router-link v-else :to="{ name: 'productServices', params: { id: cart.id } }"
                      class="flex justify-center">
                      <button
                        class="btn-hover  text-[14px] flex gap-2 items-center md:mt-[25px] md:text-[15px] lg:text-[16px] xl:text-[15px] font-NotoSansKhmer font-bold grow_skew_forward relative z-10 text-white text-2xl py-1.5 px-4 mt-4 overflow-hidden bg-gradient-to-r from-purple-600 to-indigo-700 shadow-xl">
                        <p>មើលលម្អិតបន្ថែម</p>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                          stroke="currentColor" class="size-6">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                        </svg>
                      </button>
                    </router-link>



                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>




      <!-- {{ category }} -->

    </div>
  </div>


</template>

<script>
import { useRouter } from "vue-router";
import getCollection from "@/composible/getCollections.js";


import Navbar from "./Navbar.vue";

export default {
  components: {
    Navbar
  },
  setup() {
    const { documents: category } = getCollection('categories')


    return { category };
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}



h2 {
  font-size: 2rem
}

/* Vanilla CSS for flip card */
.card {
  perspective: 800px;
}

.card__content {
  transform-style: preserve-3d;
  transition: transform 1.5s;


}

.card:hover .card__content {
  transform: rotateY(.5turn);
}

.card__front,
.card__back {
  backface-visibility: hidden;
}

.card__back {
  transform: rotateY(.5turn);
}
</style>
