<template>
    <div>
        <div class="fixed  z-50 right-0 top-1/2">
            <div class="space-y-[16px] p-[14px] md:p-[35px]">
                <div>
                    <a href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-[44px] w-[44px] md:w-[50px] md:h-[50px] lg:w-[50px] lg:h-[50px] xl:h-[60px] xl:w-[60px]" viewBox="0 0 256 256">
                            <defs>
                                <radialGradient id="logosMessenger0" cx="19.247%" cy="99.465%" r="108.96%" fx="19.247%"
                                    fy="99.465%">
                                    <stop offset="0%" stop-color="#09f" />
                                    <stop offset="60.975%" stop-color="#a033ff" />
                                    <stop offset="93.482%" stop-color="#ff5280" />
                                    <stop offset="100%" stop-color="#ff7061" />
                                </radialGradient>
                            </defs>
                            <path fill="url(#logosMessenger0)"
                                d="M128 0C55.894 0 0 52.818 0 124.16c0 37.317 15.293 69.562 40.2 91.835c2.09 1.871 3.352 4.493 3.438 7.298l.697 22.77c.223 7.262 7.724 11.988 14.37 9.054L84.111 243.9a10.218 10.218 0 0 1 6.837-.501c11.675 3.21 24.1 4.92 37.052 4.92c72.106 0 128-52.818 128-124.16S200.106 0 128 0" />
                            <path fill="#fff"
                                d="m51.137 160.47l37.6-59.653c5.98-9.49 18.788-11.853 27.762-5.123l29.905 22.43a7.68 7.68 0 0 0 9.252-.027l40.388-30.652c5.39-4.091 12.428 2.36 8.82 8.085l-37.6 59.654c-5.981 9.489-18.79 11.852-27.763 5.122l-29.906-22.43a7.68 7.68 0 0 0-9.25.027l-40.39 30.652c-5.39 4.09-12.427-2.36-8.818-8.085" />
                        </svg>


                    </a>
                </div>
                <div>
                    <a  href="https://t.me/masteritsystems_saleconsultant" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg"
                            class="h-[44px] w-[44px] md:w-[50px] md:h-[50px] xl:w-[60px] xl:h-[60px] lg:w-[50px] lg:h-[50px]"
                            viewBox="0 0 256 256">
                            <defs>
                                <linearGradient id="logosTelegram0" x1="50%" x2="50%" y1="0%" y2="100%">
                                    <stop offset="0%" stop-color="#2aabee" />
                                    <stop offset="100%" stop-color="#229ed9" />
                                </linearGradient>
                            </defs>
                            <path fill="url(#logosTelegram0)"
                                d="M128 0C94.06 0 61.48 13.494 37.5 37.49A128.038 128.038 0 0 0 0 128c0 33.934 13.5 66.514 37.5 90.51C61.48 242.506 94.06 256 128 256s66.52-13.494 90.5-37.49c24-23.996 37.5-56.576 37.5-90.51c0-33.934-13.5-66.514-37.5-90.51C194.52 13.494 161.94 0 128 0" />
                            <path fill="#fff"
                                d="M57.94 126.648c37.32-16.256 62.2-26.974 74.64-32.152c35.56-14.786 42.94-17.354 47.76-17.441c1.06-.017 3.42.245 4.96 1.49c1.28 1.05 1.64 2.47 1.82 3.467c.16.996.38 3.266.2 5.038c-1.92 20.24-10.26 69.356-14.5 92.026c-1.78 9.592-5.32 12.808-8.74 13.122c-7.44.684-13.08-4.912-20.28-9.63c-11.26-7.386-17.62-11.982-28.56-19.188c-12.64-8.328-4.44-12.906 2.76-20.386c1.88-1.958 34.64-31.748 35.26-34.45c.08-.338.16-1.598-.6-2.262c-.74-.666-1.84-.438-2.64-.258c-1.14.256-19.12 12.152-54 35.686c-5.1 3.508-9.72 5.218-13.88 5.128c-4.56-.098-13.36-2.584-19.9-4.708c-8-2.606-14.38-3.984-13.82-8.41c.28-2.304 3.46-4.662 9.52-7.072" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style></style>