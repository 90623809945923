<template>
    <div class="xl:w-[1280px] mx-auto mt-5 xl:mt-10 px-5 my-5">
        <div>
            <div class="flex justify-center">
           

                <h1
                    class="text-center leading-8 text-primery1 text-[20px] lg:text-[24px] xl:text-[28px] md:text-[28px] inline-block font-KhmerMoul font-[500]">
                </h1>

                <div class=" xl:mt-0  text-center xl:pb-5">
                    <h1
                        class="text-center text-primery1 xl:text-3xl xl:my-0 md:text-3xl  lg:text-2xl  text-2xl  font-NotoSansKhmer">
                        ហេតុអ្វីជ្រើសរើស ម៉ាស្ទ័រអាយធី សុីស្ទឹម?

                    </h1>
                    <div class="border-b-[4px] border-primery1 w-20 lg:w-20 xl:w-40 mx-auto mt-2"></div>

                </div>
            </div>
        </div>

        <div class="mt-5 lg:mt-10">
            <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 items-center justify-center gap-3 md:gap-3 lg:gap-10">

                <div class="space-y-3 md:space-y-3 lg:space-y-10">


                    <div class=" ">
                        <div
                            class="flex gap-3 bg-white hover:scale-105 transition-all duration-300 ease-in-out cursor-pointer border border-gray-300 rounded- overflow-hidden items-center justify-start">

                            <div class="flex-shrink-0 bg-gradient-to-l shadow-xl from-[#500192]/90 to-indigo-600 p-6">
                                <img class="w-[70px] lg:w-[50px] md:w-[80px]  xl:w-[80px]  object-cover object-center transition duration-50 "
                                    src="../../src/assets/icons8-experience-100.png">
                            </div>

                            <div class="flex flex-col gap-2 py-2 p-2 ">
                                <p
                                    class="text-justify font-bold text-black/70 text-[15px] md:text-[17px] lg:text-[14px] xl:text-[17px] ">
                                    មានបទពិសោធន៍ក្នុងវិស័យបច្ចេកវិទ្យានិងអាជីវកម្មចាប់តាំងពីឆ្នាំ២០១២។</p>
                            </div>
                        </div>
                    </div>


                    <div class="">
                        <div
                            class="flex gap-3 bg-white hover:scale-105 transition-all duration-300 ease-in-out cursor-pointer border border-gray-300 rounded- overflow-hidden items-center justify-start">

                            <div class="flex-shrink-0 bg-gradient-to-l shadow-xl from-[#500192]/90 to-indigo-600 p-6">
                                <img class=" w-[70px] md:w-[80px] lg:w-[50px] xl:w-[80px]  object-cover object-center transition duration-50 "
                                    src="../../src/assets/advantage.png">
                            </div>

                            <div class="flex flex-col gap-2 py-2 p-2 ">
                                <p
                                    class="text-justify font-bold text-black/70 text-[15px]  md:text-[17px] lg:text-[14px] xl:text-[17px]">
                                    នាំមកនូវប្រព័ន្ធបច្ចេកវិទ្យាមានគុណភាពនិងមានស្តង់ដារ។</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="  ">

                    <div class="">
                        <div
                            class="flex gap-3 bg-white hover:scale-105 transition-all duration-300 ease-in-out cursor-pointer border border-gray-300 rounded- overflow-hidden items-center justify-start">

                            <div class="flex-shrink-0 bg-gradient-to-l shadow-xl from-[#500192]/90 to-indigo-600 p-6">
                                <img class="w-[70px] md:w-[80px] lg:w-[50px] xl:w-[80px]  object-cover object-center transition duration-50 "
                                    src="../../src/assets/trust.png">
                            </div>

                            <div class="flex flex-col gap-2 py-2 p-2 ">
                                <p
                                    class="text-justify font-bold text-black/70 text-[15px]  md:text-[17px] lg:text-[14px] xl:text-[17px]">
                                    ផ្ដល់ទំនុកចិត្តកំរិតខ្ពស់បំផុតដោយមានការធានា៥​យ៉ាង។</p>
                            </div>
                        </div>
                    </div>


                </div>

                <div class="space-y-3 md:space-y-3 lg:space-y-10 ">

                    <div class=" mx-auto  ">

                        <div class="">
                            <div
                                class="flex gap-3 bg-white hover:scale-105 transition-all duration-300 ease-in-out cursor-pointer border border-gray-300 rounded- overflow-hidden items-center justify-start">

                                <div
                                    class="flex-shrink-0 bg-gradient-to-l shadow-xl from-[#500192]/90 to-indigo-600 p-6">
                                    <img class=" w-[70px] md:w-[80px] lg:w-[50px] xl:w-[80px]  object-cover object-center transition duration-50 "
                                        src="../../src/assets/discuss.png">
                                </div>

                                <div class="flex flex-col gap-2 py-2 p-2 ">
                                    <p
                                        class="text-justify font-bold text-black/70 text-[15px]  md:text-[17px] lg:text-[14px] xl:text-[17px]">
                                        ផ្តល់ក្រុមការងារជំនាញជួយគាំទ្រអតិថិជនបានគ្រប់កាល:ទេស:។</p>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div class=" mx-auto  ">

                        <div class="">
                            <div
                                class="flex gap-3 bg-white hover:scale-105 transition-all duration-300 ease-in-out cursor-pointer border border-gray-300 rounded- overflow-hidden items-center justify-start">

                                <div
                                    class="flex-shrink-0 bg-gradient-to-l lg:h-[125px]  xl:h-full shadow-xl from-[#500192]/90 to-indigo-600 p-6">
                                    <img class=" w-[70px] md:w-[80px] lg:w-[50px] xl:w-[80px]  object-cover object-center transition duration-50 "
                                        src="../../src/assets/interpersonal-relationship.png">
                                </div>

                                <div class="flex flex-col gap-2 py-2 p-2 ">
                                    <p
                                        class="text-justify font-bold text-black/70 text-[15px]   md:text-[17px] lg:text-[14px] xl:text-[17px]">
                                        ផ្តល់ជូនការប្រឹក្សា ដោយឥត គិតថ្លៃជួយឲម្ចាស់អាជីវកម្មបាន
                                        ប្រព័ន្ធដែលឆ្លើយតបតម្រូវការបំផុតហើយចំណាយប្រាក់តិចបំផុត។</p>
                                </div>
                            </div>
                        </div>


                    </div>

                    
                </div>



            </div>
        </div>
    </div>
</template>

<style scoped>
@keyframes border-animation {
    0% {
        border-top-color: indigo;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }

    25% {
        border-right-color: indigo;
        opacity: 0.5;
    }

    50% {
        border-bottom-color: indigo;
        opacity: 0.5;
    }

    75% {
        border-left-color: indigo;
        opacity: 0.5;
    }

    100% {
        border-color: indigo;
        opacity: 0.5;
    }
}

.animate-border-animation:hover {
    animation: border-animation 1s linear forwards;
}
</style>
