<template>
    <div class="flex flex-col items-center justify-center w-full h-screen fixed top-0 right-0 bg-black/20 z-[20]">
        <div v-motion-slide-top class="w-full overflow-y-auto overflow-x-auto   h-screen">
            <div v-motion-slide-top
                class=" w-[90%] p-5 mx-auto md:w-[80%] lg:w-[50%] xl:w-[50%] mt-5 m-5  bg-white shadow-sm  overflow-auto ">

                <div class="w-full mx-auto flex  items-center justify-between mb-4">
                    <h1 class="text-primery1 text-heading3">
                        {{ datatoedit ? "កែប្រែឧបករណ៍ថ្មី​​" : "បង្កើតឧបករណ៍ថ្មី" }}
                    </h1>
                    <button @click="handleClose"
                        class="w-10 h-10 rounded-full focus:border-primery1 focus:border flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                            class="w-8 h-8 text-primery1">
                            <path
                                d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
                        </svg>
                    </button>
                </div>


                <div class="gap-4 overflow-x-auto w-full">
                    <form @submit.prevent="handleSubmit" class="">
                        <div class="w-full flex flex-wrap md:flex-nowrap gap-2">
                            <div class="w-full space-y-2">
                                <label class="text-body text-textbody"> ឈ្មោះឧបករណ៍:* </label>
                                <input required v-model="productName" type="text"
                                    class="border p-2 w-full hover:border-primery1" placeholder="ឈ្មោះឧបករណ៍" />
                            </div>
                            <div class="w-full">
                                <div class="w-full space-y-2">
                                    <label class="text-body text-textbody">
                                        ប្រភេទឧបករណ៍
                                    </label>
                                    <div class="">
                                        <!-- 
                                        <input v-model="category_pro" class=""
                                            placeholder="ប្រភេទឧបករណ៍" type="text" /> -->

                                        <select required v-model="category_pro" class="border p-2 w-full hover:border-primery1">
                                            <option value="">--Select Category--</option>
                                            <option v-for="hardCat in hardwareCat" :value="hardCat.id"
                                                :key="hardCat.id">{{ hardCat.name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="w-full flex flex-wrap md:flex-nowrap gap-2 mt-3">
                            <div class="w-full space-y-2">
                                <label class="text-body text-textbody"> ម៉ាកយីហោ </label>

                                <input class="border p-2 w-full hover:border-primery1" v-model="brand"
                                    placeholder="ម៉ាកយីហោ" type="text" />

                            </div>


                            <div class="w-full">
                                <div class="w-full space-y-2">

                                    <label class="text-body text-textbody"> ចំនួន </label>
                                    <input v-model="qty" type="text" class="border p-2 w-full hover:border-primery1"
                                        placeholder="ចំនួន" />
                                </div>
                            </div>
                            <div class="w-full">
                                <div class="w-full space-y-2">

                                    <label class="text-body text-textbody"> តម្លៃឧបករណ៍ </label>
                                    <input v-model="price" type="text" class="border p-2 w-full hover:border-primery1"
                                        placeholder="តម្លៃផលិតផល" />
                                </div>
                            </div>
                        </div>

                        <div class="space-y-3">
                            <div class="w-full mt-3 space-y-2">

                                <label class="text-body text-textbody"> លម្អិតពីផលិតផល </label>
                                <!-- <textarea type="text" v-model="productDesscript" placeholder="លម្អិតពីផលិតផល"
                                    class="border p-2 w-full hover:border-primery1" cols="10" rows="3"></textarea> -->
                                <div id="app">
                                    <ckeditor :editor="editor" v-model="productDesscript" :config="editorConfig" />
                                </div>
                            </div>



                            <div class="space-y-2">
                                <label class="text-body text-textbody"> រូបភាពផលិតផល </label>

                                <div class="hover:border-primery1 border w-[200px]  border-dashed relative">
                                    <div v-if="!urls" class="text-center text-lebeltext text-heading4  cursor-pointer">
                                        <img src="https://fakeimg.pl/200x200/" alt="">
                                    </div>
                                    <div v-else>
                                        <img :src="urls" :key="urls" alt="Product Image"
                                            class="h-full w-full object-cover" />
                                    </div>
                                    <input required accept=".jpg, .jpeg, .png, .gif" @change="handleChangeImage" type="file"
                                        class="opacity-0 absolute w-full  z-50 top-0 cursor-pointer" />

                                </div>
                                <input type="hidden" v-model="urls" class="border">
                                <!-- <img :src="urls" alt="Product Image" class="h-24" /> -->
                            </div>

                            <div v-if="!isLoading" class="w-auto flex justify-end mx-auto">
                                <button
                                    class="px-10 p-3 bg-primery1 transition-all duration-300 ease-in-out hover:bg-blue-500 text-white mt-2">
                                    {{ datatoedit ? "កែប្រែ" : "បន្ថែមថ្មី" }}
                                </button>
                            </div>
                            <div v-else class="w-auto flex justify-end mx-auto">
                                <button class="px-5 p-3 bg-primery1 items-center  flex  text-white">
                                    <svg aria-hidden="true" role="status"
                                        class="inline w-6 h-6 me-3 text-white animate-spin" viewBox="0 0 100 101"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="#E5E7EB" />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentColor" />
                                    </svg>
                                    កំពុងបញ្ចូល
                                </button>

                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useCollection from "@/composible/useCollection";
import useStorage from "@/composible/useStorange";
import { push } from "notivue";
import { ref, onMounted, watch } from "vue";
import { timestamp } from "@/firebase/config";
import { getCollectionQuery } from "@/composible/getCollection";
import { getDownloadURL, getStorage, ref as itemsRef, uploadBytes } from 'firebase/storage';
import getCollection from "@/composible/getCollections";
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import useDocument from "@/composible/useDocument";

export default {
    emit: ["AddSusccesfully", "UpddateSuccess"],
    components: {
        ckeditor: CKEditor.component
    },
    props: ["catDocument","datatoedit", "loadHardware"],
    setup(props, { emit }) {
        const category = ref([]);
        const itemsType = ref("");
        const editor = ClassicEditor;
        // const hardwareCategory = ref([])

        const cateHardware = ref("")


        const getDataCategory = async () => {
            try {
                await getCollectionQuery(
                    "categories",
                    [],
                    (data) => {
                        category.value = data;
                    },
                    true
                );
            } catch (error) {
                console.error(error.message);
            }
        };

        const ispending = ref(false);
        const { uploadImage } = useStorage();
        const productName = ref("")
        const category_pro = ref("")
        const brand = ref("")
        const qty = ref("")
        const price = ref("")
        const productDesscript = ref("");
        const selectedFile = ref("")




        const productImage = ref(null);
        const productFeatures = ref([]);
        const productFeaturesTitle = ref("");
        const productFeatueDetails = ref("");
        const img = ref(null);
        const isLoading = ref(false)


        const urls = ref(null)
        // const { addDocs, updateDocs } = useCollection("newProduct");
        const { documents: hardwareCat } = getCollection('hardwareCategories')



        watch(category_pro, (cate) => {
            cateHardware.value = cate
        })

        const handleChangeImage = (e) => {
            try {
                const file = e.target.files[0];
                urls.value = URL.createObjectURL(file)
                selectedFile.value = file
                if (!file) {
                    console.error("No file selected.");
                    return;
                }
                const allowedExtensions = ["jpg", "png", "svg", "jpeg"];
                const extension = file.name.split(".").pop().toLowerCase();

                if (!allowedExtensions.includes(extension)) {
                    console.error("Only jpg, png, svg, and jpeg files are allowed.");
                    return;
                }
                img.value = file;
            }
            catch (err) {
                console.log(err)
            }
        }


        const productDisplay = ref("");
        const productDisplayStanding = ref(["outstanding", "normal"]);



        const handleFileChangeProductImage = (event) => {
            const file = event.target.files[0];
            if (!file) {
                console.error("No file selected.");
                return;
            }
            const allowedExtensions = ["jpg", "png", "svg", "jpeg"];
            const extension = file.name.split(".").pop().toLowerCase();

            if (!allowedExtensions.includes(extension)) {
                console.error("Only jpg, png, svg, and jpeg files are allowed.");
                return;
            }
            productImage.value = file;
        };

        const loading = ref(false);

        const handleSubmit = async () => {
            const { addDocs, updateDocs } = useDocument('hardwareCategories', cateHardware.value, 'hardware')

            
            try {
                const file = selectedFile.value;

                // Check if no file is selected
                if (!file || (selectedFile.value && selectedFile.value.length === 0)) {
                    const data = {
                        productName: productName.value,
                        category: category_pro.value,
                        brand: brand.value,
                        qty: qty.value,
                        price: price.value,
                        productDesscript: productDesscript.value,
                        url: urls.value,
                        createdAt: timestamp()
                    };
                    await updateDocs(props.datatoedit.id, data)
                    handleClose();
                } else {
                    isLoading.value = true;

                    // Upload file and get URL
                    const storage = getStorage();
                    const storageRef = itemsRef(storage, "newProduct/" + file.name);
                    await uploadBytes(storageRef, file);
                    const url = await getDownloadURL(storageRef);

                    // Prepare data object
                    const data = {
                        productName: productName.value,
                        category: category_pro.value,
                        brand: brand.value,
                        qty: qty.value,
                        price: price.value,
                        productDesscript: productDesscript.value,
                        url: url,
                        createdAt: timestamp()
                    };

                    if (props?.datatoedit) {
                        isLoading.value = false;
                        await updateDocs(props.datatoedit.id, data);
                        props.loadHardware();
                        push.success("បានកែប្រែដោយជោគជ័យ");
                 
                        handleClose();
                    } else {
                        isLoading.value = false;
                        await addDocs(data);
                        props.loadHardware();
                        push.success("បានបញ្ជូលដោយជោគជ័យ");
                        handleClose();
                    }



                }
            } catch (err) {
                isLoading.value = false;
                console.log(err);
            }
        };

        const handleAddFeature = async () => {
            try {
                let imageURL = null;

                if (img.value && img.value !== props.datatoedit?.image) {
                    // Check image size
                    if (img.value.size > 1024 * 1024) {
                        console.error("Image size exceeds 1MB limit.");
                        return;
                    }

                    // Upload image
                    const storagePath = `feature/${img.value.name}`;
                    imageURL = await uploadImage(storagePath, img.value);
                } else {
                    // If img.value hasn't changed or is not provided, retain the existing image URL
                    imageURL = props.datatoedit?.image;
                }
                const newFeature = {
                    title: productFeaturesTitle.value,
                    details: productFeatueDetails.value,
                    image: imageURL,
                };
                productFeatures.value.push(newFeature);
                productFeaturesTitle.value = "";
                productFeatueDetails.value = "";
                img.value = "";
                console.log(newFeature);
            } catch (error) {
                console.error("Error adding feature:", error);
            }
        };

        const editFeature = (index, item) => {
            // Check if the provided index is valid
            if (index >= 0 && index < productFeatures.value.length) {
                // Store the old image
                const oldImage = productFeatures.value[index].image;

                // Remove the item at the specified index
                productFeatures.value.splice(index, 1);

                // Update the text values with the properties of the item being edited
                productFeaturesTitle.value = item.title;
                productFeatueDetails.value = item.details;

                // Restore the old image if it exists
                if (oldImage !== null && oldImage !== undefined) {
                    img.value = oldImage;
                }
            }
        };



        onMounted(() => {
            getDataCategory();
        });
        onMounted(() => {

            if (props.datatoedit) {
                productName.value = props.datatoedit.productName;
                category_pro.value = props.datatoedit.category;
                brand.value = props.datatoedit.brand;
                qty.value = props.datatoedit.qty;
                productDesscript.value = props.datatoedit.productDesscript;
                price.value = props.datatoedit.price;


                urls.value = props.datatoedit.url;
            }
        });
        const currentComponent = ref("");
        const handleClose = () => {
            currentComponent.value = "";
            emit("close");
        };

        const handleRemoveFeature = async (index, productId) => {
            productFeatures.value.splice(index, 1);

            try {
                await updateDocs("products", productId, {
                    productFeatures: productFeatures.value,
                });

                console.log("Feature removed successfully from Firebase");
            } catch (error) {
                console.error("Error removing feature from Firebase:", error);
            }
        };

        return {
            handleClose,
            handleAddFeature,

            productFeatueDetails,
            productFeaturesTitle,
            productFeatures,
            productDisplay,
            productName,
            img,
            itemsType,
            handleSubmit,
            currentComponent,
            productDisplayStanding,
            handleRemoveFeature,
            editFeature,
            push,
            productDesscript,
            ispending,
            handleFileChangeProductImage,
            productImage,
            loading,
            category,
            urls,
            handleChangeImage,
            brand,
            qty,
            price,
            category_pro,
            isLoading,
            hardwareCat,
            editor,
            cateHardware
        };
    },
};
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #510192b9;
    border-radius: 10px;
    margin-left: 20px;
    transition: 3s;
}

::-webkit-scrollbar-thumb:hover {
    display: block;
    transition: 0.5s;
}

table,
tr,
th,
td {
    border: 1px solid lightgray;
    border-collapse: collapse;


}
</style>