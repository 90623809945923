<template>
    <div class="flex flex-col h-screen ">
        <!-- Navbar (always visible on all screen sizes) -->
        <nav class="flex items-center justify-between p-4 text-black border print:hidden ">
            <div class="text-xl font-koulen font-[500] text-red-600 flex items-center gap-1">
                <img src="" class="w-10" alt="">
                <p class="text-primery1">ម៉ាស្ទ័រអាយធីសុីស្ទឹម</p>
            </div>
            <div class="hidden space-x-6 lg:flex">
                <CurrentDate />

            </div>
            <div class="flex items-center gap-2">
                <div class="flex gap-1">
                    <p class=" font-koulen">ប្រវត្តិរូប៖</p>
                    <p class="font-bold font-koulen">{{ user?.displayName }}</p>
                </div>
                <div class="p-4 bg-gray-100 rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="lucide lucide-user">
                        <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
                        <circle cx="12" cy="7" r="4" />
                    </svg>
                </div>
            </div>
        </nav>




        <!-- Main content area -->
        <div class="flex flex-1 ">
            <!-- Sidebar (hidden on small screens) -->
            <div
                class="w-16 p-4 text-black transition-all duration-300 ease-in-out bg-white border-b border-r lg:w-64 print:hidden">
                <ul class="space-y-1">

                    <div v-if="userDocument?.role === 'admin' || userDocument?.role === 'SuperAdmin'">
                        <li class="block">
                            <RouterLink :to="{ name: 'services' }"
                                class="flex items-center gap-3 px-4 py-2 rounded hover:bg-gray-200 '">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="size-5">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                </svg>
                                <span class="font-bold font-NotoSansKhmer">ប្រភេទសេវាកម្ម</span>
                            </RouterLink>
                        </li>

                        <li class="block">
                            <RouterLink :to="{ name: 'banner' }"
                                class="flex items-center gap-3 px-4 py-2 rounded hover:bg-gray-200 '">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="size-5">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                </svg>
                                <span class="font-bold font-NotoSansKhmer">ផ្ទាំងបង្ហាញ</span>
                            </RouterLink>
                        </li>

                        <li class="block">
                            <RouterLink :to="{ name: 'hardwareCategory' }"
                                class="flex items-center gap-3 px-4 py-2 rounded hover:bg-gray-200 '">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="size-5">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                </svg>
                                <span class="font-bold font-NotoSansKhmer">ប្រភេទឧបករណ៍</span>
                            </RouterLink>
                        </li>


                        <li class="block">
                            <RouterLink :to="{ name: 'products' }"
                                class="flex items-center gap-3 px-4 py-2 rounded hover:bg-gray-200 '">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="size-5">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                </svg>
                                <span class="font-bold font-NotoSansKhmer">ផលិតផល</span>
                            </RouterLink>
                        </li>






                        <li class="block">
                            <RouterLink :to="{ name: 'listproduct' }"
                                class="flex items-center gap-3 px-4 py-2 rounded hover:bg-gray-200 '">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="size-5">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                </svg>
                                <span class="font-bold font-NotoSansKhmer">ឧបករណ៍</span>
                            </RouterLink>
                        </li>








                        <li class="block">
                            <RouterLink :to="{ name: 'qanda' }"
                                class="flex items-center gap-3 px-4 py-2 rounded hover:bg-gray-200 '">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="size-5">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                </svg>
                                <span class="font-bold font-NotoSansKhmer">សំណួរខ្លី</span>
                            </RouterLink>
                        </li>


                        <li class="block">
                            <RouterLink :to="{ name: 'partner' }"
                                class="flex items-center gap-3 px-4 py-2 rounded hover:bg-gray-200 '">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="size-5">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                </svg>
                                <span class="font-bold font-NotoSansKhmer">ដៃគូសហការណ៍</span>
                            </RouterLink>
                        </li>

                        <li class="block">
                            <RouterLink :to="{ name: 'inbox' }"
                                class="flex items-center gap-3 px-4 py-2 rounded hover:bg-gray-200 '">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="size-5">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                </svg>
                                <span class="font-bold font-NotoSansKhmer">ប្រអប់សារ</span>
                            </RouterLink>
                        </li>

                        <li v-if="userDocument?.role === 'SuperAdmin'" class="block">
                            <RouterLink :to="{ name: 'adminmanager' }"
                                class="flex items-center gap-3 px-4 py-2 rounded hover:bg-gray-200 '">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="size-5">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                </svg>
                                <span class="font-bold font-NotoSansKhmer">អ្នកប្រើប្រាស់</span>
                            </RouterLink>
                        </li>

          
                        <RouterLink :to="{ name: 'request-quote' }"
                            class="flex items-center gap-3 px-4 py-2 rounded hover:bg-gray-200 '">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="size-5">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                            </svg>
                            <span  class="font-bold font-NotoSansKhmer">ស្នើរសុំសម្រង់ <span  v-if="filterCount"
                                    class="px-3 py-2 text-sm text-white bg-red-500 rounded-full">{{
                                    filterCount}}</span></span>
                        </RouterLink>
                

                    </div>

                    <li v-if="userDocument?.role === 'Sale'" class="block">
                        <RouterLink :to="{ name: 'media-video' }"
                            class="flex items-center gap-3 px-4 py-2 rounded hover:bg-gray-200 '">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="size-5">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                            </svg>
                            <span class="font-bold font-NotoSansKhmer">បញ្ចូលវីដែអូ</span>
                        </RouterLink>
                    </li>

                    <li v-if="userDocument?.role === 'Sale'" class="block">
                        <RouterLink :to="{ name: 'request-quote' }"
                            class="flex items-center gap-3 px-4 py-2 rounded hover:bg-gray-200 '">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="size-5">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                            </svg>
                            <span  class="font-bold font-NotoSansKhmer">ស្នើរសុំសម្រង់ <span  v-if="filterCount"
                                    class="px-3 py-2 text-sm text-white bg-red-500 rounded-full">{{
                                    filterCount}}</span></span>
                        </RouterLink>
                    </li>


                </ul>
            </div>

            <!-- Content -->
            <div class="flex-1 p-6 bg-gray-200/60">
                <router-view />
            </div>
        </div>





    </div>


    <div @click="handleSignOut" class="fixed bottom-4 right-4">
        <button class="px-4 py-2 font-bold text-white bg-red-500 rounded-full shadow-lg hover:bg-red-600">
            ចាកចេញ
        </button>
    </div>
</template>


<script>
import { ref } from 'vue';
import getUser from '@/composible/getUser';
import { projectAuth, projectFirestore } from '@/firebase/config';
import { useRouter } from 'vue-router';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from '@firebase/firestore';
import { onMounted } from 'vue';

import getCollection from '@/composible/getCollections';
import { computed } from 'vue';

export default {
    setup() {

        const userDocument = ref(null);

        const { user } = getUser();
        const router = useRouter();

        const { documents: request_qoute } = getCollection('Request-Quote');
        // const pendingCount = request_qoute.filter(doc => doc.status === 'pending').length;

        const filterCount = computed(() => {
            return request_qoute.value?.filter(
                (b) => b.status === "pending"
            ).length;
        });

        onMounted(() => {
            fetchUserData();
        })

        const handleSignOut = async () => {
            // Handle sign out logic here
            if (window.confirm('តើអ្នកចង់ចាកចេញមែនទេ?')) {
                try {
                    await projectAuth.signOut();
                    router.push({ name: 'login' });

                } catch (error) {
                    console.error('Error signing out:', error);
                }
            }
        };


        const fetchUserData = async () => {
            try {
                const userlogin = getAuth().currentUser;
                if (userlogin) {
                    if (!userDocument.value) {
                        const docRef = doc(projectFirestore, "users", userlogin.uid);
                        const docSnap = await getDoc(docRef);
                        if (docSnap.exists()) {
                            userDocument.value = docSnap.data();
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching user data:", error.message);
            }
        };



        return {

            user,
            handleSignOut,
            fetchUserData,
            userDocument,
            filterCount

        };
    }
};
</script>
