<template>
    <div class="bg-gray-50/60 p-[20px] md:p-5 xl:py-5 mt-6 lg:mt-10 xl:mt-0  ">
        <div class="md:w-[100%] lg:w-[90%] xl:w-[1280px] mx-auto  xl:py-2 xl:p-4 px-4">

            <div class="xl:space-y-[1px] flex justify-center">
                <!-- Content -->
                <div class="">
                    <div class="pb-5 text-center xl:mt-0">
                        <h1
                            class="mt-5 text-2xl text-center text-primery1 xl:text-3xl xl:my-0 md:text-3xl lg:text-2xl font-NotoSansKhmer">
                            ប្រព័ន្ធគ្រប់គ្រងអាជីវកម្មពេញនិយម
                        </h1>
                        <div class="border-b-[4px] border-primery1 w-16 lg:w-16 xl:w-20 mx-auto mt-2"></div>
                    </div>
                    <div class="flex justify-center ">
                        <p
                            class="px-1 text-justify  text-textbody/70 md:p-0 md:text-center font-[400] md:text-[20px] lg:text-body  xl:text-[20px]   font-NotoSansKhmer lg:w-[95%] mx-auto xl:w-[60%] w-full">
                            ដោះស្រាយបញ្ហាស្មុគស្មាញ ការលំបាក និងភាពខ្ចះខ្ចាយ ទាំងឡាយ
                            នៅក្នុងដំណើរការនៃគ្រប់គ្រងអាជីវកម្មរបស់លោកអ្នក
                            ជាមួយប្រព័ន្ធគ្រប់គ្រងអាជីវកម្មជាច្រើនប្រភេទកំពុងពេញនិយម ទំនើប ទាន់សម័យ ងាយស្រួលប្រើប្រាស់
                            គ្រប់គ្រងច្បាស់លាស់ ជួយឱ្យលោកអ្នកគ្រប់គ្រងអាជីវកម្មកាន់តែស្រួល និងរីកចម្រើនកាន់តែលឿន។
                        </p>
                    </div>
                </div>
            </div>


            <div
                class="grid grid-cols-1 md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 lg:gap-[24px] gap-[30px] md:gap-[15px] xl:grid-cols-3 xl:gap-[30px] py-5 mt-2">
                <div v-for="buss in filteredBusiness" :key="buss">
                    <div
                        class="bg-white  hover:-translate-y-4 transition-all duration-300 ease-in-out border-[1px] h-full   md:h-full   xl:h-full flex flex-col justify-between">

                        <div class="gap-5 mt-2 xl:flex xl:space-y-0 lg:space-y-4 xl:mt-0 ">

                            <div>
                                <div class="flex justify-center -mt-2 xl:mt-0">
                                    <img :src="buss.image"
                                        class="w-[400px] h-[250px]   md:w-[420px] md:h-[300px] lg:w-[350px] lg:h-[250px] xl:w-[400px] xl:h-[300px]"
                                        alt="">

                                </div>
                                <div class="p-4 space-y-2">
                                    <h4
                                        class="text-[18px] mt-3 xl:mt-0 font-poppins text-black/70 font-bold md:text-[16px] xl:text-[18px] lg:text-[18px] line-clamp-2">
                                        {{ buss.name }}</h4>
                                    <div v-html="buss.descritpts"
                                        class="text-[15px] mt-2 text-justify  md:text-[15px] line-clamp-4  xl:text-[16px] sm:line-clamp-4 text-[#909090] xl:mt-3 md:line-clamp-3 lg:line-clamp-4 xl:line-clamp-3 lg:text-[16px] ">
                                    </div>

                                    <div class="flex justify-end mt-3 xl:justify-end">

                                        <router-link :to="{ name: 'systemdetails', params: { id: buss.id } }"
                                            class="text-primery1/90 text-[17px] hover:text-indigo-600  md:mt-[5px] flex items-center gap-1 rounded-md  md:text-[14px] lg:text-[16px] xl:text-[18px] font-NotoSansKhmer font-bold grow_skew_forward relative z-10  text-2xl py-1 lg:py-1.5 px-8 mt-1   hover:border-blue-500">
                                            <p>មើលបន្ថែម</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke-width="1.5" stroke="currentColor" class="size-6">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                                            </svg>
                                        </router-link>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { watch, ref } from 'vue'
import { orderBy, where } from '@firebase/firestore'
import getCollection from "@/composible/getCollections.js";
import { computed } from 'vue';

export default {
    setup() {

        const bussiness = ref(null);

        const { documents } = getCollection('products', where("cid", "==", "ra1yTG7WvRtntQ9utxru", orderBy("createdAt", "asc")))

        watch(documents, () => {
            bussiness.value = documents.value || null
        })


        // Filter out the specific business
        const filteredBusiness = computed(() => {
            return bussiness.value?.filter(
                b => b.name !== 'ប្រព័ន្ធគ្រប់គ្រងបន្ទប់ជួល និងតូបជួល'
            ) || [];
        });

        return { bussiness, filteredBusiness }
    }
}
</script>

<style></style>