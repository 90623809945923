<template>
    <div class="select-none hidden lg:block text_navbar">
        <div class="grid grid-cols-2 p-5">
            <div
                class="space-y-3 flex flex-col lg:text-[14px] xl:text-[17px] bg-white xl:w-[300px] h-[200px] border-l border-t border-b border-r p-4">
                <div class="space-y-3 flex flex-col lg:text-[14px] xl:text-[17px] mt-2">
                    <router-link to="/aboutus"
                        class="cursor-pointer font-NotoSansKhmer hover:text-primery1 flex gap-2 font-[500]  lg:text-[16px] xl:text-[18px] items-center justify-between">អំពីពួកយើង</router-link>
                    <router-link to="/aboutus#history"
                        class="cursor-pointer font-NotoSansKhmer hover:text-primery1 flex gap-2 font-[500] lg:text-[16px] xl:text-[18px] items-center justify-between">
                        បុព្វហេតុ និង​ ប្រវត្តិនៃកាបង្កើត
                    </router-link>

                    <router-link to="/visionMissionValue"
                        class="cursor-pointer font-NotoSansKhmer hover:text-primery1 flex gap-2 font-[500]  lg:text-[16px] xl:text-[18px] items-center justify-between">ចក្ខុវិស័យ-បេសកម្ម-គុណតម្លៃ</router-link>
                    <router-link to="/actionTeamwork"
                        class="cursor-pointer font-NotoSansKhmer hover:text-primery1 flex gap-2 font-[500]  lg:text-[16px] xl:text-[18px] items-center justify-between">សកម្មភាពក្រុមការងារ</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

</script>