<template>
    <div>
  
    </div>
    <div v-motion :initial="{
      opacity: 0,
    }" :enter="{
  
      opacity: 1,
      transition: {
        duration: 500,
  
        ease: 'easeIn',
      },
    }">
  
      <div class="bg-gradient-to-r from-[#500192] to-indigo-400/70">
        <div v-for="pro in product" :key="pro.id"
          class="w-[100%] px-2  md:w-[97%] xl:w-[1280px] lg:w-[90%] mx-auto md:space-y-0 lg:space-y-4 xl:px-4 py-5 lg:py-10">
          <div
            class="lg:mt-[0px] xl:mt-[0px]  mt-0 text-start lg:p-0 xl:p-0 p-3 grid-cols-1 md:grid-cols-2 grid  lg:gap-10 xl:gap-5 md:px-5 lg:px-0">
            <div>
              <p class="font-bold text-[14px] md:text-[18px] lg:text-[16px] xl:text-[18px] text-white mt-5">Master-IT
                System
              </p>
              <div
                class="font-NotoSansKhmer font-bold lg:ml-0  text-white xl:text-heading2 lg:text-heading2 md:text-[20px] text-[26px]  xl:text-start lg:text-start md:text-start">
                {{ pro.name }}
              </div>
              <div class="my-2  md:px-0">
                <span
                  class="prose text-white text-justify  lg:-mt-0 xl:text-body lg:text-[16px] md:text-[16px] text-[18px]">
                  ដោះស្រាយល្អបំផុតសម្រាប់អាជីវកម្ម។
                  <br>
                  មុខងារ​សម្បូរបែប ទំនើប​ ងាយស្រួលប្រើ គ្រប់គ្រងច្បាស់។
                </span>
              </div>
              <a href="https://t.me/masteritsystem_saleconsultant">
                <button
                  class="btn-hover  mb-5 md:mb-0 rounded-md text-[14px] md:mt-[10px] md:text-[14px] lg:text-[16px] xl:text-[18px] font-NotoSansKhmer font-bold grow_skew_forward relative z-10 text-white text-2xl py-1 px-4  mt-4 overflow-hidden bg-gradient-to-r from-purple-600 to-indigo-700 shadow-xl">
                  ប្រឹក្សាឥឡូវនេះ
                </button>
  
              </a>
            </div>
            <div
              class="flex justify-center mt-10 md:mt-0 px-4 md:px-0 w-full h-[280px] md:w-[370px] md:h-[300px] lg:w-[450px] lg:h-[400px] xl:w-[500px] xl:h-[400px]">
              <iframe v-if="pro.videoLink && getYouTubeEmbedLink(pro.videoLink)" :src="getYouTubeEmbedLink(pro.videoLink)"
                frameborder="0" allow="autoplay; encrypted-media" allowfullscreen class="w-full h-full aspect-video">
              </iframe>
  
              <video v-else-if="pro.videoLink && /\.(mp4|webm|ogg|m4v)$/i.test(pro.videoLink)" :src="pro.videoLink" muted
                autoplay controls class="w-full h-full object-contain">
              </video>
  
              <img v-else
                class="w-[400px] h-[250px] md:w-[900px]  md:h-[300px] lg:w-[500px] lg:h-[350px] xl:w-[500px] xl:h-300px] mx-auto md:mb-10"
                :src="pro.image" />
  
              <!-- <img
                  class="w-[400px] h-[250px] md:w-[900px]  md:h-[300px] lg:w-[500px] lg:h-[350px] xl:w-[500px] xl:h-300px] mx-auto md:mb-10"
                  src="https://fakeimg.pl/600x400/" /> -->
            </div>
          </div>
        </div>
      </div>
  
      <!-- select 2 -->
      <div class="flex flex-col py-4">
        <div v-for="pro in product" :key="pro.id">
          <div class="w-[100%] px-2  md:w-[97%] xl:w-[1100px] lg:w-[96%] mx-auto md:space-y-0 lg:space-y-4 xl:px-4">
  
  
            <div class="md:mx-2 xl:mx-1 ">
  
              <div class="my-5 px-5 md:px-0">
                <span v-html="pro.descritpts"
                  class="prose text-textbody text-justify  lg:mt-0 xl:text-[20px] lg:text-[16px] md:text-[16px] text-[16px] ">
                </span>
  
                <div v-if="pro.bannerImage === null">
                  <img
                    class="w-[400px] h-[250px] sm:w-[700px] sm:h-[300px] md:w-[900px]  md:h-[400px] lg:w-[700px] lg:h-[350px] xl:w-[800px] xl:h-[450px] mx-auto mt-5 sm:mt-8"
                    src="https://fakeimg.pl/800x350/" />
                </div>
                <div v-else>
                  <img
                    class="w-full  h-[240px] md:w-[900px]   md:h-[400px] lg:w-[700px] lg:h-[330px] xl:w-[800px] xl:h-[350px] mx-auto border mt-4"
                    :src="pro.bannerImage" />
                </div>
              </div>
  
              <div v-for="(productfeatures, index) in pro.feature" :key="index"
                class="w-full max-w-[1420px] py-4 lg:p-0 xl:p-0 p-3 md:p-3 space-y-4 xl:mt-[48px] xl:block  lg:block hidden">
                <!-- details1 -->
  
                <div :class="{
                  'w-full lg:flex lg:flex-row  xl:flex xl:flex-row md:flex md:flex-row  flex flex-col-reverse items-start justify-center  space-y-4 xl:gap-12 lg:gap-12 md:gap-12 gap-5 py-3':
                    index % 2 === 0,
                  'w-full lg:flex lg:flex-row-reverse xl:flex-row-reverse xl:flex md:flex md:flex-row flex flex-col-reverse items-start justify-center space-y-4 xl:gap-12 lg:gap-12 md:gap-12 gap-5 py-3':
                    index % 2 !== 0,
                }">
                  <div class="lg:w-1/2 xl:w-full md:w-[95%] md:mx-auto w-full mt-[18px]">
                    <h2 v-html="productfeatures.title"
                      class="text-textbody prose font-NotoSansKhmer text-heading3 font-semibold xl:text-start lg:text-start md:text-start text-center">
  
                    </h2>
                    <hr class="my-4">
                    <div class="">
                      <p v-html="productfeatures.details"
                        class="text-textbody lg:text-body md:text-body text-body_Mobile  text-start xl:text-[17px]">
                      </p>
                    </div>
                  </div>
                  <div class="lg:w-1/2 xl:w-full md:w-[95%] w-full md:mx-auto">
                    <!-- <img class="w-full rounded-[6px]" :src="productfeatures.image" /> -->
  
                    <img
                      class="w-[400px] h-[250px] md:w-[900px]  md:h-[400px] lg:w-[500px] lg:h-[350px] xl:w-[600px] xl:h-[350px]"
                      :src="productfeatures.image" />
                    <!-- <img
                      class="w-[400px] h-[250px] md:w-[900px]  md:h-[400px] lg:w-[500px] lg:h-[350px] xl:w-[600px] xl:h-[350px]"
                      src="https://fakeimg.pl/600x350/" /> -->
  
                  </div>
                </div>
              </div>
  
              <!-- for mobile -->
  
              <div v-for="(productfeatures, index) in pro.feature" :key="index" class="w-full lg:hidden  block xl:hidden">
                <!-- details1 -->
                <div class="w-[90%] mx-auto mt-4">
                  <div class="w-full">
                    <h2 v-html="productfeatures.title"
                      class="text-textbody font-NotoSansKhmer text-heading3 font-semibold text-start xl:text-center">
  
                    </h2>
                    <div>
                      <p v-html="productfeatures.details" class="text-textbody text-body_Mobile text-start mt-2 prose">
  
                      </p>
                    </div>
                  </div>
                  <div class="w-full mt-8">
                    <!-- <img class="w-full rounded-[6px]" :src="productfeatures.image" /> -->
  
                    <img
                      class="w-[400px] h-[250px] md:w-[500px]  md:h-[300px] lg:w-[500px] lg:h-[350px] xl:w-[600px] xl:h-[400px]"
                      :src="productfeatures.image" />
                    <!-- <img
                      class="w-[400px] h-[250px] md:w-[500px]  md:h-[300px] lg:w-[500px] lg:h-[350px] xl:w-[600px] xl:h-[400px]"
                      src="https://fakeimg.pl/600x400/" /> -->
                  </div>
                </div>
              </div>
  
              <!-- slide -->
              <div class="mt-3 mx-5 sm:mx-0 relative">
  
  
                <!-- Swiper for each category -->
                <div v-for="category in productDetailsSlide" :key="category.id" class="relative select-none">
                  <div v-if="$route.params.id === category.id">
                    <h2 v-if="category.product.length > 1"
                      class="font-NotoSansKhmer lg:text-[18px] font-bold text-primery1 text-heading4 xl:text-[26px] text-center my-5 md:my-5 lg:my-10">
                      សាកសមសម្រាប់អាជីវកម្ម
                    </h2>
                    <swiper ref="mySwiper" :navigation="{
                      nextEl: '.button-next-slide',
                      prevEl: '.button-pre-slide'
                    }" :loop="false" :modules="modules" :allowTouchMove="false" :slides-per-group="1" :speed="1000"
                      :breakpoints="{
                        '540': { slidesPerView: 2, spaceBetween: 15 },
                        '768': { slidesPerView: 3, spaceBetween: 0 },
                        '1024': { slidesPerView: 3, spaceBetween: 30 }
                      }" class="mySwiper relative" @slideChange="onSlideChange" @reachEnd="onSlideReachEnd = true"
                      @reachBeginning="onSlideReachBeginning">
  
                      <swiper-slide v-for="detail in category.product" :key="detail.id" class="flex justify-center">
                        <div
                          class="border-[1px] md:w-[230px] md:h-[200px] lg:w-[300px] lg:h-[250px] xl:w-[380px] xl:h-[350px] ">
                          <img :src="detail.images" alt="" class=" w-full h-full" />
                          <!-- <img src="https://fakeimg.pl/380x300/" alt="" class="w-full h-full" /> -->
  
  
                        </div>
  
                      </swiper-slide>
                    </swiper>
  
                    <!-- Navigation buttons -->
                    <!-- Show buttons only if there are more than 1 slide -->
                    <div v-if="category.product.length > 1">
                      <!-- Previous Button -->
                      <div :class="showPrevButton ? 'block' : 'hidden'"
                        class="button-pre-slide cursor-pointer rounded-full text-black absolute top-[55%] left-0 md:left-0 lg:-left-5 z-[2]">
                        <div
                          class="flex justify-center w-10 h-10 border-indigo-500 border-[1px] items-center bg-background rounded-full hover:bg-background/90"
                          @click="prevSlide">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="size-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                          </svg>
                        </div>
                      </div>
  
                      <!-- Next Button -->
                      <div v-if="category.product.length > 3" :class="showNextButton ? 'block' : 'hidden'"
                        class="button-next-slide cursor-pointer rounded-full text-black absolute top-[55%] md:right-0 right-0 lg:-right-5 z-[2]">
                        <div
                          class="flex justify-center w-10 h-10 border-indigo-500 border-[1px] items-center bg-background rounded-full hover:bg-background/90"
                          @click="nextSlide">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="font-bold size-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <!-- end slide -->
  
  
              <!-- tab detail -->
              <div class="mt-10 lg:px-0 hidden md:block">
                <div v-for="proDetail in productDetails" :key="proDetail.id" class="lg:mx-0 xl:mx-0">
                  <div v-if="$route.params.id === proDetail.id">
                    <!-- Tabs for each product detail -->
                    <div v-if="proDetail.productdetail && proDetail.productdetail.length > 0"
                      class="tabs cursor-pointer grid grid-cols-3 bg-gray-200 h-12 font-bold font-NotoSansKhmer text-[18px] select-none ">
                      <div v-for="(detail, index) in proDetail.productdetail" :key="detail.id"
                        class="tab flex items-cente justify-center px-5 hover:bg-indigo-500 hover:text-white transition-all duration-300 ease-in-out "
                        :class="{ 'active bg-gradient-to-r from-[#500192] to-indigo-400/90 h-auto text-white font-bold font-NotoSansKhmer flex justify-center': selectedDetail === detail.id }"
                        @click="selectDetail(detail.id)">
                        <div class="flex justify-between items-center w-full">
                          <p class="md:text-[13px] lg:text-[16px] xl:text-[18px]">{{ detail.details }} </p>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="size-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                          </svg>
  
                        </div>
                      </div>
                    </div>
  
                    <!-- Display the selected detail -->
                    <div v-for="(detail, index) in proDetail.productdetail" :key="detail.id" class="my-5">
                      <div v-if="selectedDetail === detail.id">
                        <div class="text-xl font-bold  md:text-[15px] lg:text-[16px] xl:text-[20px]">{{ detail.details }}
                        </div>
                        <div>
                          <hr class="my-4 lg:my-4 xl:my-6">
                          <p v-html="detail.description" class=" xl:text-[16px]"></p>
  
                          <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-3">
                            <div v-for="feature in detail.feature" :key="feature">
                              <div>
                                <img :src="feature.image" alt="">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <!-- Fallback message for no details -->
  
                  </div>
                </div>
              </div>
  
              <!--tab mobile -->
  
              <div class="mt-5  md:hidden">
                <div v-for="proDetail in productDetails" :key="proDetail.id" class="mx-5 sm:mx-0">
                  <div v-if="$route.params.id === proDetail.id">
                    <!-- Tabs for each product detail -->
                    <div v-if="proDetail.productdetail && proDetail.productdetail.length > 0"
                      class="tabs flex flex-wrap sm:grid sm:grid-cols-3 gap-2 ">
                      <div v-for="(detail, index) in proDetail.productdetail" :key="detail.id"
                        class="tab flex px-5  bg-gray-100 w-full p-2 font-bold cursor-pointer"
                        :class="{ 'active bg-gradient-to-r from-[#500192] to-indigo-400/90 h-auto text-white font-bold font-NotoSansKhmer flex justify-start': selectedDetail === detail.id }"
                        @click="selectDetail(detail.id)">
                        <div class="flex justify-between items-center w-full">
                          <p class="sm:text-[15px]">{{ detail.details }} </p>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="size-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                          </svg>
  
                        </div>
                      </div>
                    </div>
  
                    <!-- Display the selected detail -->
                    <div v-for="(detail, index) in proDetail.productdetail" :key="detail.id" class="my-5">
                      <div v-if="selectedDetail === detail.id">
                        <div class="text-md font-bold mb-3 sm:mb-4">{{ detail.details }}</div>
                        <div>
                          <hr>
                          <p v-html="detail.description" class="prose"></p>
                          <div class="grid grid-cols-1 sm:grid-cols-2 gap-3">
                            <div v-for="feature in detail.feature" :key="feature">
                              <div>
                                <img :src="feature.image" alt="">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <!-- Fallback message for no details -->
  
                  </div>
                </div>
              </div>
  
            </div>
  
  
  
            <div class="lg:mt-[32px] xl:mt-[32px]  mt-0 text-start lg:p-0 xl:p-0 p-3 ">
              <!-- <p class="text-lg font-NotoSansKhmer font-bold text-center mt-14 mb-5">វីដេអូបង្ហាញពីប្រព័ន្ធ</p> -->
              <h2
                class="font-NotoSansKhmer lg:text-[18px]   font-bold text-primery1 text-heading4  xl:text-[26px] text-center my-10 ">
                វីដេអូបង្ហាញពី{{ pro.name }}
              </h2>
              <div class="flex justify-start">
                <iframe v-if="pro.videoLink && getYouTubeEmbedLink(pro.videoLink)" :src="getYouTubeEmbedLink(pro.videoLink)"
                frameborder="0" allow="autoplay; encrypted-media" allowfullscreen
                class="w-full h-[300px]  shadow-md rounded-md md:w-[500px] lg:w-[500px] lg:h-[300px] border-[2px] mx-auto  md:h-[300px] xl:w-[700px] xl:h-[400px] aspect-video"></iframe>
  
              <video v-else-if="pro.videoLink && /\.(mp4|webm|ogg|m4v)$/i.test(pro.videoLink)" :src="pro.videoLink"
                controls
                class="w-full h-[300px]  shadow-md rounded-md md:w-[500px] lg:w-[500px] lg:h-[300px] border-[2px] mx-auto  md:h-[300px] xl:w-[700px] xl:h-[400px]"></video>
  
              <img v-else
                class="w-[400px] h-[300px]  shadow-md rounded-md md:w-[500px] lg:w-[500px] lg:h-[300px] border-[2px] mx-auto  md:h-[300px] xl:w-[600px] xl:h-[400px]"
                src="https://fakeimg.pl/800x450/" alt="">
  
                <!-- <img
                  class="w-[400px] h-[250px] sm:w-[700px] sm:h-[300px] md:w-[900px]  md:h-[400px] lg:w-[700px] lg:h-[350px] xl:w-[800px] xl:h-[450px] mx-auto"
                  src="https://fakeimg.pl/800x450/" /> -->
  
              </div>
  
            </div>
  
            <!-- {{ productDetailsSlide }} -->
          </div>
        </div>
        <div class="w-full h-[48px]"></div>
      </div>
  
  
  
  
      <div>
        <h2
          class="font-NotoSansKhmer text-[20px] mx-5 sm:mx-0 lg:text-[18px] font-bold text-primery1   xl:text-[26px] text-center mb-6 ">
          ទំនាក់ទំនងមកពួកយើងឥឡូវនេះទទួលបានការប្រឹក្សា និងបទបង្ហាញជូនដោយឥតគិតថ្លៃ
        </h2>
        <ContactUsComponent />
      </div>
    </div>
  
    <Notivue v-slot="item">
      <Notification :item="item" />
    </Notivue>
  </template>
  
  <script>
  import { push, Notivue, Notification } from "notivue";
  
  import FooterVue from "./Footer.vue";
  import NavbarVue from "./Navbar.vue";
  import { ref, onMounted, watch } from "vue";
  
  import { doc, documentId, getDoc, where } from "firebase/firestore";
  import useCollection from "@/composible/useCollection";
  import { getCollectionQuery } from "@/composible/getCollection";
  import { timestamp } from "@/firebase/config";
  import { useRoute } from 'vue-router';
  import Navbar from "./Navbar.vue";
  import getCollection from "@/composible/getCollections";
  import ContactUsComponent from "@/components/ContactUsComponent.vue";
  import { useFirestoreCollection, useSubcollection } from '@/composible/getSubcollection';
  
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import 'swiper/css';
  import 'swiper/css/navigation';
  import { Navigation } from 'swiper/modules';
  
  export default {
    props: {
      id: String,
    },
    components: {
      FooterVue,
      NavbarVue,
      Notivue,
      Notification,
      Navbar,
      ContactUsComponent,
      Swiper,
      SwiperSlide,
  
    },
    setup(props) {
      const product = ref(null);
      const productDetails = ref('')
      const route = useRoute();
      const productDetailsSlide = ref([])
  
      const status = ref("Pending");
      const category = ref([]);
      const itemsType = ref("");
      const name = ref("");
      const companyname = ref("");
      const number = ref("");
      const numbertelete = ref("");
      const address = ref("");
      const pospurse = ref("");
      const selectedDetail = ref(null)
  
      const { addDocs } = useCollection("inbox");
  
  
      const swiperRef = ref(null);
      const showNextButton = ref(true);
      const showPrevButton = ref(false);
      const { documents: productsdetails, fetchCollection } = useFirestoreCollection("products");
  
      const fetchProduct = async () => {
        try {
          const { documents } = getCollection('products', where(documentId(), "==", route.params.id));
  
          watch(documents, () => {
            product.value = documents.value;
          })
  
        } catch (error) {
  
          console.error("Error fetching product:", error);
        }
      };
  
  
      //get subcollection
  
  
  
      const fetchProductDetail = async () => {
        // isLoading.value = true
        const orderByField = 'createdAt';
  
        try {
          // Fetch all categories and products concurrently
          const detailsPromises = productsdetails.value.map(async (pro) => {
            const { subcollectionData: product, fetchSubcollection } = useSubcollection('products', pro.id, 'details', orderByField);
  
            await fetchSubcollection();
  
            return {
              id: pro.id,
              name: pro.name,
              descritpts: pro.descritpts,
              image: pro.image,
              videoLink: pro.videoLink,
              productdetail: product.value,
            };
          });
  
          const result = await Promise.all(detailsPromises);
          // isLoading.value = false
          productDetails.value = result;
        } catch (err) {
          console.error('Error fetching categories and products:', err);
        }
      };
  
  
  
  
      const fetchProductDetailSlide = async () => {
        const orderByField = 'createdAt';
  
        try {
          const detailsPromises = productsdetails.value.map(async (pro) => {
            const { subcollectionData: product, fetchSubcollection } = useSubcollection('products', pro.id, 'productSlider', orderByField);
  
            await fetchSubcollection();
  
            return {
              id: pro.id,
              name: pro.name,
              descritpts: pro.descritpts,
              image: pro.image,
              videoLink: pro.videoLink,
              product: product.value,
            };
          });
  
          const result = await Promise.all(detailsPromises);
          productDetailsSlide.value = result;
        } catch (err) {
          console.error('Error fetching categories and products:', err);
        }
      };
  
  
  
      onMounted(async () => {
        fetchProduct();
        await fetchCollection();
        await fetchProductDetail();
        await fetchProductDetailSlide();
      });
  
      const handleSubmit = async () => {
        try {
          const productpro = {
            status: status.value,
            name: name.value,
            companyname: companyname.value,
            numbertelete: numbertelete.value,
            itemsType: itemsType.value,
            pospurse: pospurse.value,
            createdAt: timestamp(),
          };
  
          // Add 'number' field if it has a value
          if (number.value.trim() !== "") {
            productpro.number = number.value;
          }
  
          // Add 'address' field if it has a value
          if (address.value.trim() !== "") {
            productpro.address = address.value;
          }
  
          await addDocs(productpro);
          handleClear();
          push.success("អរគុណសម្រាប់ការទាក់ទង");
  
          console.log("Product operation successful");
        } catch (error) {
          console.error("Error performing product operation:", error);
        }
      };
  
      const getproCategory = async () => {
        try {
          await getCollectionQuery(
            "categories",
            [],
            (pro) => {
              category.value = pro;
            },
            true
          );
        } catch (error) {
          console.error(error.message);
        }
      };
      onMounted(() => {
        getproCategory();
      });
      const handleClear = () => {
        status.value = "";
        name.value = "";
        companyname.value = "";
        number.value = "";
        numbertelete.value = "";
        address.value = "";
        pospurse.value = "";
        itemsType.value = "";
      };
  
      const selectDetail = (itemDetail) => {
        selectedDetail.value = itemDetail
      }
  
  
  
  
      //for get video link youtube
      const getYouTubeEmbedLink = (url) => {
        const youtubeRegex = /(?:youtu\.be\/|youtube\.com\/(?:[^\/]+\/\S+\/|(?:watch\?v=)))([a-zA-Z0-9_-]+)/;
        const match = url.match(youtubeRegex);
        if (match) {
          return `https://www.youtube.com/embed/${match[1]}`;
        }
        return '';
      };
  
  
  
      //below is button slide change
  
      const onSlideChange = (swiper) => {
        showPrevButton.value = !swiper.isBeginning;
        showNextButton.value = !swiper.isEnd;
      };
  
      const prevSlide = () => {
        swiperRef.value?.slidePrev(); // Use swiperRef here
      };
  
      const nextSlide = () => {
        swiperRef.value?.slideNext(); // Use swiperRef here
      };
  
  
      return {
        product,
        itemsType,
        category,
        name,
        companyname,
        number,
        numbertelete,
        address,
        pospurse,
        status,
        handleSubmit,
        getYouTubeEmbedLink,
        productDetails,
        selectedDetail,
        selectDetail,
        productDetailsSlide,
  
        swiperRef,
        showNextButton,
        showPrevButton,
        prevSlide,
        nextSlide,
        onSlideChange,
        modules: [Navigation],
      };
    },
  };
  </script>
  <style scoped>
  ::-webkit-scrollbar {
    display: none;
  }
  
  p {
    line-height: 2;
  }
  </style>
  