<template>
    <div class=" w-full h-screen fixed top-0 right-0 bg-black/20 z-[20]">
        <div v-motion-slide-top
            class="w-[90%] md:w-[80%] lg:w-[50%] xl:w-[50%]  mx-auto p-5  bg-white mt-[4rem] md:mt-[2rem]">
            <div class="w-full mx-auto flex items-center justify-between ">
                <h1 class="text-primery1 text-heading3">
                    {{ datatoedit ? "កែប្រែផ្ទាំងបង្ហាញ" : "បន្ថែមផ្ទាំងបង្ហាញថ្មី" }}
                </h1>
                <button @click="handleClose"
                    class="w-10 h-10 rounded-full focus:border-primery1 focus:border flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                        class="w-8 h-8 text-primery1">
                        <path
                            d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
                    </svg>
                </button>
            </div>
            <form @submit.prevent="handleSubmit" class="w-full space-y-2 items-center justify-center">
                <div class="w-full flex gap-2">
                    <div class="w-full space-y-2">
                        <label class="text-body text-textbody"> ឈ្មោះផ្ទាំងបង្ហាញ:* </label>

                        <input required v-model="categoryName" type="text"
                            class="border-[1px] w-full p-2  hover:border-primery1 " placeholder="ឈ្មោះផ្ទាំងបង្ហាញ" />
                    </div>
                </div>

                <div>


                    <div class="w-full space-y-2">
                        <label class="text-body text-textbody"> វីដេអូអំពីផលិតផល:* </label>

                        <input v-model="videoLink" id="videoLink" type="text"
                            class="border p-2 w-full hover:border-primery1" placeholder="វីដេអូអំពីផលិតផល" />
                    </div>

                    <!-- Video Preview -->
                    <div v-if="isValidVideoLink" class="mt-4">
                        <h4>វីដេអូពិពណ៌នា:</h4>

                        <video v-if="isDirectVideoLink" :src="videoLink" controls class="w-full max-w-md"></video>

                        <iframe v-else :src="youtubeEmbedLink" frameborder="0" allow="autoplay; encrypted-media"
                            allowfullscreen class="w-full max-w-md aspect-video"></iframe>
                    </div>

                    <!-- Validation Message -->
                    <p v-else-if="videoLink" class="text-red-500 mt-2">
                        សូមបញ្ចូលលីងវីដេអូឲបានត្រឹមត្រូវ
                    </p>

                </div>

                <div class="w-full">
                    <div class="w-full space-y-2">
                        <label class="text-body text-textbody"> ពិពណ៌នា </label>

                        <div id="app">
                            <ckeditor :editor="editor" v-model="categoryDescription" :config="editorConfig" />
                        </div>
                    </div>

                </div>
                <div class="w-full space-y-2">
                    <label class="text-body text-textbody py-2"> រូបភាព </label>
                    <div
                        class="cursor-pointer h-[100px] w-[100px] border-[1px] hover:border-primery1 border-dashed flex items-center justify-center relative overflow-auto">
                        <h2 v-if="!img" class="text-center text-lebeltext text-heading4 overflow-auto cursor-pointer">
                            <img src="https://fakeimg.pl/400x300/" class="object-contain" alt="">
                        </h2>
                        <div v-else class="h-[200px] w-full overflow-auto overflow-x-auto">
                            <img :src="img" alt="Selected Image" class="pt-16 w-[120px] h-[140px] object-cover" />
                        </div>

                        <input @change="handleFileChange" type="file"
                            class="opacity-0 absolute h-full w-[90%] cursor-pointer" />
                    </div>
                </div>


                <div v-if="loading == false" class="w-auto flex justify-end mx-auto ">
                    <button
                        class="p-2 px-8 bg-primery1 hover:bg-indigo-700 transition-all duration-300 ease-in-out text-white mt-5">
                        {{ datatoedit ? "កែប្រែ" : "បន្ថែមថ្មី" }}
                    </button>
                </div>
                <div v-else class="w-auto flex justify-end mx-auto">
                    <button type="submit"
                        class="p-2 px-4 space-x-2 bg-primery1 flex items-center justify-center text-white mt-5">

                        <div role="status ">
                            <svg aria-hidden="true"
                                class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor" />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill" />
                            </svg>
                        </div>
                        <p> កំពុងបញ្ចូល</p>
                    </button>
                </div>
            </form>
        </div>
        <!-- {{ categoryDescription }} -->
    </div>
</template>

<script>
import useCollection from "@/composible/useCollection";
import useStorage from "../composible/useStorange";
import { ref, onMounted, computed } from "vue";
import { timestamp } from "@/firebase/config";
import { getDownloadURL, getStorage, ref as itemRef, uploadBytes } from 'firebase/storage';
import { push } from 'notivue';

import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';





export default {
    emit: ["AddSusccesfully", "UpddateSuccess"],
    components: {
        ckeditor: CKEditor.component
    },
    props: ["datatoedit"],
    setup(props, { emit }) {
        const { addDocs, updateDocs } = useCollection("banners");
        const categoryName = ref("");
        const categoryDescription = ref("");
        const img = ref(null);
        const selectedFile = ref(null)
        const loading = ref(false);

        const { uploadImage } = useStorage()

        const videoLink = ref("")

        const editor = ClassicEditor;




        onMounted(() => {
            if (props.datatoedit) {
                categoryName.value = props.datatoedit.name;
                categoryDescription.value = props.datatoedit.description;
                img.value = props.datatoedit.image;
                videoLink.value = props.datatoedit.videoLink;
            }
        });


        const sanitizeCategoryName = (name) => {
            return name.replace(/\s+/g, ""); // Remove all spaces
        };

        const handleFileChange = (event) => {
            try {


                const file = event.target.files[0];

                img.value = URL.createObjectURL(file);
                selectedFile.value = file

            } catch (err) {
                console.log(err)
            }
        };



        const handleSubmit = async () => {
            loading.value = true;
            try {
                let imageUrl = '';

                // Handle image upload or use default image
                if (selectedFile.value) {
                    if (selectedFile.value.size > 10 * 1024 * 1024) {
                        push.error("Image size should not exceed 10MB");
                        loading.value = false;
                        return;
                    }
                    const storagePath = `banners/${selectedFile.value.name}`;
                    imageUrl = await uploadImage(storagePath, selectedFile.value);
                } else {
                    imageUrl = props.datatoedit?.image || "image_null";

                }

                const sanitizedCategoryName = sanitizeCategoryName(categoryName.value);

                const productData = {
                    name: categoryName.value,
                    description: categoryDescription.value,
                    image: imageUrl,
                    videoLink: videoLink.value,
                    createdAt: timestamp(),
                    route: sanitizedCategoryName,
                };

                push.success("Save successful");
                console.log("Save Success");

                if (props.datatoedit) {
                    await updateDocs(props.datatoedit?.id, productData);
                    handleClose();
                    handleClear();
                } else {
                    const success = await addDocs(productData);
                    if (success) {
                        emit("AddSusccesfully");
                    }
                }

                handleClose()
                console.log("Product operation successful");
            } catch (error) {
                console.error("Error performing product operation:", error);
            } finally {
                loading.value = false;
            }
        };





        // Check if the link is a valid video link
        const isValidVideoLink = computed(() => {
            const videoPatterns = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/|.*\.(mp4|webm|ogg|m4v))(.*)?$/i;
            return videoPatterns.test(videoLink.value);
        });

        // Check if the link is a direct video file
        const isDirectVideoLink = computed(() => {
            const directVideoExtensions = /\.(mp4|webm|ogg|m4v)$/i;
            return directVideoExtensions.test(videoLink.value);
        });

        // Generate YouTube embed link for YouTube URLs
        const youtubeEmbedLink = computed(() => {
            if (!isValidVideoLink.value || isDirectVideoLink.value) return "";
            const youtubeRegex = /(?:youtu\.be\/|youtube\.com\/watch\?v=)([a-zA-Z0-9_-]+)/;
            const match = videoLink.value.match(youtubeRegex);
            return match ? `https://www.youtube.com/embed/${match[1]}` : "";
        });



        const handleClear = () => {
            categoryName.value = "";
            categoryDescription.value = "";
            img.value = null;
        };

        const handleClose = () => {
            emit("close");
        };

        return {
            handleClose,
            handleSubmit,
            handleFileChange,
            categoryName,
            categoryDescription,
            img,
            loading,
            editor,

            videoLink,
            isValidVideoLink,
            isDirectVideoLink,
            youtubeEmbedLink,

        };
    },
};
</script>
<style scoped>
/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #510192b9;
    border-radius: 10px;
    margin-left: 20px;
    transition: 3s;
}

::-webkit-scrollbar-thumb:hover {
    display: block;
    transition: 0.5s;
}
</style>