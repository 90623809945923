<template>
    <div class="flex flex-col items-center justify-center w-full h-full  fixed top-0 right-0 bg-black/20 z-[20]">
        <div v-motion-slide-top
            class="w-[90%] p-5 mx-auto md:w-[80%] lg:w-[50%] xl:w-[30%] bg-white lg:-mt-[35rem] xl:-mt-[35rem] -mt-[35rem] ">
            <div class="flex items-center justify-between w-full mx-auto ">
                <h1 class="text-primery1 text-heading3">

                </h1>
                <button @click="handleClose"
                    class="flex items-center justify-center w-10 h-10 rounded-full focus:border-primery1 focus:border">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                        class="w-8 h-8 text-primery1">
                        <path
                            d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
                    </svg>
                </button>
            </div>
            <form @submit.prevent="handleSubmit(statusRequest?.id)" action="" class="mt-2">

                <div>
                    <!-- {{ productDetail }} -->
                    <h2 class="text-xl font-bold">កែប្រែស្ថានភាព</h2>

                    <div>
                        <select v-model="statusRequestVal" class="input1">
                            
                            <option value="" selected disabled>ជ្រើសរើស</option>
                            <!-- <option value="pending">Pending</option> -->
                            <option value="completed">Completed</option>
                        </select>
                    </div>

                </div>
                <div class="flex items-center justify-end gap-2 py-4">
                    <button
                        class="p-2 px-5 text-white transition-all duration-300 ease-in-out bg-accent hover:bg-yellow-500"
                        @click="handleClose">
                        បោះបង់
                    </button>
                    <button v-if="!loading"
                        class="p-2 px-5 text-white transition-all duration-300 ease-in-out bg-primery1 hover:bg-blue-500 hover:text-white"
                        >
                        កែប្រែ
                    </button>
                    <button v-else class="p-2 px-5 text-white transition-all duration-300 ease-in-out bg-primery1/80 ">
                        កំពុងកែប្រែ...
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import useCollection from "@/composible/useCollection";
import { onMounted, ref } from "vue";
import { timestamp } from "@/firebase/config";
import { push } from 'notivue';
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import useResetPassword from "@/composible/ForgetPassword";
import useDocument from "@/composible/useDocument";
import useStorage from "@/composible/useStorange";
import { useRoute } from "vue-router";

export default {
    emit: ["AddSusccesfully", "UpddateSuccess"],
    components: {
        ckeditor: CKEditor.component
    },
    props: ["datatoedit", "productDetail", "statusRequest"],
    setup(props, { emit }) {

        const editor = ClassicEditor; // Set the CKEditor editor type

        const route = useRoute()

        const Question = ref("");
        const Answer = ref("");
        const loading = ref(false);
        const statusRequestVal = ref("");
        const { updateDocs } = useCollection("Request-Quote");

        const { deleteDocs } = useDocument("products", route.params.id, "productDetails");
        const { removeImage } = useStorage()

        onMounted(() => {
            if (props.datatoedit) {
                Question.value = props.datatoedit.question;
                Answer.value = props.datatoedit.answer;
            }
        });

        const handleSubmit = async () => {


            const data = {
               status: statusRequestVal.value,
    
            }
            try {
                await updateDocs(props.statusRequest?.id, data)
                push.success("បានកែប្រែស្ថានភាពដោយជោគជ័យ")
                emit("UpddateSuccess")
            }
            catch (err) {
                console.log(err)
            }


            handleClose();
        };

        const handleClose = () => {
            emit("close");
            Answer.value = "";
            Question.value = "";
        };


        const handleDeleteProductDetail = async (productDetailsId) => {
            try {
                loading.value = true;
                if (productDetailsId) {
                    await deleteDocs(productDetailsId)
                    push.success("បានលុបដោយជោគជ័យ")
                    handleClose()
                }

                loading.value = false

            }
            catch (err) {
                console.log(err)
            }
            finally {
                loading.value = false
            }

        };



        return {
            handleClose,
            handleSubmit,
            Question,
            Answer,
            loading,
            editor,
            handleDeleteProductDetail,
            statusRequestVal
        };
    },
};
</script>