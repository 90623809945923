<template>
    <div v-motion :initial="{ opacity: 0 }" :enter="{ opacity: 1, transition: { duration: 500, ease: 'easeIn' } }">
        <div class="lg:w-[90%] xl:w-[1280px] mx-auto my-10 md:px-4 lg:px-4">
            <div>
                <div class="w-full mb-8 lg:w-[80%] px-5 lg:px-0">
                    <h1 class="text-start md:text-[26px] font-NotoSansKhmer font-bold text-primery1 xl:text-[28px]">
                        ឧបករណ៍ទាំងអស់
                    </h1>
                    <p
                        class="text-[16px] mt-2 text-justify font-[500] md:text-[18px] xl:mx-0 lg:text-[16px] text-black/70 xl:text-[18px] xl:mb-10">
                        ឈុតឧបករណ៍ ប្រើក្នុងឈុតប្រព័ន្ធគ្រប់គ្រងអាជីវកម្ម POS Hardware បែបទំនើបស្តងដារ​ ទាន់សម័យ
                        ងាយស្រួលប្រើប្រាស់ ទៅតាមតម្រូវការដែលអតិថិជនចង់បាន។
                    </p>
                    <div class="flex flex-wrap gap-4 mt-5">
                        <button v-for="cate in hardwareCategory" :key="cate.id" @click="selectCategory(cate)"
                            class="px-4 py-2 hover:bg-indigo-700 duration-300 ease-in-out rounded-md transition hover:text-white font-poppins text-sm md:text-md"
                            :class="{
                                'bg-gradient-to-r from-[#500192] to-indigo-600 text-white': selectedCategory?.id === cate.id,
                                'bg-gray-200': selectedCategory?.id !== cate.id,
                            }">
                            {{ cate.name }}
                        </button>
                    </div>
                </div>



                <!-- Selected Category Products -->
                <div v-if="selectedCategory" class="mt-4 md:px-5 lg:px-0 px-5">
                    <p class="font-bold text-lg text-primary mb-4">
                        {{ selectedCategory.name }}
                    </p>
                    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-4">
                        <div v-for="pro in selectedCategory.products" :key="pro.id">
                            <div class="relative bg-white h-full rounded-md cursor-pointer overflow-hidden hover:scale-105 duration-300 transition-all"
                                style="box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;">
                                <div
                                    class="absolute inset-0 border-2 border-transparent rounded-md hover:border-indigo-500 transition-all duration-500 animate-border-animation">
                                </div>
                                <div class="p-[16px] gap-6">
                                    <div class="flex justify-center">
                                        <img :src="pro.url"
                                            class="h-[150px] w-[150px] md:w-[140px] md:h-[140px] lg:w-[120px] lg:h-[120px] xl:w-[180px] xl:h-[180px] object-contain"
                                            alt="">
                                    </div>
                                    <div class="mt-2">
                                        <h4
                                            class="font-bold font-poppins md:text-[18px] xl:text-[18px] lg:text-[15px] text-[16px] line-clamp-2">
                                            {{ pro.productName }}
                                        </h4>
                                        <div v-html="pro.productDesscript"
                                            class="xl:text-[14px] font-poppins prose mt-[12px] md:text-[13px] xl:mt-[16px] lg:text-[#909090] lg:text-[12px] text-[14px]">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>
<script>
import getCollection from "@/composible/getCollections";
import getDocument from "@/composible/getDocument";
import { ref, watchEffect } from "vue";
import { orderBy } from "@firebase/firestore";
export default {
    setup() {
        const hardwareCategory = ref([]);
        const selectedCategory = ref(null);

        // Fetch categories
        const { documents } = getCollection("hardwareCategories", orderBy("createdAt", "desc"));
        watchEffect(() => {
            hardwareCategory.value = documents.value || [];
            if (!selectedCategory.value && hardwareCategory.value.length) {
                // Default to the first category
                selectCategory(hardwareCategory.value[0]);
            }
        });

        // Select category
        const selectCategory = (cate) => {
            const { documents } = getDocument("hardwareCategories", cate.id, "hardware");
            watchEffect(() => {
                selectedCategory.value = {
                    id: cate.id,
                    name: cate.name, // Add the category name here
                    products: documents.value || [],
                };
            });
        };

        return { hardwareCategory, selectedCategory, selectCategory };
    },
};

</script>
